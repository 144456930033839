import { Controller } from "@hotwired/stimulus"
import SelectionModel from "../selection/model"
import merge from 'deepmerge'
// import { debounce } from 'debounce';
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['form', 'intentField', 'allSelectedField', 'checkbox', 'status', 'errors']
  static debounces = ['sendDefaultData']

  connect() {
    this.model = new SelectionModel()

    useDebounce(this, { wait: 200 })
    // this.debouncedSend = debounce(this.sendDefaultData, 500)
    this.pendingSend = false

    this.updateModel()
    this.updateStatus()
    // this.setupPaginationGuard()

    app.controllers = app.controllers || []
    app.controllers.push(this)
    app.selection = this
  }

  disconnect() {
    app.controllers = app.controllers.filter((c) => c != this)
    app.selection = null
    this.teardownPaginationGuard()
  }


  // Interaction Events ----------------------------------------------------- //

  // Track individual box [un]checking
  // To avoid hitting the server as many times as the user can check boxes,
  // we build up changes in the [un]selectedIds buffers in the model.
  // A debounced function is defined in connect() which performs sendDefaultData
  // after the user has stopped checking boxes for the set amount of time.
  selectOne(evt) {
    let id = evt.target.dataset['listingId']

    // Update buffers and set local count for immediate status update
    // count will be overwritten by server after request completes
    if (evt.target.checked) {
      this.model.add(id)
    } else {
      this.model.remove(id)
    }

    this.updateStatus() // don't wait for server to update count
    this.pendingSend = true
    // this.debouncedSend()
    this.sendDefaultData()
  }

  // Handle 'select 25 on this page'
  // pageSelected is use to tell the server to replace ids,
  // ie only the page is selected.
  // This is sent immediately
  selectPage(evt) {
    evt.preventDefault()
    this.checkPageBoxes()
    this.checkedBoxes.forEach((id) => this.model.add(id))
    this.allSelected = false
    this.pageSelected = true
    this.sendDefaultData()
  }

  // Handle all selected, count comes back from server. Sent immediately
  selectAll(evt) {
    evt.preventDefault()
    this.allSelected = true
    this.checkPageBoxes()
    this.sendData(this.withRails({selection: { all_selected: true }}))
  }

  // Hits the SelectionsController#destroy action which resets the selection
  // Sent immediately
  unselectAll(evt) {
    evt.preventDefault()
    this.model.unselectAll()
    this.uncheckPageBoxes()
    this.updateStatus() // don't wait for server to update count
    this.sendData({ _method: 'destroy' }, 'delete')
  }

  setupPaginationGuard() {
    document.querySelectorAll('.pagination-link').forEach((link) => {
      link.addEventListener('click', this.guardPageChange)
    })
  }

  teardownPaginationGuard() {
    document.querySelectorAll('.pagination-link').forEach((link) => {
      link.removeEventListener('click', this.guardPageChange)
    })
  }

  // Catch links and wait for selection to be saved. Covers the edge-case
  // where a quick box-checker makes a selection then tries to change pages
  // or perform a mass-action
  guardPageChange = (evt) => {
    evt.preventDefault()
    let path = this.findHref(evt)

    if (this.pendingSend) {
      this.element.addEventListener('selection:sendComplete', () => Turbo.visit(path, {action: 'replace'}))
    } else {
      Turbo.visit(path, {action: 'replace'})
    }
  }

  // Data  ------------------------------------------------------------------ //

  // attempt to keep backend as source of truth and keep this self-contained
  // track view-necessary data here
  updateModel() {
    this.model.count = this.data.get('count')
    this.model.allSelected = this.allSelected
  }

  sendDefaultData(evt) {
    this.sendData(this.withRails(this.model.payload))
  }

  sendData(data, method) {
    fetch(this.formAction, this.fetchOptions(data, method))
      .then(this.handleResponse)
      .then(this.resolveJson)
      .then(this.handleServerData)
      .then(this.sendComplete)
  }

  fetchOptions(data, method) {
    return {
      method: method || 'PUT',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.csrfToken
      },
      body: JSON.stringify(data)
    }
  }

  // Include necessary Rails params
  withRails(data) {
    return merge({ authenticity_token: this.formAuthToken, _method: this.railsMethod }, data)
  }

  handleResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  }

  resolveJson(response) {
    return response.json()
  }

  // Called after the server has responded with the server's json_data
  handleServerData = (data) => {
    this.model.count        = data.selected_count
    this.model.allSelected  = data.all_selected
    this.model.pageSelected = false // reset this after server response
    this.model.resetBuffers()

    if (this.model.allSelected) {
      this.checkPageBoxes()
    }

    this.updateStatus()
  }

  // Notify waiting links that it is ok to proceed
  sendComplete = () => {
    this.pendingSend = false
    let event = new Event('selection:sendComplete', {bubbles: true})
    this.element.dispatchEvent(event)
  }

  get formAuthToken() {
    return this.formTarget.querySelector('[name="authenticity_token"]').value
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  // View management -------------------------------------------------------- //

  updateStatus() {
    this.setStatus(this.countMsg, 'attention')
    this.errorsTarget.innerHTML = ''
  }

  requireSelection() {
    this.errorsTarget.innerHTML = 'Select households to perform mass-actions'
  }

  checkPageBoxes() {
    this.checkboxTargets.forEach(c => c.checked = true)
    this.data.set('count', this.model.count)
    this.setStatus(this.countMsg, 'attention')
    this.updateModel()
  }

  uncheckPageBoxes() {
    this.checkboxTargets.forEach(c => c.checked = false)
    this.data.set('count', this.model.count)
    this.setStatus(this.countMsg, 'attention')
    this.updateModel()
  }

  setStatus(msg, state) {
    if (state == 'alert') {
      this.statusTarget.classList.add('section-status--alert')
    } else {
      this.statusTarget.classList.remove('section-status--alert')
      this.statusTarget.classList.add('section-status--info')
    }

    this.statusTarget.innerHTML = msg
  }

  get formAction() {
    return this.formTarget.action
  }

  get railsMethod() {
    let method = document.querySelector('[name="_method"]')

    if (Boolean(method)) {
      return method.value
    } else {
      return 'post'
    }
  }

  // State management ------------------------------------------------------- //

  get checkedBoxes() {
    return this.checkboxTargets
      .filter(b => b.checked)
      .map(b => Number(b.value))
  }

  get count() {
    return this.data.get('selection-count')
  }

  get countMsg() {
    let count = this.model.count

    if (this.model.allSelected) {
      return `${this.data.get('list-total')} households selected`
    } else if (count < 1 || count > 1) {
      return `${count} households selected`
    } else {
      return `${count} household selected`
    }
  }

  get allSelected() {
    return this.allSelectedFieldTarget.value
  }

  set allSelected(val) {
    return this.allSelectedFieldTarget.value = val
    this.data.set('all-selected', val)
    this.model.allSelected = val
  }

  get pageSelected() {
    return this.data.get('pageSelected')
  }

  set pageSelected(val) {
    this.data.set('page-selected', val)
    this.model.pageSelected = val
  }

  findHref(evt) {
    if (Boolean(evt.data) && Boolean(evt.data.url)) {
      // from Turbolinks
      return evt.data.url
    } else if (Boolean(evt.target.href)) {
      // clicked on link
      return evt.target.href
    } else {
      // clicked on something inside a link
      return evt.target.parentNode.href
    }
  }
}
