import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  // 'input' collides with something else in Flatpickr or flatpickr-stimulus
  static targets = ['field']

  // This totally overrides the super class's method so that we can change
  // which element FP is initialized with. `super.connect()` is purposely not used.
  connect() {
    this.config = {
      altInput: true,
      altFormat: 'M d, Y',
      dateFormat: "Y-m-d",
      mode: 'range',
      showMonths: 2,
      // allowInput: true
    }

    this._initializeEvents();
    this._initializeOptions();
    this._initializeDateFormats();

    this.fp = flatpickr(this.fieldTarget, {
      ...this.config
    });

    this._initializeElements();
  }

  open() {
    this.fp.open()
  }

  setT30(event) {
    this.fp.setDate([this.thirtyDaysAgo, this.today], false)
    event.preventDefault()
  }

  setT90(event) {
    this.fp.setDate([this.ninetyDaysAgo, this.today], false)
    event.preventDefault()
  }

  setTtm(event) {
    this.fp.setDate([this.oneYearAgo, this.today], false)
    event.preventDefault()
  }

  get today() {
    return new Date()
  }

  get thirtyDaysAgo() {
    const dt = new Date()
    return dt.setDate(this.today.getDate() - 30)
  }

  get ninetyDaysAgo() {
    const dt = new Date()
    return dt.setDate(this.today.getDate() - 90)
  }

  get oneYearAgo() {
    const dt = new Date()
    return dt.setYear(this.today.getFullYear() - 1)
  }
}
