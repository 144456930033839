import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// Connects to data-controller="listbox"
export default class extends Controller {
  static targets = ['menu', 'option', 'button', 'buttonText']
  static classes = ['open', 'closed', 'option', 'selected']

  static values = {
    open: { type: Boolean, default: false },
    exclusive: { type: Boolean, default: true },
    selected: { type: String },
  }

  connect() {
    useClickOutside(this)

    app.listboxes = app.listboxes || []
    app.listboxes.push(this)

    // Set the current selected option styles
    this.selectedValue = this.selectedValue || this.optionTargets[0].id
  }

  clickOutside(event) {
    event.preventDefault()
    this.close()
  }

  selectedValueChanged(value, previousValue) {
    this.deselectOption(previousValue)
    this.selectOption(value)
    this.setButtonText()

    this.dispatch('change', { detail: { selected: value } })
  }

  toggle(event) {
    event.preventDefault()

    if (this.openValue) {
      this.close()
    } else {
      this.open()
    }
  }

  close() {
    this.openValue = false
    this.menuTarget.classList.remove(...this.openClasses)
    this.menuTarget.classList.add(...this.closedClasses)
  }

  open() {
    this.openValue = true
    this.menuTarget.classList.remove(...this.closedClasses)
    this.menuTarget.classList.add(...this.openClasses)
  }

  select(event) {
    this.selectedValue = event.target.id
    this.close()
  }

  selectOption(id) {
    if (!id.length) { return false }

    let opt = this.optionTargets.find((option) => option.id == id)

    if (!opt) {
      console.log(this.optionTargets);
    }

    opt.classList.add(...this.selectedClasses)
    opt.classList.remove(...this.optionClasses)
  }

  deselectOption(id) {
    if (!id.length) { return false }

    let opt = this.optionTargets.find((option) => option.id == id)

    opt.classList.remove(...this.selectedClasses)
    opt.classList.add(...this.optionClasses)
  }

  deselectAll() {
    this.optionTargets.forEach((option) => {
      this.optionTargets.forEach((option) => {
        option.classList.remove(...this.selectedClasses)
        option.classList.add(...this.optionClasses)
      })
    })
  }

  setButtonText() {
    let selectedOption = this.optionTargets.find((option) => option.id == this.selectedValue)
    this.buttonTextTarget.textContent = selectedOption.textContent
  }
}
