import { Controller } from "@hotwired/stimulus"
import microWait from "../../micro_wait"
import { DateTime } from '../../date_utils/all';

export default class extends Controller {
  connect() {
    this.register()
    this.initialLoad = true
    microWait(this.updateRanges)
  }

  updateRanges = () => {
    app.scenarioForm.ranges.forEach((range) => range.update())
  }

  get fromDate() {
    let dt = this.data.get('fromdate')

    if (Boolean(dt)) {
      return DateTime.fromISO(dt)
    } else {
      return DateTime.local()
    }
  }

  // Private
  // ------------------------------------------------------------------------ //

  register() {
    window.app.scenarioForm.group = this
  }
}
