export default class Formatter {
  static money(num) {
    const len = num.toFixed().length
    const size = (num > 0) ? len : len - 1

    if (size > 6) {
      return this.formatCurrencyMillions(num)
    } else if (size > 3) {
      return this.formatCurrencyThousands(num)
    } else {
      return num.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    }
  }

  static moneyExact(num) {
    return this.currency(num)
  }

  static currency(num) {
    return num.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
  }

  static formatCurrencyMillions(num) {
    return `$${(num / 1000000.0).toFixed(2)}M`
  }

  static formatCurrencyThousands(num) {
    return `$${(num / 1000.0).toFixed(1)}k`
  }

  static percent(num) {
    return `${num}%`
  }

  static withDelimiters(num) {
    return num.toLocaleString('en-US');
  }

  static humanize(str) {
    str = str.replace(/[a-z][A-Z]/g, letter => `_${letter.toLowerCase()}`)
    str = str.replace(/_/g, ' ')
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  static hourOfDay(hour, offset = 0) {
    // Note: a positive offset means hours behind base timezone and vice versa
    hour = parseInt(hour)

    if (!Number.isInteger(hour) || hour < 0 || hour > 23) {
      return ''
    }

    let adjHour = hour - offset

    if (adjHour == 12 || adjHour == -12) {
      return '12:00-12:59 P.M.'
    } else if (adjHour == 0) {
      return '12:00-12:59 A.M.'
    } else if (adjHour > 12) {
      return `${adjHour - 12}:00-${adjHour - 12}:59 P.M.`
    } else if (adjHour < 0) {
      return `${adjHour + 12}:00-${adjHour + 12}:59 P.M.`
    } else if (adjHour < 12) {
      return `${adjHour}:00-${adjHour}:59 A.M.`
    }
  }

  static dayOfWeek(dow) {
    dow = parseInt(dow)

    if (!Number.isInteger(dow) || dow < 0 || dow > 6) {
      return ''
    }

    return this.weekdays[dow]
  }

  static byteFormatter(bytes, decimals) {
    return this.bytes(bytes, decimals)
  }

  static bytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static range(value, unit) {
    if (unit == undefined) return value.toLocaleDateString();

    let opts = this.rangeOpts[unit]
    let date = new Date(value).toLocaleString('default', opts)

    if (unit == "week") {
      return `Week of ${date}`
    } else {
      return date
    }
  }

  static get weekdays() {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ]
  }

  static get rangeOpts() {
    return {
      "month": {
        month: 'short',
        year: 'numeric'
      },
      "week": {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      },
      "day": {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }
    }
  }
}

export { Formatter }
