import 'trix'
import { Controller } from "@hotwired/stimulus"
// import microWait from "../../micro_wait";
import { useDebounce } from "stimulus-use"

// Connects to data-controller="trix--img"
export default class extends Controller {
  static targets = ['btn', 'input', 'dialog', 'filename']

  static debounces = ['prepFigures']

  connect() {
    useDebounce(this)
    this.element.addEventListener('trix-selection-change', this.selectionChange)
    this.element.addEventListener('trix-focus', this.prepFigures)
  }

  prepFigures = (event) => {
    this.editor.element.querySelectorAll('figure.attachment').forEach(elem => {
      elem.classList.add('relative')
    });
  }

  open(event) {
    if (this.selectedAttachment) {
      this.setupDialog()
      this.openDialog()
    }
  }

  setupDialog() {
    this.filenameTarget.textContent = this.attrs['filename']
    this.inputTarget.removeAttribute('disabled')

    if (this.attrs['external']?.length) {
      this.inputTarget.value = this.attrs['external']
    }
  }

  openDialog() {
    this.dialogTarget.setAttribute('data-trix-active', '')
  }

  // Trix seems to handle this automatically
  closeDialog() {
    this.dialogTarget.removeAttribute('data-trix-active')
  }

  link(event) {
    this.selectedAttachment.setAttributes({ external: this.url })
    this.closeDialog()
  }

  unlink(event) {
    this.selectedAttachment.setAttributes({ external: '' })
    this.inputTarget.value = ''
    this.closeDialog()
  }

  selectionChange = (event) => {
    if (this.imgSelected) {
      this.btnTarget.removeAttribute('disabled')
    } else {
      this.btnTarget.setAttribute('disabled', 'disabled')
    }
  }

  get editor() {
    return this.element.querySelector('trix-editor').editor
  }

  get selectedAttachment() {
    return this.editor.getSelectedDocument().getAttachments()[0]
  }

  get imgSelected() {
    return Boolean(this.selectedAttachment)
  }

  get attrs() {
    return this.selectedAttachment.getAttributes()
  }

  get url() {
    return this.inputTarget.value
  }

  get linkedIndicator() {
    let elem = document.createElement('div')
  }
}
