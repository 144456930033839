import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input', 'results', 'form']
  static debounces = ['submit']

  static values = {
    resetUrl: String
  }

  initialize () {
    useDebounce(this, { wait: 200 })
  }

  submit (event) {
    if (this.termSize > 1) {
      this.formTarget.requestSubmit()
    }
  }

  reset () {
    this.resultsTarget.src = this.resetUrlValue
  }

  resetWhenEmpty () {
    if (!Boolean(this.inputTarget?.value)) {
      this.reset()
    }
  }

  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  get termSize() {
    return this.inputTarget?.value?.length || 0
  }
}
