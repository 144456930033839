import { Controller } from "@hotwired/stimulus"

/*
  Smuggles fund id data from backend
*/

// Connects to data-controller="fund-data"
export default class extends Controller {
  static values = {
    payload: Object
  }

  connect() {
    if (this.hasPayloadValue) {
      window.app.fundData = this.payloadValue
    }
  }
}
