import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

// Connects to data-controller="dashboards"
export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.editing = false
  }

  itemTargetConnected(item) {
    if (this.editing) {
      this.thaw(item)
    } else {
      this.freeze(item)
    }
  }

  toggleEditMode(event) {
    this.editing = !this.editing

    if (this.editing) {
      event.target.textContent = "Save"
      this.itemTargets.forEach(item => {
        this.thaw(item)
      })
    } else {
      this.itemTargets.forEach(item => {
        this.freeze(item)
      })
      event.target.textContent = "Edit"
    }
  }

  thaw(item) {
    const ops = item.querySelector(".item-ops")
    const handle = item.querySelector(".sortable-handle")
    const wrapper = item.querySelector(".item-wrapper")

    ops.classList.remove("hidden")
    handle.classList.remove("hidden")
    wrapper.classList.remove("contents")
  }

  freeze(item) {
    const ops = item.querySelector(".item-ops")
    const handle = item.querySelector(".sortable-handle")
    const wrapper = item.querySelector(".item-wrapper")

    ops.classList.add("hidden")
    handle.classList.add("hidden")
    wrapper.classList.add("contents")
  }
}
