import merge from 'deepmerge'
import { Formatter } from "../utils/formatter"
import AxisConfigurator from './axis_configurator'
import DecorationConfigurator from './decoration_configurator'
import TooltipConfigurator from './tooltip_configurator'

export default class ChartConfig {
  constructor(sourceConfig, sourceData) {
    this.sourceConfig   = sourceConfig
    this.axisHandler    = new AxisConfigurator(sourceConfig)
    this.tooltipHandler = new TooltipConfigurator(sourceConfig, sourceData)
    this.decorHandler   = new DecorationConfigurator(sourceConfig)
    this.isLazy         = false
    this.baseConfig     = {}

    this.initBaseConfig()
  }

  // Base config and modifiers
  // -------------------------------------------------------------------------- //

  initBaseConfig() {
    this.baseConfig = {
      data: {
        json: [],
        type: 'line'
      },
      axis: {
        x: { type: 'timeseries' },
        y: {
          tick: {
            culling: { max: 6 }
          }
        }
      },
      grid: { y: { show: true } },
      resize: { auto: false }
    }
  }

  renderLazy() {
    this.baseConfig.render = {
      lazy: true,
      observe: false
    }
  }

  renderImmediately() {
    this.baseConfig.render = {}
  }

  // Useful for pie charts that have text on top of a color
  setContrastLabels() {
    this.baseConfig.data.labels = {
      colors: function (color, d) {
        let bg = this.data.colors()[d.data.id]
        return d3.hsl(bg).l > 0.6 ? '#0f172a' : '#fff'
      }
    }
  }

  // Build config for final output
  // -------------------------------------------------------------------------- //

  get axisConfig() {
    return this.axisHandler.config
  }

  get tooltipConfig() {
    return this.tooltipHandler.config
  }

  get decorationsConfig() {
    return this.decorHandler.config
  }

  get finalConfig() {
    return merge.all([
      this.baseConfig,
      this.sourceConfig,
      this.axisConfig,
      this.tooltipConfig,
      this.decorationsConfig
    ])
  }

  get msConfig() {
    return this.sourceConfig.ms
  }

  // Boolean functions
  // ------------------------------------------------------------------------ //

  get shouldFilterData() {
    return this.shouldFilterDates // add other filters here
  }

  get shouldFilterDates() {
    return Boolean(this.msConfig?.date_filter)
  }

  // Filtering
  // ------------------------------------------------------------------------ //

  get dateFilter() {
    return this.msConfig?.date_filter
  }
}

export { ChartConfig }
