import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static classes = ['tabActive', 'tabInactive', 'panelActive', 'panelInactive']
  static values = { index: Number }

  connect() {
    // app.tabs ||= []
    app.tabs.push(this)
    this.element.addEventListener('turbo:morph-element', (evt) => this.afterMorph(evt))

    this.showTab()
  }

  afterMorph(event) {
    this.showTab()
  }

  showTab(event) {
    event?.preventDefault()

    this.tabTargets.forEach((tab, index) => {
      let panel = this.panelTargets[index]

      // turn the class off or on depending on indexValue

      if (index == this.indexValue) {
        this.activateTab(tab)
        this.activatePanel(panel)
      } else {
        this.deactivateTab(tab)
        this.deactivatePanel(panel)
      }
    })
  }

  toggle(event) {
    event.preventDefault()
    this.indexValue = this.tabTargets.indexOf(event.currentTarget)

    if (Boolean(event?.params)) {
      this.dispatch('selected', { bubbles: true, detail: event.params })
    }
  }

  indexValueChanged(value, previousValue) {
    this.showTab()
  }

  activateTab(tab) {
    if (this.hasTabInactiveClass) { tab.classList.remove(...this.tabInactiveClasses) }
    if (this.hasTabActiveClass)   { tab.classList.add(...this.tabActiveClasses) }
  }

  deactivateTab(tab) {
    if (this.hasTabActiveClass)   { tab.classList.remove(...this.tabActiveClasses) }
    if (this.hasTabInactiveClass) { tab.classList.add(...this.tabInactiveClasses) }
  }

  activatePanel(panel) {
    if (this.hasPanelInactiveClass) { panel.classList.remove(...this.panelInactiveClasses) }
    if (this.hasPanelActiveClass)   { panel.classList.add(...this.panelActiveClasses) }
  }

  deactivatePanel(panel) {
    if (this.hasPanelActiveClass)   { panel.classList.remove(...this.panelActiveClasses) }
    if (this.hasPanelInactiveClass) { panel.classList.add(...this.panelInactiveClasses) }
  }
}
