import { Controller } from "@hotwired/stimulus"
import * as d3 from 'd3'
import bb from 'billboard.js/dist/billboard.js'
import merge from 'deepmerge'
import { useIntersection, useWindowResize } from 'stimulus-use'
import { Formatter } from "../utils/formatter"
import { ChartConfig } from '../charts/chart_config'

// Connects to data-controller="graph"
export default class extends Controller {
  static targets = ['container', 'loadbar', 'desc', 'modeBtn']

  static values = {
    bindId: String,
    config: Object,
    source: Array,
    mode: String,
    desc: String
  }

  connect() {
    app.graphs ||= {}
    this.name = this.bindId?.replace('#', '')

    this.initConfigHandlers()

    this.highlightMode()

    // handle resizing
    useWindowResize(this)

    // is the chart hidden? load when visible
    useIntersection(this)

    // handle delayed loading (?)
    this.load()
  }

  disconnect() {
    if (Boolean(this.chart)) {
      this.destroy()
      // app.graphs?.splice(app.graphs.indexOf(this), 1)
    }

    if (Boolean(app?.graphs[this.name])) {
      delete app.graphs[this.name]
    }
  }

  // Stim-use Callbacks
  // ------------------------------------------------------------------------ //

  // useIntersection callbacks
  appear(entry) {
    this.chart?.flush()
    this.resize()
  }

  disappear(entry) {}

  // useWindowResize
  windowResize({ width, height, event }) {
    if (this.chart) this.resize()
  }

  // Config Handling
  // ------------------------------------------------------------------------ //

  initConfigHandlers() {
    this.configs = {}

    for(const [key, value] of Object.entries(this.configValue)) {
      // this.configs[key] = new ChartConfig(merge(value, { bindto: this.bindId }), this.sourceValue)
      if (value.ms?.date_filter == 'recent') {
        this.configs[key] = new ChartConfig(
          merge(value, { bindto: this.bindId }),
          this.recentData
        )
      } else {
        this.configs[key] = new ChartConfig(
          merge(value, { bindto: this.bindId }),
          this.sourceValue
        )
      }
    }
  }

  get currentConfig() {
    return this.configs[this.modeValue]
  }

  get currentData() {
    // return this[`${this.guideValue[this.modeValue]}Data`]
    // if there's a date_filter then call it, else sourceValue
    let data

    if (this.currentConfig.shouldFilterDates) {
      data = this[`${this.currentConfig.dateFilter}Data`]
    } else {
      data = this.sourceValue
    }

    return { data: { json: data } }
  }

  get currentDataAndConfig() {
    return merge(this.currentConfig.finalConfig, this.currentData)
  }

  // Chart instance management
  // ------------------------------------------------------------------------ //

  load() {
    if (this.shouldLoad) {
      this.generate()
      app.graphs[this.name] = this
    }
  }

  generate() {
    this.chart = bb.generate(this.currentDataAndConfig)
  }

  destroy() {
    this.chart.destroy()
  }

  change(evt) {
    evt.preventDefault()
    this.modeValue = evt.params.mode
    this.descValue = evt.params.desc

    this.changeDesc()
    this.highlightMode()
    this.rebuild()
  }

  changeDesc() {
    if (this.hasDescValue) {
      this.descTarget.textContent = this.descValue
    }
  }

  rebuild() {
    this.destroy()
    this.generate()
  }

  registerChart() {
    app.charts.push(this.chart)
  }

  unregisterChart() {
    app.charts?.splice(app.charts.indexOf(this.chart), 1)
  }

  // change chart type or normalize
  // convert to normalized

  get shouldLoad() {
    // also consider delayed loading
    return !(document.documentElement.hasAttribute("data-turbo-preview"))
  }

  // Mode Buttons
  // ------------------------------------------------------------------------ //

  highlightMode() {
    this.modeBtnTargets.forEach((b) => {
      if (b.dataset.multiChartModeParam == this.modeValue) {
        b.classList.add('btn--active')
      } else {
        b.classList.remove('btn--active')
      }
    })
  }

  // Data
  // ------------------------------------------------------------------------ //

  // Filters
  get recentData() {
    return this.sourceValue.filter(row => row.recent)
  }

  // Find the chart container element
  get bindElement() {
    return document.querySelector(this.bindId)
  }

  get bindId() {
    if (this.hasBindIdValue) {
      return `#${this.bindIdValue}`
    } else {
      return `#${this.element.id}`
    }
  }

  // Resizing
  // ------------------------------------------------------------------------ //

  get container() {
    return this.element.parentElement
  }

  get box() {
    return this.container.getClientRects()[0]
  }

  get targetWidth()  { return Math.floor(this.box.width) - 16 }
  get targetHeight() { return Math.floor(this.box.height) - 16 }

  resize() {
    this.chart?.resize()
  }
}
