import { Controller } from "@hotwired/stimulus"
import ArrayTools from "../../utils/array_tools"

// Connects to data-controller="searches--fund-summary"
export default class extends Controller {
  static targets = ['summary', 'category', 'funds']

  connect() {
    this.update()
  }

  update() {
    if (this.selectedFundIds.length) {
      this.summaryTarget.textContent = this.summaryWithIndividuals
    } else if (this.defaultCategoriesSelected) {
      this.summaryTarget.textContent = this.summaryWithDefaultCategories
    } else {
      this.summaryTarget.textContent = this.summaryWithCategoriesOnly
    }
  }

  get summaryWithIndividuals() {
    return `${this.selectedCategoryIds.length} categories and ${this.selectedFundIds.length} individual funds`
  }

  get summaryWithCategoriesOnly() {
    return `${this.selectedCategoryIds.length} categories`
  }

  get summaryWithDefaultCategories() {
    return "Default fund categories"
  }

  get categoryIds() {
    return app.fundData.category_ids
  }

  get defaultCategoryIds() {
    return app.fundData.default_category_ids
  }

  get fundIds() {
    return app.fundData.fund_ids
  }

  get selectedCategoryIds() {
    return this.categoryTargets.filter((t) => t.checked).map((t) => t.value).map((v) => parseInt(v))
  }

  get selectedFundIds() {
    return [...this.fundsTarget.options]
      .filter(option => option.selected)
      .map(option => option.value)
      .map(value => Number(value))
  }

  get defaultCategoriesSelected() {
    return ArrayTools.arraysEqual(this.defaultCategoryIds, this.selectedCategoryIds)
  }
}
