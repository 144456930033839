// https://www.betterstimulus.com/architecture/mixins.html
import { Formatter } from "../utils/formatter"

export const useFormatting = (obj) => {
  Object.assign(obj, {
    get formatters() {
      return {
        money:      this.formatMoney,
        bytes:      this.formatBytes,
        number:     this.formatNumber,
        percent:    this.formatPercent,
        moneyExact: this.formatMoneyExact
      }
    },

    formatMoney(value) {
      return Formatter.money(value)
    },

    formatMoneyExact(value) {
      return Formatter.moneyExact(value)
    },

    formatBytes(bytes, decimals) {
      return Formatter.bytes(bytes, decimals)
    },

    formatNumber(value) {
      return value.toLocaleString()
    },

    formatPercent(value) {
      return `${value}%`
    },

    formatRange(value, unit) {
      return Formatter.range(value, unit)
    }
  })
}

// export { useFormatting }
