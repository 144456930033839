import { DateTime } from './date_utils/all'
import { PeriodData } from './periods/period_data'
import { Formatter } from "./utils/formatter"

export default class App {
  constructor() {
    this.debugging = false;
    this.data = {}
    this.charts = []
    this.graphs = []
    this.tabs = []
    this.accordions = []
    this.panels = {}
    this.scenarioForm = {}
    this.resetables = {}
    this.isos = {}
    this.periods = window.periods
    this.periodData = new PeriodData(window.periods)
    this.sortables = []
    this.search = { dates: [] }
    this.fundData = {}
  }

  debug() {
    this.debugging = true;

    document.addEventListener("turbo:before-cache", function() {
      console.log('turbo:before-cache');
    })

    document.addEventListener("turbo:before-render", function() {
      console.log('turbo:before-render');
    })
  }

  listen(name) {
    document.addEventListener(name, event => console.log(event))
  }

  on(eventName, callback) {
    jQuery(document).on(`app:${eventName}`, callback)
  }

  off(eventName, callback) {
    jQuery(document).off(`app:${eventName}`, callback)
  }

  one(eventName, callback) {
    jQuery(document).one(`app:${eventName}`, callback)
  }

  trigger(eventName, options) {
    if (this.debugging) { console.log(`app:${eventName}`) }
    jQuery(document).trigger(`app:${eventName}`, options)
  }

  dispatch(elem, eventName, options) {
    const opts  = Object.assign({bubbles: true}, options)
    const event = new CustomEvent(eventName, opts)

    if (this.debugging) { console.log(eventName) }

    elem.dispatchEvent(event)
  }

  dataLookup(bindId, index) {
    return this.graphs[bindId]?.currentData?.data?.json[index]
  }

  get luxon() {
    return DateTime
  }

  get periodsArray() {
    let arr = []

    for(const [name, period] of Object.entries(this.periods)) {
      period.name = name
      arr.push(period)
    }

    return arr
  }

  get formatter() {
    return Formatter
  }

  get mainPanel() {
    return document.querySelector('#main_panel')
  }
}

window.app = new App()

export { App }
