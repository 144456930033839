import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="households--page"
export default class extends Controller {
  static targets = ['namesFrame', 'detailFrame', 'nameOption']

  connect() {}

  namesFrameTargetConnected(element) {
    // when the names frame loads
    // get the id of the first household
    // change the src of the detail frame
    element.loaded.then(() => {
      this.detailFrameTarget.src = this.nameOptionTargets[0].href
    })
  }
}
