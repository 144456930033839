import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="overlay"
export default class extends Controller {
  static values = { visible: Boolean }
  static classes = ['visible']

  connect() {
    window.app.overlay ??= this
    window.ui.overlay  ??= this

    if (this.visibleValue) {
      this.show()
    }
  }

  show() {
    this.element.classList.add(this.visibleClass)
    this.visibleValue = true
  }

  hide() {
    this.element.classList.remove(this.visibleClass)
    this.visibleValue = false
  }

  emitClick(event) {
    this.dispatch('click', { bubbles: true })
  }
}
