import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--form"
export default class extends Controller {
  static targets = ['form']
  static values = { frame: String, url: String }

  connect() {}

  update(event) {
    const frame = document.getElementById(this.frameValue)

    frame.src = this.urlValue + this.formParams
  }

  get formParams() {
    return "?" + new URLSearchParams(new FormData(this.formTarget)).toString()
  }
}
