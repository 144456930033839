import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
// import Trix from "trix"

// Connects to data-controller="embeds"
export default class extends Controller {
  static targets = ['editor', 'href', 'dialog', 'embedBtn']

  connect() {
    /*
      We need to know about 6 elements:
      - The editor html
      - The editor instance
      - Toolbar
      - The input for urls (href)
      - Our embed dialog addition
      - Our "yes embed" button
    */

    // this.pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/
    // this.pattern = /^https:\/\/([^\.]+\.)?vimeo\.com\/(.*)\?(.*)/

    // this.element = element
    // this.editor = element.editor
    // this.toolbar = element.toolbarElement

    // this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']")
    // this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']")
    // this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']")

    // inputTarget fires this.didInput with focus and input
    // embedBtnTarget fires this.embed on click
    app.embeds = this
    this.reset()
  }

  editorTargetConnected(elem) {
    this.editor = elem.editor
  }

  didInput(event) {
    let value = event.target.value.trim()

    if (this.patterns.some((p) => value.match(p))) {
      this.givenUrl = new URL(value)

      // const type = this.typeFromUrl(this.givenUrl.host)
      // const id = this.idFromPath(this.givenUrl)
      console.log(this.givenUrl.pathname);
      console.log(this.givenUrl.host);
      console.log(this.idFromGivenUrl);
      console.log(this.typeFromGivenUrl);

      this.fetch(
        this.idFromGivenUrl,
        this.typeFromGivenUrl
      )
    } else {
      this.reset()
    }
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.editor.insertAttachment(attachment)
    this.element.focus()
  }

  async fetch(value, type) {
    const url = `/embeds/${value}`

    console.log(type);
    const response = await get(
      url,
      {
        query: { type: type },
        responseKind: 'json',
        contentType: 'application/json'
      }
    )

    if (response.ok) {
      this.showEmbed(await response.json)
    } else {
      this.reset()
    }
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.dialogTarget.classList.remove('hidden')
  }

  reset() {
    this.dialogTarget.classList.add('hidden')
    this.currentEmbed = null
  }

  get patterns() {
    return [
      /^https:\/\/([^\.]+\.)?vimeo\.com\/(\d+)\??(.*)?/,
      /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/
    ]
  }

  typeFromUrl(url) {
    return this.typeOptions[url]
  }

  idFromPath(path) {
    return path.substring(1)
  }

  get typeFromGivenUrl() {
    return this.typeOptions[this.givenUrl.hostname]
  }

  get idFromGivenUrl() {
    if (this.typeFromGivenUrl == "vimeo") {
      // the id is the path minus the leading slash
      return this.givenUrl.substring(1)
    } else {
      // the id is the v param
      return this.givenUrl.searchParams.get('v')
    }
  }

  get typeOptions() {
    return {
      'vimeo.com': "vimeo",
      'youtube.com': "youtube",
      'www.youtube.com': "youtube",
    }
  }

  // setup() {
  //   console.log('setup');
  //   if (Boolean(Trix.config.textAttributes.red)) { return }

  //   Trix.config.textAttributes.red = {
  //     style: { color: 'red' },
  //     parser: function (element) {
  //       return element.style.color === "red"
  //     },
  //     inheritable: true
  //   }
  // }

  // appendButton(event) {
  //   const buttonHtml = `<button type="button" class="trix-button" data-trix-attribute="red">RED</button>`
  //   const target = event.target.toolbarElement.querySelector('.trix-button-group--text-tools')

  //   console.log(target);

  //   target.insertAdjacentHTML('beforeend', buttonHtml)
  // }
}
