import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

// Connects to data-controller="ui--toast"
export default class extends Controller {
  static values = {
    // By default it will not auto-dismiss
    dismissAfter: { type: Number, default: 0 }
  }

  connect() {
    useTransition(this)

    this.enter()

    if (this.dismissAfterValue > 0) {
      setTimeout(() => { this.dismiss() }, this.dismissAfterValue)
    }
  }

  async dismiss() {
    await this.leave()
    this.element.remove()
  }
}
