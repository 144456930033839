import { Controller } from "@hotwired/stimulus"

/**
 * This must be used in tandam with the currency type, otherwise the formatted
 * number will be incorrectly cast
*/

// Connects to data-controller="dollar-field"
export default class extends Controller {
  static targets = ['field']

  connect() {
    if (this.isValid) {
      this.toCurrency()
    }

    app.dollars = app.dollars || []
    app.dollars.push(this)
  }

  /**
   * When the user focuses on the input, strip non number characters like commas
   * @param {event} event
   */
  focusFormat(event) {
    if (!this.isValid) { return false }

    this.field.value = this.cleanValue
  }

  /**
   * When the user leaves the input, format it with delimiter and 2 trailing digits.
   * Do nothing if blank or not number
   * @param {event} event
   */
  blurFormat(event) {
    if (!this.isValid) { return false }

    this.toCurrency()
  }

  /**
   * Set the value to a formatted currency string
   */
  toCurrency() {
    this.field.value = Intl.NumberFormat(
      'en-US',
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    ).format(this.numValue)
  }

  /**
   * Used to avoid entering zeros when the field is intended to be blank
   */
  get isEmpty() {
    return this.field.value === ''
  }

  /**
   * Does the string *contain* numbers? Would the string be a number if it was
   * stripped of non-numbers?
   * @returns {boolean}
   */
  get isNumerical() {
    return this.cleanValue.length > 0 && !isNaN(this.numValue)
  }

  get isValid() {
    return !this.isEmpty && this.isNumerical
  }

  get value() {
    return this.field.value
  }

  get commalessValue() {
    return this.value.replace(/,/g, "")
  }

  get cleanValue() {
    return this.cleanStr(this.field.value)
  }

  cleanStr(str) {
    return str.replace(/[^0-9.]/g, "")
  }

  get numValue() {
    return Number(this.cleanValue)
  }

  get field() {
    if (this.hasFieldTarget) {
      return this.fieldTarget
    } else {
      return this.element
    }
  }
}
