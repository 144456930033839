import Tablesort from 'tablesort'

document.addEventListener("turbo:load", function () {
  const sortable_tables = document.querySelectorAll('[data-sortable]')

  app.sorttables = app.sorttables || []

  for (var elem of Array.from(sortable_tables)) {
    app.sorttables.push(new Tablesort(elem))
  }
})
