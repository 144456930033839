import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
// Connects to data-controller="utils--form-submit"
export default class extends Controller {
  static debounces = ['submit']

  connect() {
    useDebounce(this)
  }

  submit() {
    this.element.submit()
  }
}
