import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-picker"
export default class extends Controller {
  static targets = [ 'summary', 'option' ]

  static values = { showComparison: { type: Boolean, default: false } }

  connect() {
    this.updateSummary()
  }

  updateSummary() {
    const checked = this.optionTargets.filter((option) => option.checked)

    if (checked.length) {
      this.summaryTarget.innerHTML = this.summaryHTML
    } else {
      this.summaryTarget.textContent = 'Not filtered'
    }
  }

  get summaryHTML() {
    let str = this.checkedOption.dataset.name

    if (this.showComparisonValue) {
      str += `<div class="text-xs text-slate-400"> compared to ${this.compSummary}</div>`
    }

    return str
  }

  get checkedOption() {
    return this.optionTargets.filter((option) => option.checked)[0]
  }

  get compSummary() {
    const checked = this.optionTargets.filter((option) => option.checked)

    return this.checkedOption.dataset.comparison
  }
}
