import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = []

  reveal(event) {
    if (event.metaKey || event.ctrlKey) return false

    event.preventDefault()
    event.stopPropagation()

    app.stack.open()
    this.setTitle()
    this.load()
  }

  // Load the remote content if a url was provided and it wasn't already loaded
  load() {
    if (this.data.has('url') && app.stack.currentUrl != this.url) {
      fetch(this.url, this.options)
        .then(response => response.text())
        .then(html => {
          app.stack.content.innerHTML = html
          app.stack.currentUrl = this.url
          app.stack.controller.data.set('currentUrl', this.url)
        })
    }
  }

  setTitle() {
    if (this.data.has('title') && app.stack.title) {
      app.stack.title.textContent = this.data.get('title')
    }
  }

  get url() {
    return this.data.get('url')
  }

  // NOTE: This may need to change in future versions of Rails
  get options() {
    return {
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  }
}
