import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'
import Onion from "../maps/onion"
import MapPopup from "../maps/popup"
import Campus from "../maps/popups/campus"
import SearchResult from "../maps/popups/search_result"
import { useMeta } from 'stimulus-use'

export default class extends Controller {
  static targets = ['container', 'controlPanel', 'legend']
  static metaNames = ['mapsVersion']
  static values = {
    lat: Number,
    lng: Number,
    zoom: Number,
    bounds: Array
  }

  connect () {
    useMeta(this)

    this.map = new mapboxgl.Map({
      container: this.containerTarget,
      style: this.mbStyle,
      center: [this.lngValue, this.latValue],
      zoom: this.zoomValue,
      bounds: this.boundsValue,
      antialias: true
    })

    this.onion = new Onion(this.map)
    this.onion.sourceVersion = this.mapsVersionMeta

    this.map.on('load', () => {
      this.setControls()
      this.moveControlPanel()
    })
  }

  disconnect () {
    this.map.remove()
  }

  setControls () {
    this.map.addControl(this.scale)
    this.map.addControl(this.fullScreen)
    this.map.addControl(this.nav)
  }

  moveControlPanel () {
    this.containerTarget.appendChild(this.controlPanelTarget) // Fullscreen compatible
    this.controlPanelTarget.classList.remove('hidden')
  }

  flyTo (lng, lat, zoom) {
    this.map.flyTo({
      center: [lng, lat],
      zoom: zoom,
      speed: 0.5
    })
  }

  togglePins (event) {
    if (this.onion.hasLayer(event.params.layer)) {
      this.onion.toggleLayer(event.params.layer)
    } else {
      this.addPins(event.params.url)
    }
  }

  async addPins (url) {
    return fetch(url)
      .then(this.handleResponse)
      .then(json => this.onion.addLayerFromJson(json.config, json.data))
      .then(config => this.addPopup(config.popup, config.id))
  }

  addPopup (type, layerId) {
    let popup

    switch(type) {
      case 'Campus':
        popup = new Campus(this.map)
        break;
      case 'SearchResult':
        popup = new SearchResult(this.map)
        break;
      default:
        popup = new MapPopup(this.map)
        break;
    }

    popup.addTo(layerId)
  }

  handleResponse (response) {
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  }

  get mbStyle () {
    return 'mapbox://styles/mortarstone/cl22dpkji002815mytt2liioj'
  }

  get scale () {
    return new mapboxgl.ScaleControl({ unit: 'imperial' })
  }

  get fullScreen () {
    return new mapboxgl.FullscreenControl()
  }

  get nav () {
    return new mapboxgl.NavigationControl({ showZoom: true, showCompass: true })
  }
}
