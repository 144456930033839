import { MonthStrategy } from './month_strategy'

export default class TrailingMonthStrategy extends MonthStrategy {

  // private

  get autoDateStart() {
    let offset

    if (this.isEndOfMonth) {
      offset = (this.offsetForPeriod - 1)
    } else {
      offset = this.offsetForPeriod
    }

    return this.fromDate.endOf('month').minus({ months: offset }).startOf('month')
  }

  get autoDateEnd() {
    return this.lastFullMonthEnd
  }

  get priorPeriodDateStart() {
    return this.dateStart
      .minus({ days: 1, months: (this.offsetForPeriod - 1) })
      .startOf('month')
  }

  get priorYearDateStart() {
    return this.dateStart
      .minus({ years: 1 })
      .startOf('month')
  }

  get isEndOfMonth() {
    return this.fromDate.toISODate() == this.fromDate.endOf('month').toISODate()
  }

  get lastFullMonthEnd() {
    if (this.isEndOfMonth) {
      return this.fromDate
    } else {
      return this.fromDate.minus({ months: 1 }).endOf('month')
    }
  }
}

export { TrailingMonthStrategy }
