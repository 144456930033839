import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (!Boolean(app.dismissed_banner)) {
      setTimeout(() => this.element.classList.add('displaying'), 1000)
    }
  }

  dismiss = (evt) => {
    this.element.classList.remove('displaying')
    this.element.classList.add('dismissed')
    app.dismissed_banner = true
  }
}
