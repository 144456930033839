import Choices from "choices.js"
import debounce from "debounce"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field']
  static values = { paramName: String }

  connect() {
    this.instance = new Choices(this.fieldTarget, {
      duplicateItemsAllowed: false,
      classNames: this.classNames,
      placeholder: true,
      searchPlaceholderValue: (this.data.get('hint') || ''),
      noChoicesText: ''
    })

    if (this.data.has('url')) {
      this.element.addEventListener('search', debounce(this.search, 500))
    }
  }

  disconnect() {
    this.instance.destroy()
  }

  search = (evt) => {
    fetch(this.searchUrl(evt.detail.value), this.options)
      .then((response) => response.json())
      .then((data) => {
        this.instance.setChoices(data, 'id', 'name', true)
      })
  }

  searchUrl(query) {
    let path = this.data.get('url').split('?')[0]
    let params = new URLSearchParams(this.data.get('url').split('?')[1])
    params.set(this.paramName, query)

    return path.concat('?').concat(params)
  }

  get paramName () {
    return this.paramNameValue || 'query'
  }

  get options() {
    return {
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  }

  get classNames() {
    return {
      containerOuter: 'pillbox',
      containerInner: 'pillbox-inner',
      input: 'pillbox-input',
      inputCloned: 'pillbox-input--cloned',
      list: 'pillbox-list',
      listItems: 'pillbox-list--multiple',
      listSingle: 'pillbox-list--single',
      listDropdown: 'pillbox-list--dropdown',
      item: 'pillbox-item',
      itemSelectable: 'pillbox-item--selectable',
      itemDisabled: 'pillbox-item--disabled',
      itemChoice: 'pillbox-item--choice',
      placeholder: 'pillbox-placeholder',
      group: 'pillbox-group',
      groupHeading: 'pillbox-heading',
      button: 'pillbox-button',
      activeState: 'pillbox--active',
      focusState: 'pillbox--focused',
      openState: 'pillbox--open',
      disabledState: 'pillbox--disabled',
      highlightedState: 'pillbox--highlighted',
      hiddenState: 'pillbox--hidden',
      flippedState: 'pillbox--flipped',
      loadingState: 'pillbox--loading',
      noResults: 'pillbox--no_results',
      noChoices: 'pillbox--no_choices'
    }
  }
}
