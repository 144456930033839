import Choices from "choices.js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field']

  static values = {
    hint: { type: String, default: '' }
  }

  connect() {
    // app.pillboxes ??= []
    // app.pillboxes.push(this)

    this.setPlaceholder()

    this.instance = new Choices(this.fieldTarget, {
      duplicateItemsAllowed: false,
      removeItemButton: true,
      placeholder: true
    })
  }

  disconnect() {
    this.instance.destroy()
  }

  // The (new) recommended way to add a placeholder to a multi-select is to
  // add a first-option with no value
  setPlaceholder() {
    let opt = new Option(this.hintValue, '')
    this.fieldTarget.add(opt, this.fieldTarget.options[0])
  }

  // get classNames() {
  //   return {
  //     containerOuter: 'pillbox',
  //     containerInner: 'pillbox-inner',
  //     input: 'pillbox-input',
  //     inputCloned: 'pillbox-input--cloned',
  //     list: 'pillbox-list',
  //     listItems: 'pillbox-list--multiple',
  //     listSingle: 'pillbox-list--single',
  //     listDropdown: 'pillbox-list--dropdown',
  //     item: 'pillbox-item',
  //     itemSelectable: 'pillbox-item--selectable',
  //     itemDisabled: 'pillbox-item--disabled',
  //     itemChoice: 'pillbox-item--choice',
  //     placeholder: 'pillbox-placeholder',
  //     group: 'pillbox-group',
  //     groupHeading: 'pillbox-heading',
  //     button: 'pillbox-button',
  //     activeState: 'pillbox--active',
  //     focusState: 'pillbox--focused',
  //     openState: 'pillbox--open',
  //     disabledState: 'pillbox--disabled',
  //     highlightedState: 'pillbox--highlighted',
  //     hiddenState: 'pillbox--hidden',
  //     flippedState: 'pillbox--flipped',
  //     loadingState: 'pillbox--loading',
  //     noResults: 'pillbox--no_results',
  //     noChoices: 'pillbox--no_choices'
  //   }
  // }
}
