import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    turboId: String
  }

  connect() {}

  open(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    app.stack.open()

    if (app.stack.currentUrl != this.urlValue) {
      app.stack.content.replaceChildren(this.tfTag)
      app.stack.currentUrl = this.urlValue
    }
  }

  get tfTag() {
    let tpl = document.createElement('template')
    tpl.innerHTML = `
      <turbo-frame id="${this.turboIdValue}" src="${this.urlValue}">
        <div class="loadbar"><div class="loadbar-mover"></div></div>
      </turbo-frame>
    `

    return tpl.content
  }
}
