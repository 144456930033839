import mapboxgl, { Popup } from 'mapbox-gl'
import Formatter from '../utils/formatter'

export default class MapPopup {
  constructor (map) {
    this.map = map
    this.classList = ['mb-popup', 'rounded-md']
    this.filtered = {}
  }

  addTo (layerId) {
    this.map.on('click', layerId, (event) => {
      this.parsed = this.parseValues(event.features[0].properties)
      this.filter(this.parsed)

      let lngLat
      if (this.lng && this.lat) {
        lngLat = [this.lng, this.lat]
      } else {
        lngLat = event.lngLat
      }

      new mapboxgl.Popup({
          maxWidth: '31rem',
          className: this.classList.join(' ')
        })
        .setLngLat(lngLat)
        .setHTML(this.propertiesHTML())
        .addTo(this.map)
    })
  }

  propertiesHTML () {
    return `
      <div>
        <h3>${this.name}</h3>
        <br />
        <i>${this.address}</i>
        ${this.properties()}
      </div>
    `
  }

  parseValues (props) {
    return Object.fromEntries(Object.entries(props).map(([k, v]) => [ k, JSON.parse(v)]))
  }

  properties () {
    return Object.entries(this.filtered).map(([prop, values]) => {
      return `<div>${Formatter.humanize(prop)}: ${this.formatVal(values)}</div>`
    }).join(' ')
  }

  filter (props) {
    props = this.extractAddress(props)
    props = this.extractName(props)

    this.filtered = props
  }

  extractName (props) {
    let name = props.name

    if (name) {
      this.name = name.value
      delete props.name
    }

    return props
  }

  extractAddress (props) {
    this.addressProps.forEach((prop) => {
      let value = props[prop]

      if (value) {
        this[prop] = value.value
        delete props[prop]
      }
    })

    return props
  }

  get lng () {
    return this.filtered?.lng?.value
  }

  get lat () {
    return this.filtered?.lat?.value
  }

  get address () {
    if (this.addressProps.some(p => this[p])) {
      return `<div><div>${this.street}</div>${this.city}, ${this.state} ${this.zip}</div`
    } else {
      return ''
    }
  }

  formatVal (value) {
    switch (value.type) {
      case 'currency':
        return Formatter.currency(value.value)
      case 'percent':
        return Formatter.percent(value.value)
      default:
        return value.value
    }
  }

  get addressProps () {
    return ['street', 'city', 'state', 'zip']
  }
}
