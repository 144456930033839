import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  connect() {
    this.inputTargets.forEach((target) => {
      if(target.dataset.initiallyLocked == "true"){
        this.lock(target);
      }
    })

    $(this.element).submit(() => {
      this.enableForSubmit();
    });
  }

  toggle(event) {
    let lock = event.currentTarget;
    let id = lock.dataset.locks;
    let element = document.getElementById(id);

    if(element.disabled) {
      $(lock).removeClass('fa-lock').addClass('fa-lock-open');
      this.unlock(element);
    } else {
      $(lock).removeClass('fa-lock-open').addClass('fa-lock');
      this.lock(element);
    }
  }

  lock(element) {
    element.disabled = true;

    if(element._flatpickr) {
      element._flatpickr._input.disabled = true;
    }
  }

  unlock(element) {
    element.disabled = false;

    if(element._flatpickr) {
      element._flatpickr._input.disabled = false;
    }
  }

  enableForSubmit() {
    this.inputTargets.forEach((element) => {
      this.unlock(element);
    })
  }
}
