import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="searches--date-summary"
export default class extends Controller {
  static targets = [ 'dateMode', 'summary' ]

  connect() {
    this.update()
    this.dateModeTarget.addEventListener('change', this.update.bind(this))
  }

  update() {
    this.hideAllSummaries()
    this.currentDateSummary.classList.remove('hidden')
  }

  hideAllSummaries() {
    this.summaryTargets.forEach((t) => t.classList.add('hidden'))
  }

  get currentDateSummary() {
    return this.summaryTargets.find((t) => t.dataset.dateMode === this.dateModeTarget.value)
  }
}
