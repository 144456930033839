import { DayStrategy } from './day_strategy'

export default class MtdStrategy extends DayStrategy {

  // private

  get offsetForPeriod() {
    let bom = this.fromDate.startOf('month')
    let int = this.diff(bom, this.fromDate)

    return int.length('days')
  }

  get priorPeriodDateEnd() {
    return this.dateEnd.minus({ months: 1 })
  }

  get priorYearDateEnd() {
    return this.dateEnd.minus({ years: 1 })
  }
}

export { MtdStrategy }
