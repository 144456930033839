import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'customerDetails',
    'confirmationForm',
    'nameField',
    'emailField',
    'errorContainer',
    'accountHolderName',
    'financialInstitution',
    'routingNumber',
    'last4'
  ]

  static values = {
    clientSecret: String,
    redirectUrl: String
  }

  submit (event) {
    event.preventDefault()
    this.clearErrors()
    if (this.validCustomerDetails) {
      this.disableButton(event.currentTarget)
      // Open instant verification modal
      this.collectBankInfo()
    } else {
      this.addError('You must provide valid contact information')
    }
  }

  confirm (event) {
    event.preventDefault()
    this.disableButton(event.currentTarget, 'Confirming...')
    this.confirmBankInfo()
  }

  collectBankInfo () {
    this.stripe
      .collectBankAccountForSetup(this.bankSetup)
      .then(({setupIntent, error}) => {
        if (error) {
          this.addError('Something went wrong. Please try again later')
        } else if (setupIntent.status === 'requires_payment_method') {
          // Closed instant verification modal
          this.redirect()
        } else if (setupIntent.status === 'requires_confirmation') {
          this.showConfirmation(setupIntent)
        }
      }
    )
  }

  confirmBankInfo () {
    this.stripe
      .confirmUsBankAccountSetup(this.clientSecretValue)
      .then(({setupIntent, error}) => {
        if (error) {
          this.redirectWithError()
        } else if (setupIntent.status === "requires_payment_method") {
          // Confirmation failed, retry
          this.redirectWithError()
        } else if (setupIntent.status === "succeeded") {
          this.redirectWithSuccess()
        } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
          this.redirectWithVerify()
        }
      }
    )
  }

  clearErrors () {
    this.errorContainerTarget.innerHTML = ''
    this.errorContainerTarget.classList.remove('form_errors--visible')
  }

  addError (message) {
    this.errorContainerTarget.innerHTML = `<div>${message}</div>`
    this.errorContainerTarget.classList.add('form_errors--visible')
  }

  showConfirmation (setupIntent) {
    this.addDetails(setupIntent)
    this.customerDetailsTarget.classList.add('hidden')
    this.confirmationFormTarget.classList.remove('hidden')
  }

  addDetails (setupIntent) {
    const pm = setupIntent.payment_method

    this.accountHolderNameTarget.innerHTML = pm?.billing_details?.name
    this.financialInstitutionTarget.innerHTML = pm?.us_bank_account?.bank_name
    this.routingNumberTarget.innerHTML = pm?.us_bank_account?.routing_number
    this.last4Target.innerHTML = pm?.us_bank_account?.last4
  }

  disableButton (target, text = 'Submitting...') {
    target.disabled = true
    target.innerHTML = text
  }

  redirectWithVerify () {
    this.redirect({ 'notify': 'microdeposits', 'type': 'info' })
  }

  redirectWithSuccess () {
    this.redirect({ 'notify': 'success', 'type': 'notice' })
  }

  redirectWithError () {
    this.redirect({ 'notify': 'error', 'type': 'alert' })
  }

  redirect (params = null) {
    let url = this.redirectUrlValue
    if (params) {
     url = url + this.paramsToQueryString(params)
    }
    window.location.href = url
  }

  paramsToQueryString (params) {
    return "?" + new URLSearchParams(params).toString()
  }

  get validCustomerDetails () {
    return this.nameFieldTarget.value !== '' && this.emailFieldTarget.value !== ''
  }

  get bankSetup () {
    return {
      clientSecret: this.clientSecretValue,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: this.nameFieldTarget.value,
            email: this.emailFieldTarget.value,
          },
        },
      },
      expand: ['payment_method'],
    }
  }

  get stripe () {
    return window.app.stripe
  }
}
