import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['template', 'itemContainer']

  addItem(event) {
    event.preventDefault()

    const givenTpl = event.currentTarget.dataset['templateTarget']
    let tpl

    if (Boolean(givenTpl)) {
      tpl = document.querySelector(`template#${givenTpl}`)
    } else {
      tpl = this.template
    }

    const content = tpl.innerHTML.replace(/TEMPLATE_RECORD/g, this.dateId)
    const elem = document.createElement('template')
    elem.innerHTML = content

    this.container.appendChild(elem.content)

    if (this.scroll) {
      this.container.lastElementChild.scrollIntoView({ behavior: "smooth" })
    }
  }

  removeItem(event) {
    event.preventDefault()

    let item = event.target.closest(".nested-fields")

    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  get template() {
    return this.templateTarget
  }

  get container() {
    return this.itemContainerTarget
  }

  get dateId() {
    const date = new Date().valueOf()
    return `d${date}`
  }

  get scroll() {
    return this.container.dataset.scroll
  }
}
