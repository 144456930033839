import { Application } from "@hotwired/stimulus"

import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"
import mapboxgl from 'mapbox-gl'

const appsignal = new Appsignal({
  key: "fdfa18e3-b213-4676-ad36-68d3b5c5f837"
})

window.appsignal = appsignal

const mapboxToken = document.querySelector('meta[name="mapbox-pub"]').content
mapboxgl.accessToken = mapboxToken

const application = Application.start()
installErrorHandler(appsignal, application)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
