import { Controller } from "@hotwired/stimulus"

/**
 * Mostly based on this article:
 * https://web.dev/drag-and-drop/
 */

// Connects to data-controller="ui--draggable"
// The element should have draggable="true" attribute
export default class extends Controller {
  // data-ui--draggable-drag-start-class
  static classes = ['base', 'dragStart', 'dragEnd']

  // data-ui--draggable-payload-value
  static values = {
    payload: { type: Object, default: {} }
  }

  connect() {
    this.element.addEventListener('dragstart', this.onDragStart)
    this.element.addEventListener('drag', this.dragging)
    this.element.addEventListener('dragend', this.onDragEnd)
    // this.element.addEventListener('drop', this.onDrop)
  }

  onDragStart = (evt) => {
    this.element.classList.remove(...this.dragEndClasses)
    this.element.classList.add(...this.dragStartClasses)

    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('text/plain', JSON.stringify(this.payloadValue))
  }

  dragging = (evt) => {
    // console.log([evt.clientX, evt.clientY])
    this.dispatch('dragging', { detail: { x: evt.clientX, y: evt.clientY } })
  }

  onDragEnd = (evt) => {
    this.element.classList.remove(...this.dragStartClasses)
    this.element.classList.add(...this.dragEndClasses)
  }

  onDrop = (evt) => {
    console.log('Drop from draggable');
  }
}
