import Model from '../model.js'

export default class SelectionModel extends Model {
  constructor(attrs) {
    super(attrs)
    this.count = 0
    this.resetBuffers()
  }

  get count()      { return this.getNum('count') }
  set count(value) { return this.setNum('count', value) }

  get noneSelected() {
    return this.count === 0 && !this.allSelected
  }

  get allSelected()     { return this.get('allSelected') }

  set allSelected(val)  { return this.setBoolean('allSelected', val) }
  get pageSelected()    { return this.get('pageSelected') }
  set pageSelected(val) { return this.setBoolean('pageSelected', val) }

  add(id) {
    this.attrs.selectedIds.add(id)
    this.count++
  }

  remove(id) {
    this.attrs.unselectedIds.add(id)
    this.allSelected = false
    this.pageSelected = false
    this.count--
  }

  resetBuffers() {
    this.set('selectedIds', new Set())
    this.set('unselectedIds', new Set())
  }

  unselectAll() {
    this.resetBuffers()
    this.count = 0
    this.allSelected = false
  }

  get payload() {
    return {
      selection: {
        page_selected:  this.pageSelected,
        all_selected:   this.allSelected,
        selected_ids:   Array.from(this.get('selectedIds')),
        unselected_ids: Array.from(this.get('unselectedIds'))
      }
    }
  }
}
