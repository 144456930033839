import { DayStrategy } from './day_strategy'

export default class MonthStrategy extends DayStrategy {
  get unit() {
    return this._unit || 'month'
  }

  // private

  get autoDateStart() {
    return this.autoDateEnd
      .minus({ months: this.offsetForPeriod })
      .startOf('month')
  }

  get autoDateEnd() {
    return this.fromDate.endOf('month')
  }

  get priorPeriodDateStart() {
    return this.dateStart
      .minus({ days: 1, months: this.offsetForPeriod })
      .startOf('month')
  }

  get priorPeriodDateEnd() {
    return this.dateStart.minus({ days: 1 })
  }

  get priorYearDateStart() {
    return this.dateEnd
      .minus({ years: 1, months: this.offsetForPeriod })
      .startOf('month')
  }

  get priorYearDateEnd() {
    return this.dateEnd.minus({ years: 1 }).endOf('month')
  }
}

export { MonthStrategy }
