import { Controller } from "@hotwired/stimulus"
import { Formatter } from "../utils/formatter"

export default class extends Controller {
  static targets = [ 'hour' ]

  connect() {
    this.replaceHours()
  }

  replaceHours() {
    let date = new Date()
    let offset = date.getTimezoneOffset() / 60

    for (var hour of Array.from(this.hourTargets)) {
      hour.textContent = Formatter.hourOfDay(hour.textContent, offset)
    }
  }
}
