import { Controller } from "@hotwired/stimulus";
import { Formatter } from "../../utils/formatter";

export default class extends Controller {
  static targets = ['itemCost', 'totalCost', 'itemPrice', 'totalPrice', 'netProfit']

  connect () {
    this.update()
  }

  update () {
    this.updatePrice()
    this.updateCost()
    this.updateNetProfit()
  }

  updatePrice () {
    this.totalPriceTarget.innerHTML = Formatter.currency(this.price)
  }

  updateCost () {
    this.totalCostTarget.innerHTML = Formatter.currency(this.cost * -1)
  }

  updateNetProfit () {
    this.netProfitTarget.innerHTML = Formatter.currency(this.net)

    if (this.net < 0) {
      this.netProfitTarget.classList.add('negative')
    } else  {
      this.netProfitTarget.classList.remove('negative')
    }
  }

  visible (elements) {
    return elements.filter(c => c.style.display !== 'none')
  }

  get net () {
    return this.price - this.cost
  }

  get cost () {
    return this.visibleCostTargets.reduce((a, b) => a + Number(b.dataset.total), 0)
  }

  get price () {
    return this.visiblePriceTargets.reduce((a, b) => a + Number(b.value), 0)
  }

  get visibleCostTargets () {
    return this.visible(this.itemCostTargets)
  }

  get visiblePriceTargets () {
    return this.visible(this.itemPriceTargets)
  }
}
