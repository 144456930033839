import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'verb']

  update(evt) {
    if (evt.target.checked) {
      this.buttonTarget.value = 'Move Households'
    } else {
      this.buttonTarget.value = 'Copy Households'
    }
  }
}
