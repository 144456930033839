import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "total", "remaining" ]

  connect() {
    this.updateTotal()
  }

  updateTotal() {
    let total = this.calculateTotal()
    let remaining = (100 - total).toFixed(2)
    $(this.totalTarget).html(total)
    $(this.remainingTarget).html(remaining)
    $(this.remainingTarget).toggleClass('t-alert', (remaining < 0))
  }

  calculateTotal(){
    let total = 0

    for (let input of this.inputs) {
      let value = parseFloat(input.value, 10)

      if (isNaN(value)) {
        continue
      } else {
        total += value
      }
    }
    return total.toFixed(2)
  }

  get inputs(){
    return $("#campaign_monthly_percentages :input")
  }
}
