import { Controller } from "@hotwired/stimulus"
import { loadStripe } from '@stripe/stripe-js/pure';

export default class extends Controller {
  connect() {
    this.key = this.element.getAttribute('content')
    this.load()
  }

  async load() {
    loadStripe.setLoadParameters({advancedFraudSignals: false});
    const stripe = await loadStripe(this.key)
    window.app.stripe = stripe
    document.dispatchEvent(new CustomEvent('stripe:loaded', { bubbles: true }))
  }
}
