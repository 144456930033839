import { Controller } from "@hotwired/stimulus"
import HouseholdsController from "./households_controller"
import Legend from "../../maps/legend"

// Connects to data-controller="maps--searches"
export default class extends HouseholdsController {
  static targets = ['container', 'controlPanel', 'legend', 'campusSelect', 'pinControls', 'choroplethOption', 'legendContainer']
  static values = {
    lat: Number,
    lng: Number,
    zoom: Number,
    bounds: Array,
    locationsUrl: String,
    resultsUrl: String
  }

  connect() {
    super.connect()

    this.map.on('load', () => {
      this.addPins(this.resultsUrlValue)
    })
  }

  hideChoropleth() {
    this.onion.hideChoropleth()
    this.hideLegend()
    this.choroplethOptionTargets.forEach((target) => { target.checked = false })
  }

  hideLegend() {
    this.legendContainerTarget.classList.add('hidden')
    super.hideLegend()
  }

  updateLegend(layer) {
    super.updateLegend(layer)
    this.legendContainerTarget.classList.remove('hidden')
  }

  addLegend (layerId, legendData) {
    const legend = new Legend(legendData, { showTitle: true })
    this.legends[layerId] = legend
  }

  toggleChoro ({ params: { id, url } }) {
    this.showLoading()
    this.addOrShowChoro(id, url)
      .then(() => this.onion.hideChoropleth({except: id}))
      .then(() => this.updateLegend(id))
  }
}
