import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'background']

  open (event) {
    event.preventDefault()

    this.showModal()
    this.lockScroll()
  }

  close (event) {
    event.preventDefault()

    this.hideModal()
    this.unlockScroll()
  }

  showModal () {
    this.containerTarget.classList.remove('hidden')
    this.backgroundTarget.classList.remove('hidden')
  }

  hideModal () {
    this.containerTarget.classList.add('hidden')
    this.backgroundTarget.classList.add('hidden')
  }

  lockScroll () {
    document.body.style.overflow = "hidden"
  }

  unlockScroll () {
    document.body.style.overflow = null
  }
}
