import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content']
  static classes = ['subdued', 'attention']

  connect() {
    window.app.badges = window.app.badges || {}

    if (this.data.has('name')) {
      window.app.badges[this.name] = this
      this.announceRegistered()
    }
  }

  disconnect() {
    if (this.data.has('name')) {
      delete window.app.badges[this.name]
    }
  }

  announceRegistered() {
    const event = new CustomEvent('badge-connected', {
      bubbles: true,
      detail: this.name
    })

    this.element.dispatchEvent(event)
  }

  update(content, attention) {
    this.content = content
    this.attention = attention

    if (this.isInteresting) {
      this.element.classList.remove(this.subduedClass)
      this.element.classList.add(this.attentionClass)
    } else {
      this.element.classList.add(this.subduedClass)
      this.element.classList.remove(this.attentionClass)
    }
  }

  get name() {
    return this.data.get('name')
  }

  get attention() {
    return this.data.get('attention')
  }

  set attention(value) {
    this.data.set('attention', value)
  }

  set content(value) {
    this.contentTarget.textContent = value
  }

  get isInteresting() {
    return [0, '0', false, 'false', undefined, null].indexOf(this.attention) < 0
  }
}
