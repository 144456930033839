import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'cardField',
    'expField',
    'cvcField',
    'dataField',
    'errorContainer',
    'submitBtn'
  ]

  connect() {
    document.addEventListener('stripe:loaded', () => {
      this.setupCard()
      this.setupForm()
    })
  }

  setupCard() {
    this.elements = this.stripe.elements({
      fonts: [
        { cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400,400i,700' },
      ]
    })

    this.setupCardNum()
    this.setupExp()
    this.setupCvc()
  }

  setupCardNum() {
    this.card = this.elements.create('cardNumber', {style: this.style})
    this.card.mount(this.cardField)
    this.listenForErrors(this.card)
  }

  setupExp() {
    this.exp = this.elements.create('cardExpiry', {style: this.style})
    this.exp.mount(this.expField)
    this.listenForErrors(this.exp)
  }

  setupCvc() {
    this.cvc = this.elements.create('cardCvc', {style: this.style})
    this.cvc.mount(this.cvcField)
    this.listenForErrors(this.cvc)
  }

  listenForErrors(elem) {
    elem.addEventListener('change', (event) => {
      if (event.error) {
        this.errorContainer.textContent = event.error.message;
        this.errorContainer.classList.add('visible')
      } else {
        this.errorContainer.textContent = '';
        this.errorContainer.classList.remove('visible')
      }
    })
  }

  setupForm() {
    this.element.addEventListener('submit', (event) => {
      event.preventDefault();

      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          let errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          this.signalErrorAndResetForm()
        } else {
          // Send the token to your server.
          this.submitStripeToken(result.token);
        }
      })
    })
  }

  submitStripeToken(data) {
    console.log('submitStripeToken');
    this.dataField = JSON.stringify(data)
    this.element.submit()
  }

  get stripe() {
    return window.app.stripe
  }

  get style() {
    return {
      base: {
        color: '#32325d',
        fontFamily: '"Lato", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  }

  get cardField() {
    return this.cardFieldTarget
  }

  get expField() {
    return this.expFieldTarget
  }

  get cvcField() {
    return this.cvcFieldTarget
  }

  get errorContainer() {
    return this.errorContainerTarget
  }

  get dataField() {
    return this.dataFieldTarget
  }

  set dataField(value) {
    this.dataField.value = value
  }

  get formUrl() {
    return this.element.getAttribute('action')
  }

  get submitBtn() {
    return this.submitBtnTarget
  }

  submitOptions(body) {
    return {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': this.csrfToken
      },
      body: JSON.stringify({token: body})
    }
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  handleResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  }

  signalErrorAndResetForm() {
    // shake error message
    this.errorContainer.addEventListener('animationend', () => {
      this.errorContainer.classList.remove('shake')
    })

    this.errorContainer.classList.add('shake')

    this.submitBtn.removeAttribute('data-disable-with')
    this.submitBtn.removeAttribute('disabled')
  }
}
