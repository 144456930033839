import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflow"
export default class extends Controller {
  static targets = [
    'dragger',
    'selector',
    'listFrame',
    'step',
    'stepsContainer',
    'stepAction'
  ]

  static classes = [
    'activeStep',
    'baseStep'
  ]

  static values = {
    currentStep: String
  }

  connect() {
    app.workflow = this

    Turbo.cache.exemptPageFromCache()

    document.addEventListener('ui--draggable:dragging', this.dragWatch)
    document.addEventListener('workflow-step-added', this.addList)
    document.addEventListener('workflow-step-updated', this.handleStepUpdate)
  }

  // Highlighting in connect is too early
  stepTargetConnected(element) {
    if (element.dataset.id == this.currentStepValue) {
      this.highlightActiveStep()
    }
  }

  disconnect() {
    Turbo.cache.resetCacheControl()

    document.removeEventListener('ui--draggable:dragging', this.dragWatch)
    document.removeEventListener('workflow-step-added', this.addList)
    document.removeEventListener('workflow-step-updated', this.handleStepUpdate)
  }

  dragWatch = (event) => {
    // This should (hopefully) make the auto scroll when dragging a bit smoother

    if (event.detail.x >= this.containerRect.right - 40) {
      this.stepsContainerTarget.scroll({
        left: event.detail.x,
        behavior: "smooth"
      })
    } else if (event.detail.x <= this.containerRect.left + 40) {
      this.stepsContainerTarget.scroll({
        left: 0,
        behavior: "smooth"
      })
    }
  }

  draggerTargetConnected(elem) {
    elem.draggable = true
    // elem.querySelector('& > span.hidden')?.classList.remove('hidden')
  }

  // Params comes from data-attrs in workflows/steps/_step.html.erb
  changeList(event) {
    // this.listFrameTarget.id = `list_${event.params['listId']}_listings`
    // let params = Object.assign({ listId: 'everyone' }, event.params)

    if (!this.stepActionTargets.some(element => element.contains(event.target))) {
      Turbo.visit(event.params.pageUrl)
      // this.stepChange(params.listId, params.frameId, params.listingUrl)
    }

    // if (this.currentStepValue == 'everyone') {
    //   // removes step_id param
    //   window.history.pushState(params, '', window.location.pathname)
    // } else {
    //   window.history.pushState(params, '', `?step_id=${this.currentStepValue}`)
    // }
  }

  stepChange(listId, frameId, url) {
    this.currentStepValue = listId
    this.listFrameTarget.id = frameId
    this.listFrameTarget.src = url
    this.highlightActiveStep()
  }

  addList = (event) => {
    this.stepsContainerTarget.scroll({
      left: this.containerRect.right,
      behavior: "smooth"
    })

    this.currentStepValue = event.target.dataset.stepId
    this.highlightActiveStep()
    this.listFrameTarget.src = event.target.dataset.workflowListingUrlParam
    // this.changeList(event)
  }

  highlightActiveStep() {
    this.stepTargets.forEach(elem => {
      if (elem.dataset.id == this.currentStepValue) {
        this.highlightStep(elem)
      } else {
        this.diminishStep(elem)
      }
    })
  }

  handleStepUpdate = (event) => {
    // let stepId = event.target.dataset.stepId
    this.currentStepValue = event.target.dataset.stepId
    this.highlightActiveStep()
  }

  highlightStep(element) {
    element.classList.remove(...this.baseStepClasses)
    element.classList.add(...this.activeStepClasses)
  }

  diminishStep(element) {
    element.classList.remove(...this.activeStepClasses)
    element.classList.add(...this.baseStepClasses)
  }

  reloadFrame = () => {
    this.listFrameTarget.reload()
  }

  get containerRect() {
    return this.stepsContainerTarget.getBoundingClientRect()
  }
}
