import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested--destroyable"
export default class extends Controller {
  static targets = ['field']
  connect() {}

  destroy(event) {
    event?.preventDefault()
    // set the field to true
    this.fieldTarget.value = true
    this.element.classList.add('hidden')
  }
}
