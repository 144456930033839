import merge from 'deepmerge'

export default class Onion {
  constructor(map) {
    this.map = map
    this.layers = []
    this.choroplethLayers = []
    this.isoLayers = []
  }

  hasLayer (layer) {
    return this.map.getLayer(layer)
  }

  addOrUpdateISO (id) {
    const iso = window.app.isos[id]

    if(!this.hasLayer(id)) {
      iso.getSource()
        .then(source => this.map.addSource(id, source))
        .then(() => this.map.addLayer(iso.layer()))
        .then(() => this.isoLayers.push(id))
    } else {
      iso.getData()
        .then(data => this.map.getSource(id).setData(data))
        .then(() => this.showLayer(id))
    }
  }

  toggleLayer (layer) {
    if(this.map.getLayoutProperty(layer, 'visibility') === 'visible') {
       this.hideLayer(layer)
    } else {
      this.showLayer(layer)
    }
  }

  async hideLayer (layer) {
    return this.map.setLayoutProperty(layer, 'visibility', 'none')
  }

  async showLayer (layer) {
    return this.map.setLayoutProperty(layer, 'visibility', 'visible')
  }

  clearIso () {
    this.isoLayers.forEach(layer => this.hideLayer(layer))
  }

  hideChoropleth ({except = null} = {}) {
    let layers

    if (except) {
      layers = this.choroplethLayers.filter((layer) => layer !== except)
    } else {
      layers = this.choroplethLayers
    }
    return layers.forEach((l) => this.hideLayer(l))
  }

  addLayerFromJson (config, data) {
    this.addJsonSource(config.id, data, config.cluster)

    this.map.addLayer({
      id: config.id,
      type: config.type,
      source: config.id,
      paint: config.paint,
      layout: merge(config.layout, { 'visibility': 'visible' })
    })

    return config
  }

  addChoroLayer (id, type, paint = this.defaultChoroPaint) {
    this.map.addLayer(
      {
        'id': id,
        'type': type,
        'source': this.censusBlockId,
        'source-layer': this.bgSourceLayer,
        'paint': paint,
        'layout': { 'visibility': 'visible' }
      },
      'block_group_outlines' // Insert below outlines to keep pin layers on top
    )

    this.choroplethLayers.push(id)
  }

  paintLayer (layerId, paint) {
    Object.entries(paint).map(([prop, values]) => {
      this.map.setPaintProperty(layerId, prop, values)
    })
  }

  addCensusBlocks (id) {
    this.addCensusSource(id)
    this.addBlockOutlines(id)
    this.censusBlockId = id
  }

  addJsonSource (id, data, cluster) {
    this.map.addSource(
      id,
      {
        type: 'geojson',
        data: data,
        cluster: cluster
      }
    )
  }

  addCensusSource(id) {
    this.map.addSource(id, {
      type: 'vector',
      url: this.bgUrl,
      promoteId: 'GEOID',
      sourceLayer: this.bgSourceLayer,
      minzoom: 8,
    })
  }

  addBlockOutlines (id) {
    this.map.addLayer({
      'id': 'block_group_outlines',
      'type': 'line',
      'source': id,
      'source-layer': this.bgSourceLayer,
      'paint': {
        'line-color': '#78716c',
        'line-width': 1,
        'line-opacity': 0.5
      }
    })
  }

  get bgSourceLayer () {
    return {
      'acs5_2019': 'cb_2019_us_bg_500k-besqao',
      'acs5_2022': 'cb_2022_us_bg_500k-8ssnnq'
    }[this.sourceVersion || 'acs5_2019']
  }

  get bgUrl () {
    return {
      'acs5_2019': 'mapbox://mortarstone.0hxdstct',
      'acs5_2022': 'mapbox://mortarstone.28g6wl2n'
    }[this.sourceVersion || 'acs5_2019']
  }

  get defaultChoroPaint () {
    return {
      'fill-color': 'transparent',
      'fill-opacity': 0.6
    }
  }
}
