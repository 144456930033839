import { Controller } from "@hotwired/stimulus"
import { useTransition, useClickOutside } from "stimulus-use"

// Connects to data-controller="modal"
export default class extends Controller {
  // static targets = ['container']

  static values = {
    open: { type: Boolean, default: false },
    name: { type: String, default: 'main' },
    restoreScroll: { type: Boolean, default: true },
    overlay: { type: Boolean, default: true }
  }

  static classes = ['open', 'closed']

  static outlets = ['overlay']

  connect() {
    useTransition(this)
    useClickOutside(this)

    this.appContentElement = document.querySelector('.app-content')

    if (this.openValue) {
      this.handleOpen()
    } else {
      this.element.inert = true
    }

    window.ui.modals[this.nameValue] = this
  }

  clickOutside(event) {
    event.preventDefault()

    if (this.shouldClose(event)) {
      this.close()
    }
  }

  open() {
    this.openValue = true
  }

  close() {
      this.openValue = false
  }

  openValueChanged(val, prevVal) {
    // If openValue is not an attribute, it will be changed to the default,
    // but the leave function will not exist yet. Things work fine after it loads.
    if (this.leave === undefined) { return }

    if (this.openValue) {
      this.handleOpen()
    } else {
      this.handleClose()
    }
  }

  handleOpen() {
    this.preventScrolling()
    this.element.inert = false

    if (this.overlayValue) {
      this.overlayOutlet.show()
    }

    this.element.classList.remove(...this.closedClasses)
    this.element.classList.add(...this.openClasses)

    this.enter()
  }

  handleClose() {
    this.leave()
    this.element.classList.add(...this.closedClasses)
    this.element.classList.remove(...this.openClasses)
    this.allowScrolling()

    if (this.overlayValue) {
      this.overlayOutlet.hide()
    }

    this.element.inert = true
  }

  preventScrolling() {
    // save scroll position if restoreScroll
    if (this.restoreScrollValue) {
      this.saveScrollPosition()
    }

    this.offsetForScrollbar()
    this.lockBodyAndPage()
  }

  allowScrolling() {
    this.removeScrollbarOffset()
    this.unlockBodyAndPage()
    this.restoreScrollPosition()
  }

  lockBodyAndPage() {
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')
    this.appContentElement.classList.add('overflow-hidden')
  }

  unlockBodyAndPage() {
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')
    this.appContentElement.classList.remove('overflow-hidden')
  }

  saveScrollPosition() {
    this.scrollPosition = window.scrollY || document.body.scrollTop
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) { return }

    document.documentElement.scrollTop = this.scrollPosition
  }

  offsetForScrollbar() {
    this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${this.scrollbarWidth}px`
  }

  removeScrollbarOffset() {
    document.body.style.paddingRight = null
  }

  shouldClose(event) {
    return this.openValue && !this.isInBounds(event.clientX, event.clientY)
  }

  isInBounds(x, y) {
    // Firefox seems to return zeros when clicking on select elements
    if (x == 0 && y == 0) { return true }

    return x > this.rect.left &&
      x < this.rect.right &&
      y > this.rect.top &&
      y < this.rect.bottom
  }

  get rect() {
    return this.element.getBoundingClientRect()
  }

}
