import { Controller } from "@hotwired/stimulus"
import { useDebounce, useResize } from "stimulus-use";
import microWait from "../micro_wait";

// Connects to data-controller="ui"
export default class extends Controller {
  // param for sidebar-breakpoint
  static values = {
    sidebarBp: { type: Number, default: 1280 },
    sidebarPinned: { type: Boolean, default: false }
  }

  static targets = ['sidebar', 'overlay', 'navLink', 'hidePanel']
  static classes = ['withSidebar']

  static debounces = ['navUpdate']

  connect() {
    useResize(this)
    useDebounce(this, { wait: 50 })

    app.ui = this
    window.ui = this
    this.lastWidth = this.viewportWidth

    this.modals = {}

    document.addEventListener("ui#showPanel", this.showPanel)
    document.addEventListener("ui#hidePanel", this.hidePanel)

    document.addEventListener("ui#openModal", this.openModal.bind(this))
    document.addEventListener("ui#closeModal", this.closeModal.bind(this))

    // Keep the sidebar in place after a morph refresh.
    // This is probably not smart enough because it could erase the choice a
    // user may have made. We need to track the pre-morph state and apply it after.
    // Waiting for: https://github.com/hotwired/turbo/pull/1097
    document.addEventListener("turbo:morph", this.autoToggleSidebar.bind(this))
  }

  // Stimulus-use callbacks
  //--------------------------------------------------------------------------//

  resize({ width }) {
    this.resizeWidth = width

    if (this.lastWidth != this.resizeWidth) {
      this.autoToggleSidebar()
    }
  }

  // Target Callbacks
  //--------------------------------------------------------------------------//

  navLinkTargetConnected() {
    // this.navLinkTargets.forEach((elem) => console.log(elem.getAttribute('react_to')))
    this.navUpdate()
  }

  hidePanelTargetConnected(target) {
    const name = target.dataset.panelName || 'main_panel'

    app.resetables[name]?.reset()
    app.panels[name].hide()
  }

  // General UI Controls
  //--------------------------------------------------------------------------//

  // Opens a named panel
  // - The panel is populated with a Turbo Stream request outside of this func
  // - Resets the panel because it could be populated
  // - Call show on the named panel (see panel controller)
  //
  //   <a href="#" data-action="ui#showPanel" data-ui-name-param="main_panel">
  //
  showPanel(event) {
    if (event.metaKey || event.ctrlKey) {
      // do nothing if the user is trying to open a new tab
      return true
    } else {
      event?.preventDefault()
      const name = event?.params?.name || 'main_panel'

      app.resetables[name]?.reset()
      app.panels[name].show()
    }
  }

  // Closes a named panel
  hidePanel(event) {
    // Do not prevent default or you will break things.
    // For example this is used to close the panel after deleting a List.
    // The panel is hid while the actual delete request proceeds.
    const name = event?.params?.name || 'main_panel'

    app.resetables[name]?.reset()
    app.panels[name].hide()
  }

  showOverlay(event) {
    event?.preventDefault()

    this.overlay.show()
  }

  hideOverlay(event) {
    event?.preventDefault()

    this.overlay.hide()
  }

  /*
    Useful for if something changes the width of chart containers, but the
    window wasn't resized. (like the sidebar moving)
  */
  resizeCharts() {
    if (!app?.graphs) { return }

    for (const [name, ctrlr] of Object.entries(app.graphs)) {
      ctrlr.resize()
    }
  }


  // Open a named modal
  openModal(event) {
    const name = event?.params?.name || event?.detail?.name || 'main'
    this.modals[name].open(event)
  }

  closeModal(event) {
    const name = event?.params?.name || event?.detail?.name || 'main'
    this.modals[name].close(event)
  }



  // Command Palette
  //--------------------------------------------------------------------------//

  openPalette(event) {
    event?.preventDefault()
    app.cmd?.open()
  }

  // Sidebar Management
  //--------------------------------------------------------------------------//

  /*
    Sidebar terms:

    Show: display the sidebar with an overlay (for smaller screens)
    Pin:  display the sidebar permanently and push the app to the side
  */

  // When the sidebar connects to the DOM, determine if the screen is wide
  // enough to pin the sidebar
  sidebarTargetConnected(elem) {
    microWait(() => this.autoToggleSidebar())
  }

  // Adjust the app layout and pin the sidebar
  pinSidebar(event) {
    event?.preventDefault()

    if (!this.hasWithSidebarClass) { return }

    this.element.classList.add(this.withSidebarClass)
    this.sidebarPanel.pin()
    this.sidebarPinnedValue = true
    this.resizeCharts()
  }

  // Change the app layout to single column
  unpinSidebar(event) {
    event?.preventDefault()

    if (!this.hasWithSidebarClass) { return }

    this.element.classList.remove(this.withSidebarClass)
    this.sidebarPanel.unpin()
    this.sidebarPinnedValue = false
    this.resizeCharts()
  }

  toggleSidebar(event) {
    event?.preventDefault()

    if (this.sidebarPinnedValue) {
      this.unpinSidebar()
    } else {
      this.pinSidebar()
    }
  }

  autoToggleSidebar() {
    if (this.shouldAutoPinSidebar) {
      this.pinSidebar()
    } else {
      this.unpinSidebar()
    }
  }

  get shouldAutoPinSidebar() {
    return (this.viewportWidth >= this.sidebarBpValue)
  }

  get shouldAutoHideSidebar() {
    return this.viewportWidth < this.sidebarBpValue
  }

  get viewportWidth() {
    return window.visualViewport.width
  }

  get sidebarPanel() {
    return window.app.panels[this.sidebarTarget.id]
  }

  // Nav
  //--------------------------------------------------------------------------//

  navUpdate() {
    if (this?.page?.topics) {
      this.dispatch('navUpdate', { bubbles: true, detail: { topics: this.page.topics } })
    }
  }

  // Notification Toaster
  //--------------------------------------------------------------------------//

  // initToaster() {
  //   const toaster = document.createElement('div')
  //   toaster.id = "toaster"
  //   toaster.classList.add(...'fixed z-10 w-96 right-0 p-4 pointer-events-none'.split(' '))
  //   toaster.dataset.controller = "ui--toaster"

  //   document.firstElementChild.insertBefore(toaster, document.body)
  // }

  // Debug Utils
  //--------------------------------------------------------------------------//

  log(event) {
    console.log(event)
  }
}
