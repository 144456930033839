import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'option', 'color', 'placeholder' ]

  connect() {
    let color = this.colorTarget.value

    this.optionTargets.forEach((target) => {
      if (color == target.dataset.value) {
        target.classList.add('selected')
      }
    })
  }

  select(event) {
    this.optionTargets.forEach((target) => {
      target.classList.remove('selected')
    })
    event.currentTarget.classList.add('selected')
    this.colorTarget.value = event.currentTarget.dataset.value
  }

  selectInline(event) {
    let value = event.currentTarget.dataset.value

    this.colorTarget.value = value
    this.changePlaceholder(value)
    this.optionTargets.forEach((target) => {
      target.classList.remove('selected')
    })
    event.currentTarget.classList.add('selected')
  }

  changePlaceholder(value) {
    let existing = Array.from(this.placeholderTarget.classList).find(i => { return i.startsWith('bg') })
    this.placeholderTarget.classList.remove(existing)
    this.placeholderTarget.classList.add(this.previewColor(value))
  }

  previewColor(color) {
    switch(color) {
      case 'amber':
        return 'bg-amber-300'
      case 'orange':
        return 'bg-orange-400'
      default:
        return `bg-${color}-500`
    }
  }
}
