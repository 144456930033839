import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="searches--sort-headings"
export default class extends Controller {
  static targets = ['heading']

  static values = {
    sortUrl: String,
    resultsUrl: String,
    currentFilterId: Number
  }

  connect() {}

  // on click, send a post request with the column's info
  sort({ params }) {
    this.postSort(params.filter, params.column, params.dir)
  }

  async postSort(filter, column, direction) {
    let sort_field = `${filter}.${column}`

    let data = {
      search: {
        sort_field: sort_field,
        sort_direction: direction
      },
      current_filter_id: this.currentFilterIdValue
    }

    let response = await post(
      this.sortUrlValue,
      { body: JSON.stringify(data), responseKind: 'turbo-stream' }
    )

    if (response.ok) {
      // this.resultsFrame.src = this.resultsUrlValue
      console.log(response);
    }
  }

  get resultsFrame() {
    return document.querySelector('#search_results')
  }
}
