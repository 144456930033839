import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce';

export default class extends Controller {
  static targets = [ 'original', 'results', 'hidden' ]

  connect () {
    this.changeDisplay();
  }

  search (event) {
    let query = event.currentTarget.value
    let url = $(event.currentTarget).data("url")

    if (query.length > 2) {
      debounce(this.request(url, query), 500)
    }

    if (query.length == 0) {
      this.revert()
      this.changeDisplay()
    }
  }

  request (url, query) {
    jQuery.ajax({
      url: url,
      data: { query: query },
      dataType: 'html',
      success: (results) => {
        this.showResults(results)
      }
    }).done( () => {
      this.changeDisplay()
      this.dispatchLoadEvent()
    })
  }

  show (event) {
    $(this.hiddenTarget).attr('data-state', !event.currentTarget.checked)
    this.changeDisplay()
  }

  hide (event) {
    $(this.hiddenTarget).attr('data-state', event.currentTarget.checked)
    this.changeDisplay()
  }

  changeDisplay () {
    if (this.hasHiddenTarget) {
      let hide = (this.hiddenState == "true") ? true : false
      $(this.hiddenSelector).each( function() {
        $(this).toggleClass('hidden', hide)
      })
    }
  }

  showResults (results) {
    $('.pagination').hide()
    $(this.originalTarget).hide()
    $(this.resultsTarget).html(results)
    $(this.resultsTarget).show()
  }

  revert () {
    $(this.resultsTarget).hide()
    $(this.originalTarget).show()
    $('.pagination').show()
  }

  dispatchLoadEvent () {
    document.dispatchEvent(new Event('table_filter:load'));
  }

  get hiddenState () {
    return $(this.hiddenTarget).attr('data-state') || false
  }

  get hiddenSelector () {
    return $(this.hiddenTarget).attr('data-selector')
  }
}
