import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delayable"
export default class extends Controller {
  static values = {
    key: String,
    src: String
  }

  connect() {
    // console.log('delayable connected');
  }

  load({ detail: { key } }) {
    // console.log(`Got ${key} / wanted ${this.keyValue}`)
    if (key == this.keyValue) {
      this.element.src = this.srcValue
    }
  }
}
