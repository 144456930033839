import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe--mandates"
export default class extends Controller {
  // Used to issue a new mandate for an existing payment method (ACH)
  static values = { clientSecret: String, redirectUrl: String }

  connect() {}

  confirm(evnt) {
    evnt.preventDefault()

    this.stripe
      .confirmUsBankAccountSetup(this.clientSecretValue)
      .then(({setupIntent, error}) => {
        if (error) {
          this.redirectWithError()
        } else if (setupIntent.status === "succeeded") {
          this.redirectWithSuccess()
        }
      }
    )
  }

  redirectWithSuccess() {
    this.redirect({ 'notify': 'success', 'type': 'notice' })
  }

  redirectWithError() {
    this.redirect({ 'notify': 'error', 'type': 'alert' })
  }

  redirect(params = null) {
    let url = this.redirectUrlValue
    if (params) {
     url = url + this.paramsToQueryString(params)
    }
    window.location.href = url
  }

  paramsToQueryString(params) {
    return "?" + new URLSearchParams(params).toString()
  }

  get stripe() {
    return window.app.stripe
  }
}
