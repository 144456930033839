import tippy from 'tippy.js'
import debounce from 'debounce'

document.addEventListener("turbo:load", function () {
  tippy(document.querySelectorAll('[data-content-tippy]'))
  tippy(document.querySelectorAll('[data-tippy-content]'))
})

document.addEventListener("contentLoaded", function() {
  debounce(tippy(document.querySelectorAll('[data-tippy-content]')), 100)
})

// For remote loaded frames
document.addEventListener("turbo:frame-load", function () {
  tippy(document.querySelectorAll('[data-content-tippy]'))
  tippy(document.querySelectorAll('[data-tippy-content]'))
})