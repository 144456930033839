import { Controller } from "@hotwired/stimulus"
import Isochrone from "../../maps/isochrone"

export default class extends Controller {
  static targets = ["mode", "duration"]
  static values = {
    lat: Number,
    lng: Number,
    id: String
  }

  initialize () {
    window.app.isos ??= {}
  }

  connect () {
    const rel = Object.keys(window.app.isos).length
    this.model = new Isochrone(this.idValue, this.lngValue, this.latValue, rel, this.mode, this.duration)
    this.element.innerHTML = this.model.innerHTML
    window.app.isos[this.model.id] = this.model
    this.addEventListeners()
    this.update()
  }

  moved ({ detail: { lng, lat } }) {
    this.lngValue = lng
    this.latValue = lat
    this.update()
  }

  update () {
    if (this.validValues && this.idValue !== 'none') {
      this.model.update(this.lngValue, this.latValue, this.duration, this.mode)
      this.dispatch('update', { target: window, detail: { id: this.model.id }})
    }
  }

  remove () {
    if (this.idValue !== 'none') {
      this.dispatch('remove', { target: window, detail: { id: this.idValue } })
    }
  }

  removeEventListeners () {
    window.removeEventListener(`${this.idValue}-moved`, event => this.moved(event))
    window.removeEventListener(`${this.idValue}-moving`, event => this.remove(event))
  }

  addEventListeners () {
    window.addEventListener(`${this.idValue}-moved`, event => this.moved(event))
    window.addEventListener(`${this.idValue}-moving`, event => this.remove(event))
  }

  get validValues () {
    return this.mode && this.duration
  }

  get duration () {
    if (this.hasDurationTarget) {
      return this.durationTarget.value
    }
  }

  get mode () {
    if (this.hasModeTarget) {
      return this.modeTarget.value
    }
  }
}
