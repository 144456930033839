import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leader"
export default class extends Controller {
  static values = {
    slug: String
  }

  connect() {
    if (this.element.tagName == 'TURBO-FRAME') {
      this.element.loaded.then(this.announce)
    } else {
      this.announce()
    }
  }

  announce = () => {
    this.dispatch('loaded', { bubbles: true, detail: { key: this.slugValue } })
  }
}
