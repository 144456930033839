import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dismissable"
export default class extends Controller {
  connect() {}

  dismiss() {
    this.element.remove()
  }
}
