import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--step"
export default class extends Controller {
  static classes = ['dragStart', 'dragEnter']

  static values = {
    listId: Number,
    position: { type: Number, default: 0 },
    payload: { type: Object, default: {} }
  }

  connect() {
    document.addEventListener('ui--draggable:dragging', this.dragWatch)
    document.addEventListener('workflows--dropzone:transfer', this.transferComplete)

    this.baseClasses = this.element.classList

    app.steps ??= []
    app.steps.push(this)
  }

  // Because elements inside the step intefere with drag events, we need to
  // track whether the cursor is within the bounds of the box to control classes.
  //
  dragWatch = (event) => {
    // Need to do this here instead of on connect as the left and right change when parent div is scrolled
    this.box = this.element.getBoundingClientRect()

    if (this.inBounds(event.detail)) {
      if (this.hasDragEnterClass) {
        this.element.classList.add(...this.dragEnterClasses)
      }
    } else {
      this.removeEnterClasses()
    }
  }

  /**
   * Reacts to drag start event on document, defined in data-action on step.
   * @param {event} event
   *
   */
  dragStart = (event) => {
    // This will catch both Step and Listing drags, so ignore Steps
    if (event.target.dataset.workflowTarget == 'step') { return false }

    this.element.classList.add(...this.dragStartClasses)
  }

  dragEnd = (event) => {
    this.element.classList.remove(...this.dragStartClasses)
  }

  inBounds(position) {
    return position.x >= this.box.left &&
      position.x <= this.box.right &&
      position.y >= this.box.top &&
      position.y <= this.box.bottom
  }

  // We watch the cursor during the drag to toggle dragEnterClasses, but after a
  // drop we lose that tracking. This removes the classes after a successful drop.
  transferComplete = (event) => {
    this.removeEnterClasses()
  }

  removeEnterClasses() {
    if (this.hasDragEnterClass) {
      this.element.classList.remove(...this.dragEnterClasses)
    }
  }
}
