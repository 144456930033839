import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addAction", "action"]

  connect () {
    this.toggleDisable();
  }

  toggleDisable() {
    this.addActionTargets.forEach((target) => {
      let count = this.actionCount(target.dataset.actionType);

      if(count >= target.dataset.limit) {
        target.disabled = true;
      } else {
        target.disabled = false;
      }
    })
  }

  actionCount(actionType) {
    return this.actionTargets.filter((target) =>
      (target.dataset.actionType == actionType) && this.notRemoved(target)
    ).length
  }

  notRemoved(target) {
    let fields = target.closest(".nested-fields");

    return fields.querySelector("input[name*='_destroy']").value == false
  }
}
