// import { d3Selection } from "d3";
import * as d3 from 'd3';
import { bb } from 'billboard.js';

import * as strftime from 'strftime';

// TODO: move general functions to their own module

function handleResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(new Error(response.statusText))
  }
}

function resolveJson(response) {
  return response.json()
}

function domConfig(elem) {
  return {
    bindto: `#${elem.id}`,
    dom_format: elem.dataset['format']
  }
}

function buildRemotePie(remote_src, dom_config) {
  fetch(remote_src, {credentials: 'include'})
  .then(handleResponse)
  .then(resolveJson)
  .then(function (config) {
    config = Object.assign(config, dom_config)

    bb.generate(config);

    $(config['bindto']).prev('.loadbar').hide();
  })
  .catch((error) => console.log(error))
}

function buildPies() {
  const pies   = document.querySelectorAll('.js-pie-chart')

  for (var pie of pies) {
    let dom_config = domConfig(pie);
    let remote_src = pie.dataset['url'];

    if (remote_src) {
      buildRemotePie(remote_src, dom_config)
    }
  }
}

document.addEventListener("turbo:load", function () {
  buildPies()
})
