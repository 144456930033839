import merge from 'deepmerge'
import { useFormatting } from "./chart_formatting"

export default class TooltipConfigurator {
  constructor(config, data) {
    useFormatting(this)
    this.sourceConfig = config
    this.sourceData = data || []
  }

  get config() {
    const cfg = {
      tooltip: {
        format: {
          value: this.valueFormatter
        }
      }
    }

    if (this.shouldLookupName) {
      cfg.tooltip.format.name = (name, ratio, id, idx, obj) => {
        return this.sourceData[idx][this.nameMap(id)]
      }

      // Having the title and longer, date-based names looks cluttered, this
      // disables the tooltip title by default
      cfg.tooltip.format.title = () => false
    }

    if (this.shouldUsePopTitle) {
      cfg.tooltip.format.title = undefined
      cfg.tooltip.onshown = this.popTitle
    }

    return cfg
  }

  get msConfig() {
    return this.sourceConfig.ms
  }

  get fallbackFormat() {
    return this.msConfig?.type || 'number'
  }

  get fallbackFormatter() {
    return this.formatters[this.fallbackFormat]
  }

  get dataFormats() {
    return this.msConfig?.data_formats
  }

  get dataFormatsIsGiven() {
    return Boolean(this.dataFormats)
  }

  valueFormatter = (value, ratio, id, index) => {
    const fmt = this.tooltipFormat(id)

    if (fmt) {
      return this.formatters[this.tooltipFormat(id)](value)
    } else {
      return this.formatters[this.fallbackFormat](value)
    }
  }

  tooltipFormat(name) {
    if (this.dataFormatsIsGiven) {
      return this.dataFormats[name]
    } else {
      return this.fallbackFormat
    }
  }

  get unit() {
    return this.msConfig?.unit
  }

  get shouldLookupName() {
    return Boolean(this.msConfig?.tooltip?.name_map) && (
      this.msConfig?.date_filter == 'recent' ||
      this.msConfig?.tooltip?.lookup_name
    )
  }

  nameMap(id) {
    return this.msConfig?.tooltip?.name_map[id]
  }

  get shouldUsePopTitle() {
    return Boolean(this.msConfig?.tooltip?.use_pop_title)
  }

  // A kludge to change the tooltip title to something from our data
  popTitle(dt) {
    // `this` is an instance of the chart
    let bindId = this.internal.config.bindto
    // drop the '#'
    bindId = bindId.substr(1, bindId.length)

    // this finds the index of the current data point
    const idx = dt[0].index
    // find the original row of data from the backend
    const ourData = app.dataLookup(bindId, idx)

    // Find the tooltip title, then change the text
    this.$.tooltip.select('table.bb-tooltip > tbody > tr:first-child > th')?.text(ourData.pop_title)
  }
}

export { TooltipConfigurator }
