import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--footer"
export default class extends Controller {
  static values = {
    src: { type: String, default: 'ui_footer_src' },
    id:  { type: String, default: 'ui_app_footer' }
  }

  connect() {
    if (Boolean(this.srcTemplate)) {
      this.setFooter()
    }
  }

  setFooter() {
    this.footer.innerHTML = this.srcContent
  }

  get footer() {
    return document.getElementById(this.idValue)
  }

  get srcTemplate() {
    return document.getElementById(this.srcValue)
  }

  get srcContent() {
    return this.srcTemplate.innerHTML
  }
}
