// This controller provides a loading indicator for turbo-frames.
// It watches the src attribute for changes (mutations) and reacts.

// See https://github.com/stimulus-use/stimulus-use/blob/main/docs/use-mutation.md
// for docs on useMutation

// An alternative to this would be to listen for the turbo:before-fetch-request
// event, but that fires on document and there doesn't seem to be a way
// to tell which turbo-frame is the target.


import { Controller } from "@hotwired/stimulus"
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useMutation(this, { attributeFilter: ['src'], attributeOldValue: true })
  }

  mutate(entries) {
    for(const mutation of entries) {
      // Don't activate the loading state if the frame is already loaded
      // (Turbo won't reload the frame)
      if (this.element.src != mutation.oldValue) {
        this.element.innerHTML = this.loader
      }
    }
  }

  get loader() {
    return `
      <div class="loadbar">
        <div class="loadbar-mover"></div>
      </div>
    `
  }
}
