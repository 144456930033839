import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu']

  connect() {
    app.dropdowns = app.dropdowns || []
    app.dropdowns.push(this)
    this.index = app.dropdowns.indexOf(this)

    document.addEventListener('click', this.hide)
    document.addEventListener('dropdown:toggle', this.hide)
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.element.classList.toggle('dropdown--open')
    app.dispatch(this.element, "dropdown:toggle", { detail: { index: this.index } })
  }

  hide = (event) => {
    if (event.detail?.index == this.index) return;

    this.element.classList.remove('dropdown--open')
  }

  unBindEvents() {}
}
