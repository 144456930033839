import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  connect() {
    let klass = this.element.dataset.initiallyDisabled;
    let elements = this.find_by_class(klass);

    if(elements) {
      this.disable(elements);
    }
  }

  change(event) {
    let disableKlass = event.currentTarget.dataset.classToDisable;
    let enableKlass = event.currentTarget.dataset.classToEnable;

    if(disableKlass) {
      let elements = this.find_by_class(disableKlass);
      this.disable(elements);
    }

    if(enableKlass) {
      let elements = this.find_by_class(enableKlass);
      this.enable(elements);
    }
  }

  enable(elements) {
    elements.forEach((target) => {
      if(target._flatpickr) {
        $(target._flatpickr._input).prop('disabled', false);
      }
      $(target).prop('disabled', false);
    })
  }

  disable(elements) {
    elements.forEach((target) => {
      $(target).prop('disabled', true);
    })
  }

  find_by_class(klass) {
    return document.querySelectorAll('.' + klass);
  }
}
