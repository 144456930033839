import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static values = {
    expanded: Boolean,
    topic: String
  }

  static classes = ['expanded', 'area']
  static targets = ['area', 'state']

  connect() {
    if (this.expandedValue) {
      this.expand()
    }
  }

  toggle(event) {
    event.preventDefault()

    if (this.expandedValue) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  react({ detail: { topics } }) {
    if (this.hasTopicValue && topics.includes(this.topicValue)) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand() {
    this.addClassesToElem(this.element, this.expandedClassOrDefault)

    if (this.hasAreaClass && this.hasAreaTarget) {
      this.addClassesToElem(this.areaTarget, this.areaClasses)
    }

    this.expandedValue = true
  }

  collapse() {
    this.removeClassesFromElem(this.element, this.expandedClassOrDefault)

    if (this.hasAreaClass && this.hasAreaTarget) {
      this.removeClassesFromElem(this.areaTarget, this.areaClasses)
    }

    this.expandedValue = false
  }

  // It's possible to have multiple classes so we use the array version.
  get expandedClassOrDefault() {
    if (this.hasExpandedClass) {
      return this.expandedClasses
    } else {
      return ["accordion--expanded"]
    }
  }

  addClassesToElem(elem, classes) {
    classes.forEach((item) => elem.classList.add(item))
  }

  removeClassesFromElem(elem, classes) {
    classes.forEach((item) => elem.classList.remove(item))
  }
}
