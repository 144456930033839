import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['closeButton', 'content', 'title']

  connect() {
    window.app.stack = {
      controller: this,
      container: this.element,
      content: this.contentTarget,
      title: this.titleTarget,
      close: () => this.close(),
      open: () => this.open()
    }

    document.addEventListener('click', (event) => {
      if (this.shouldClose(event)) {
        this.close()
      }
    })
  }

  close() {
    this.element.classList.remove('visible')
  }

  open() {
    this.element.classList.add('visible')
  }

  get isOpen() {
    return this.element.classList.contains('visible')
  }

  shouldClose(event) {
    return this.isOpen && !this.isInBounds(event.clientX, event.clientY)
  }

  isInBounds(x, y) {
    // Firefox seems to return zeros when clicking on select elements
    if (x == 0 && y == 0) { return true }

    return x > this.rect.left &&
      x < this.rect.right &&
      y > this.rect.top &&
      y < this.rect.bottom
  }

  get rect() {
    return this.element.getBoundingClientRect()
  }

  visit() {
    if (this.data.has('currentUrl')) {
      Turbo.visit(this.data.get('currentUrl'))
    }
  }

  isFlatpickerElement(elem) {
    let fp = document.querySelector('.flatpickr-calendar')
    if (!Boolean(fp)) return false

    return fp.contains(elem)
  }
}
