import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['state']
  static values = { criteria: String, regex: Boolean }

  update ({ params: { value } }) {
    const val = value || event.currentTarget.value

    if (this.hasCriteriaValue) {
      this.toggleOnCriteria(val)
    } else {
      this.stateTargets.forEach(element => element.classList.toggle('hidden') )
    }
  }

  toggleOnCriteria (value) {
    if (this.criteriaMet(value)) {
      this.stateTargets.forEach(element => element.classList.remove('hidden') )
    } else {
      this.stateTargets.forEach(element => element.classList.add('hidden') )
    }
  }

  criteriaMet (value) {
    if (this.hasCriteriaValue) {
      return this.regexValue ? new RegExp(this.criteriaValue).test(value) : this.criteriaValue === value
    } else {
      return true
    }
  }
}
