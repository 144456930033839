import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['category', 'section', 'input']

  connect () {
    this.update()
  }

  update () {
    this.otherSections.forEach((target) => { target.classList.add('hidden')})
    this.currentSection.classList.remove('hidden')

    this.disable(this.otherInputs)
    this.enable(this.currentInputs)
  }

  enable (elements) {
    elements.forEach((target) => {
      $(target).prop('disabled', false);
    })
  }

  disable (elements) {
    elements.forEach((target) => {
      $(target).prop('disabled', true);
    })
  }

  get currentInputs () {
    return this.inputTargets.filter((target) => {
      return target.dataset.categoryId === this.categoryId
    })
  }

  get otherInputs () {
    return this.inputTargets.filter((target) => {
      return target.dataset.categoryId !== this.categoryId
    })
  }

  get otherSections () {
    return this.sectionTargets.filter((target) => {
      return target.dataset.categoryId !== this.categoryId
    })
  }

  get currentSection () {
    return this.sectionTargets.find((target) => {
      return target.dataset.categoryId === this.categoryId
    })
  }

  get categoryId () {
    return this.categoryTarget.value
  }
}
