import { Controller } from "@hotwired/stimulus"
import MapController from '../map_controller'

export default class extends MapController {
  static values = {
    lat: Number,
    lng: Number,
    zoom: Number,
    bounds: Array,
    locationsUrl: String
  }

  connect () {
    super.connect()

    this.map.on('load', () => {
      this.addPins(this.locationsUrlValue)
      this.controlPanelTarget.classList.add('hidden')
    })
  }
}
