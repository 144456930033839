import mapboxgl from 'mapbox-gl'

export default class Marker {
  constructor (lat, lng) {
    this.lat = lat
    this.lng = lng
    this.id = `marker-${Date.now()}`
  }

  addTo(map) {
    const marker = new mapboxgl.Marker({
        draggable: true
      })
      .setLngLat([this.lng, this.lat])
      .setPopup(this.popup)
      .addTo(map)

    marker.on('dragstart', () => {
      const event = new CustomEvent(`${this.id}-moving`, { bubbles: true, detail: { id: this.id }})
      window.dispatchEvent(event)
    })

    // Adjust coordinates when dragged by user
    marker.on('dragend', () => {
      const lngLat = marker.getLngLat()
      this.lat = lngLat['lat']
      this.lng = lngLat['lng']
      const event = new CustomEvent(`${this.id}-moved`, { bubbles: true, detail: { id: this.id, lng: this.lng, lat: this.lat} })
      window.dispatchEvent(event)
    })

    return marker
  }

  get html () {
    return `
      <div>
        <header class="l-flex-between l-px-2">
          <h4 class="t-size-base">Show travel time</h4>
        </header>
        <br />
        <div>
          <form action="#"
            data-controller="maps--isochrone"
            data-maps--isochrone-lat-value="${this.lat}"
            data-maps--isochrone-lng-value="${this.lng}"
            data-maps--isochrone-id-value="${this.id}">
          </form>
        </div>
      </div>
    `
  }

  get popup () {
    return new mapboxgl.Popup({ maxWidth: '31rem', className: 'mb-popup' })
      .setHTML(this.html)
  }
}
