import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="perms--row"
export default class extends Controller {
  static values = {
    checkable: Boolean,
    parent: String,
    name: String
  }

  static targets = ['checkbox']

  connect() {
    // window.app.permRows ??= []
    // window.app.permRows.push(this)

    if (!this.checkableValue) {
      this._disable()
    }
  }

  highlight({ detail: { parent } }) {
    if (parent == this.nameValue) {
      this.element.classList.add('bg-yellow-100')
    }
  }

  diminish({ detail: { parent } }) {
    if (parent == this.nameValue) {
      this.element.classList.remove('bg-yellow-100')
    }
  }

  over() {
    this.dispatch('over', { detail: { parent: this.parentValue } })
  }

  out() {
    this.dispatch('out', { detail: { parent: this.parentValue } })
  }

  // when the parent is unchecked
  // add the disabled class
  // disable the checkbox
  disable({ detail: { parent } }) {
    if (this.parentValue == parent) {
      this._disable()
    }
  }

  // when the parent is checked
  // remove the disabled class
  // enable the checkbox
  enable({ detail: { parent } }) {
    if (this.parentValue == parent) {
      this._enable()
    }
  }

  change() {
    if (this.isChecked) {
      this.dispatch('enable',  { detail: { parent: this.nameValue } })
    } else {
      this.dispatch('disable', { detail: { parent: this.nameValue } })
    }
  }

  // private

  get isChecked() {
    return this.checkboxTarget.checked
  }

  _disable() {
    this.element.classList.add('perms--disabled')
    this.checkboxTarget.checked = false
    this.checkboxTarget.disabled = true
  }

  _enable() {
    this.element.classList.remove('perms--disabled')
    this.checkboxTarget.disabled = false
  }
}
