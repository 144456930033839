import { Controller } from "@hotwired/stimulus"
import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js"
import { post } from '@rails/request.js'

// Connects to data-controller="sortable"
export default class extends Controller {
  static values = {
    updateUrl: String,
    handleClass: String,
    sortAttr: { type: String, default: 'data-id' },
    ghostClass: { type: String, default: 'sortable-dragging' },
    draggable: { type: String, default: '.sortable-item' },
    groupName: String,
    receive: Array,
  }

  initialize() {
    if (app.autoScrollMounted === undefined) {
      Sortable.mount(new AutoScroll());
      app.autoScrollMounted = true;
    }
  }

  connect() {
    this.sortable = Sortable.create(this.element, this.options)
    // app.sortables?.push(this.sortable)
  }

  setData(dataTransfer, dragElem) {
    const position = dragElem.dataset['workflows--step-position-value']
    const payload = { position: position }

    dataTransfer.setData('text/plain', JSON.stringify(payload))
  }

  onEnd = (event) => {
    const ids = this.sortable.toArray()
    this.updatePositions(ids)
  }

  async updatePositions(ids) {
    if (ids.length > 0 && this.hasUpdateUrlValue) {
      const response = await post(
        this.updateUrlValue,
        { body: JSON.stringify({ positions: ids})
      })

      // if (response.ok) {
      //   console.log(response.text);
      // }
    }
  }

  get options() {
    let opts = {
      group: this.groupOpts,
      animation: 150,
      ghostClass: this.ghostClassValue,
      draggable: this.draggableValue,
      dataIdAttr: this.sortAttrValue,
      setData: this.setData,
      onEnd: this.onEnd,
      scroll: true,
      scrollSensitivity: 50,
      scrollSpeed: 20,
      forceAutoScrollFallback: true
    }

    if (this.hasHandleClassValue) {
      opts['handle'] = this.handleClassValue
    }

    return opts
  }

  get groupOpts() {
    return {
      name: this.groupNameValue,
      pull: true,
      put: this.hasReceiveValue ? this.receiveValue : false
    }
  }
}
