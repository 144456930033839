import { Controller } from "@hotwired/stimulus"
import { useMeta } from 'stimulus-use'
import posthog from 'posthog-js'


// Connects to data-controller="ui--analytics"
export default class extends Controller {
  static metaNames = ['userId', 'email', 'name', 'companyId', 'companyName']

  connect() {
    useMeta(this)
    this.init()

    window.app.phg = this.phg
  }

  init() {
    // if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
    //   posthog.init(
    //     'phc_UBUSpxNxtSHFywAa1HbsVsHC6AyBXpXDSae615QePx6',
    //     { api_host: 'https://app.posthog.com' }
    //   )
    // }

    this.phg = posthog.init('phc_UBUSpxNxtSHFywAa1HbsVsHC6AyBXpXDSae615QePx6', {
      api_host: 'https://app.posthog.com',
      disable_session_recording: true
    })

    posthog.identify(
      this.userIdMeta,
      {
        email: this.emailMeta,
        name: this.nameMeta,
        company: this.companyIdMeta
      }
    )

    posthog.group('company', this.companyIdMeta, {
      name: this.companyNameMeta
    })
  }
}
