import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["originator"]

  static values = {
    eventName: String,
    cleanup: { type: Boolean, default: false }
  }

  connect() {
    this.relay()
  }

  relay() {
    let evnt = new Event(this.eventNameValue, { bubbles: true })

    if (this.hasOriginatorTarget) {
      this.originatorTarget.dispatchEvent(evnt)
    } else {
      document.dispatchEvent(evnt)
    }

    if (this.cleanupValue) {
      this.cleanup()
    }
  }

  cleanup() {
    this.element.remove()
  }
}
