import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core'

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ['body']
  static classes = ['open']

  static values = {
    placement: { type: String, default: 'bottom' },
    exclusive: { type: Boolean, default: true },
    open: { type: Boolean, default: false },
    theme: { type: String, default: 'light' },
    alignElem: String,
  }

  connect() { this.init() }

  // bodyTargetConnected() {
  //   this.styleBody()
  // }

  // After morph/page-refresh
  // Use with data-action="turbo:morph@window->popover#reconnect"
  reconnect() {
    this.popper.destroy()
    this.styleBody()
    this.setupPopper()
  }

  init() {
    app.popovers = app.popovers || []
    app.popovers.push(this)
    this.index = app.popovers.indexOf(this)

    this.styleBody()

    // Listen for all clicks
    document.addEventListener('click', this.hide)

    // If exclusive, then close when other popovers open. Otherwise it's
    // possible for multiple popovers to be open. (Imagine using this for menus)
    if (this.exclusiveValue) {
      document.addEventListener('popover:open', this.hide)
    }
    // Use popper js to position the popover
    this.setupPopper()
  }

  toggle = (event) => {
    event.preventDefault()
    event.stopPropagation() // otherwise doc listener picks up click

    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  // Called from event listeners
  hide = (event) => {
    if (this.shouldClose(event)) {
      this.close()
    }
  }

  // Performs all the actions to show the popover
  open() {
    this.dispatch('open', { detail: { popover: this.index } })
    this.popper.update()
    this.bodyTarget.classList.add(this.openClassName)
    this.openValue = true
  }

  // Does everything to hide the popover
  close() {
    this.bodyTarget.classList.remove(this.openClassName)
    this.openValue = false
  }

  styleBody() {
    this.bodyTarget.classList.add('popover-container')
    this.bodyTarget.id = `popover-${this.index}-container`

    if (this.hasThemeValue) {
      this.bodyTarget.classList.add(`popover--${this.themeValue}`)
    }

    let arrow = document.createElement('span')
    arrow.classList.add('popover-arrow')
    arrow.setAttribute('data-popper-arrow', '')

    this.bodyTarget.append(arrow)
  }

  setupPopper() {
    this.popper = createPopper(this.alignElem, this.bodyTarget, this.options)
  }

  // Default Popper JS options
  get options() {
    return {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  }

  // Default open class name
  get openClassName() {
    if (this.hasOpenClass) {
      return this.openClass
    } else {
      return 'popover--open'
    }
  }

  get isOpen() {
    return this.openValue
  }

  // Align to a specific element, or default to the controller element
  get alignElem() {
    if (this.hasAlignElemValue) {
      return this.element.querySelector(this.alignElemValue)
    } else {
      return this.element
    }
  }

  // Click/hide handling

  shouldClose(event) {
    return this.isOpen &&
      Boolean(event) &&
      this.bodyRect &&
      !this.isInBounds(event.clientX, event.clientY)
  }

  isInBounds(x, y) {
    // Firefox seems to return zeros when clicking on select elements
    if (x == 0 && y == 0) { return true }

    return x > this.bodyRect.left &&
      x < this.bodyRect.right &&
      y > this.bodyRect.top &&
      y < this.bodyRect.bottom
  }

  get bodyRect() {
    return this.bodyTarget?.getBoundingClientRect()
  }
}
