import * as d3 from 'd3'
import { bb, gauge } from 'billboard.js'
import merge from 'deepmerge'

import { Controller } from "@hotwired/stimulus"
import { useIntersection, useWindowResize } from 'stimulus-use'

// Connects to data-controller="gauge"
export default class extends Controller {
  static targets = ['container', 'loadbar']

  static values = {
    bindId: String,
    title: String,
    category: String,
    color: { type: String, default: '#0ea5e9' },
    completed: Number,
  }

  connect() {
    app.graphs ??= []
    app.graphs.push(this)

    useWindowResize(this)
    useIntersection(this)
    this.load()
  }

  disconnect() {
    if (Boolean(this.chart)) {
      window.app.charts?.splice(window.app.charts.indexOf(this.chart), 1)
      this.chart.destroy()
    }
  }

  // Stim-use Callbacks
  // ------------------------------------------------------------------------ //

  appear(entry) {
    this.chart.flush()
    this.resize()
  }

  disappear(entry) {}

  windowResize({ width, height, event }) {
    if (this.chart && this.isVisible) this.resize()
  }

  // Chart instance management
  // ------------------------------------------------------------------------ //

  load() {
    if (this.shouldLoad) {
      this.generate()
    }
  }

  generate() {
    this.chart = bb.generate(this.config)
  }

  destroy() {
    this.chart.destroy()
  }

  resize() {
    this.chart?.resize({ width: this.targetWidth, height: this.targetHeight })
  }

  get shouldLoad() {
    // also consider delayed loading
    return !(document.documentElement.hasAttribute("data-turbo-preview"))
  }

  // Data
  // ------------------------------------------------------------------------ //

  get baseConfig() {
    return {
      data: {
        columns: [
          [this.categoryValue, this.completedValue]
        ],
        type: gauge(),
        colors: this.colors
      },
      resize: { auto: false },
      legend: { show: false },
      bindto: this.bindId
    }
  }

  get sizeConfig() {
    return {
      size: {
        width: this.targetWidth,
        height: this.targetHeight
      }
    }
  }

  get controlConfig() {
    return {}
  }

  get config() {
    return merge.all([
      this.baseConfig,
      // this.sizeConfig,
      this.controlConfig
    ])
  }

  // Find the chart container element
  get bindElement() {
    return document.querySelector(this.bindId)
  }

  get bindId() {
    if (this.hasBindIdValue) {
      return `#${this.bindIdValue}`
    } else {
      return `#${this.element.id}`
    }
  }

  get colors() {
    let c = {}
    c[this.categoryValue] = this.colorValue

    return c
  }

  // Resizing
  // ------------------------------------------------------------------------ //

  get container() {
    return this.element.parentElement
  }

  get box() {
    return this.container.getClientRects()[0]
  }

  get targetWidth()  { return Math.round(this.box.width) }

  get targetHeight() {
    const h = Math.round(this.box.height)
    const m = 208 // max height

    return (h < m) ? h : m
  }
  // get targetHeight() { return 208; }
}
