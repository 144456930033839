import debounce from 'debounce';

class TableFilter {
  constructor(elem) {
    this.elem     = $(elem)
    this.url      = this.elem.data('url')
    this.field    = this.elem.find('[data-behavior="filter-field"]')
    this.original = $('[data-behavior="filter_original"]')
    this.target   = $('[data-behavior="filter_results"]')
  }

  // get field() {
  //   const field = this.elem.find('[data-behavior="filter-field"]')
  // }

  search = () => {
    let query = this.field.val()

    if (query.length > 2) {
      jQuery.ajax({
        url: this.url,
        data: { query: query },
        dataType: 'script',
        success: () => true
      })
    }

    if (query.length == 0) {
      this.revert()
    }
  }

  showResults = () => {
    $('.pagination').show()
    this.original.hide()
    this.target.html('')
    this.target.show()
  }

  revert = () => {
    this.target.hide()
    this.original.show()
    $('.pagination').show()
  }
}

document.addEventListener("turbo:load", function() {
  $(document).off('keyup');

  if ($('[data-behavior="table_filter"]').length > 0) {
    app.tableFilter = new TableFilter($('[data-behavior="table_filter"]'));

    let debouncedQuery = debounce(app.tableFilter.search, 500);

    $(document).on('keyup', app.tableFilter.field, debouncedQuery);
  }
});
