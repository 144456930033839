import { DateTime, Interval } from 'luxon';
import { DateNormalizer } from '../date_utils/normalizer'

export default class DayStrategy {
  constructor() {
    this.period

    this._fromDate
    this._unit
    this._dateStart
    this._dateEnd

    this.customRange = false
  }

  get unit() {
    return this._unit || 'day'
  }

  set unit(unit) {
    this._unit = unit
  }

  get dateStart() {
    return this._dateStart || this.autoDateStart
  }

  set dateStart(date) {
    this._dateStart = this.normalize(date)
  }

  get dateEnd() {
    return this._dateEnd || this.autoDateEnd
  }

  set dateEnd(date) {
    this._dateEnd = this.normalize(date)
  }

  get fromDate() {
    return this._fromDate || DateTime.local()
  }

  set fromDate(date) {
    this._fromDate = this.normalize(date)
  }

  get range() {
    return [this.dateStart.toISODate(), this.dateEnd.toISODate()]
  }

  get dateTimeRange() {
    return [this.dateStart, this.dateEnd]
  }

  get dateRange() {
    return [this.dateStart.toJSDate(), this.dateEnd.toJSDate()]
  }

  set range(rng) {
    this._dateStart = rng[0]
    this._dateEnd = rng[1]
    this.customRange = true

    return this.range
  }

  get dayCount() {
    if (this.rangeGiven) {
      return this.diff(this._dateStart, this._dateEnd).length('days')
    } else {
      return this.offsetForPeriod
    }
  }

  compare(comparison = 'period') {
    if (comparison == 'period') {
      return this.buildPriorPeriod()
    } else {
      return this.buildPriorYear()
    }
  }

  buildPriorPeriod() {
    let comp = new this.constructor()
    comp.period = this.period
    comp.dateStart = this.priorPeriodDateStart
    comp.fromDate = this.priorPeriodDateEnd

    return comp
  }

  buildPriorYear() {
    let comp = new this.constructor()
    comp.period = this.period
    comp.dateStart = this.priorYearDateStart
    comp.fromDate = this.priorYearDateEnd

    return comp
  }

  // private

  get autoDateStart() {
    return this.fromDate.minus({ days: this.dayCount })
  }

  get autoDateEnd() {
    return this.fromDate || DateTime.local()
  }

  get rangeGiven() {
    return this.customRange || (Boolean(this._dateStart) && Boolean(this._dateEnd))
  }

  get offsetForPeriod() {
    return app.periodData.offsetFor(this.period) || this.fallbackOffset
  }

  get fallbackOffset() {
    return 30
  }

  unitForDayCount(count) {
    if (count >= 0 && count <= 20) {
      return 'day'
    } else if (count >= 21 && count <= 89) {
      return 'week'
    } else if (count >= 90 && count <= 720) {
      return 'month'
    } else {
      return 'year'
    }
  }

  get priorPeriodDateStart() {
    if (this.period == 'custom' && Boolean(this._dateStart)) {
      return this.dateStart.minus({ days: this.dayCount + 1 })
    }
  }

  get priorPeriodDateEnd() {
    return this.dateStart.minus({ days: 1 })
  }

  get priorYearDateStart() {
    if (this.period == 'custom' && Boolean(this._dateStart)) {
      return this.dateStart.minus({ years: 1 })
    }
  }

  get priorYearDateEnd() {
    return this.dateEnd.minus({ years: 1 })
  }

  normalize(date) {
    if (!Boolean(date)) { return null }

    return DateNormalizer.normalize(date)
  }

  // Builds a whole-number, interval based diff
  // https://moment.github.io/luxon/docs/manual/math.html#losing-information
  diff(start, finish) {
    return Interval.fromDateTimes(start, finish)
  }
}

export { DayStrategy }
