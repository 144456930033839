import { Controller } from "@hotwired/stimulus"
import Shepherd from "shepherd.js"
import { offset } from '@floating-ui/dom';

// Connects to data-controller="tours--redesign1"
export default class extends Controller {
  connect() {
    const dismiss = () => {
      this.createDismissal(this.url, this.body, this.csrfToken)
    }

    this.build(dismiss)
    this.tour.start()
    Shepherd.once('complete', dismiss)
  }

  build(dismiss) {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        classes: 'box box--card',
        scrollTo: { behavior: 'smooth', block: 'center' }
      },
      useModalOverlay: true,
      steps: [
        {
          title: "Welcome to the all new MortarStone",
          text: "<div class='p-4'>We've recently made some big changes <i class='text-amber-300 fa-duotone fa_icon fa-sparkles'></i></div>",
          buttons: [
            {
              action() {
                dismiss()
                return this.cancel()
              },
              text: 'Skip',
            },
            {
              action() { return this.next() },
              text: 'Next',
            }
          ]
        },
        {
          title: "Search",
          text: "Our search has been moved and now allows you to search for site features in addition to households.",
          attachTo: {
            element: "#header-search-btn",
            on: 'bottom'
          },
          buttons: [
            {
              action() { return this.back() },
              text: 'Back',
            },
            {
              action() { return this.next() },
              text: 'Next'
            }
          ]
        },
        {
          title: "Sidebar",
          text: "We've moved the navigation to the side. All of the old reports and options are still available in addition to some new ones.",
          attachTo: {
            element: "#app_sidebar",
            on: 'right'
          },
          buttons: [
            {
              action() { return this.back() },
              text: 'Back',
            },
            {
              action() { return this.next() },
              text: 'Next'
            }
          ]
        },
        {
          title: "Sidebar collapse",
          text: "You can open or close the navbar to maximize screen space.",
          modalOverlayOpeningPadding: 5,
          floatingUIOptions: {
            middleware: [offset({ mainAxis: 15, crossAxis: 0 })]
          },
          attachTo: {
            element: "#app_sidebar-collapse",
            on: 'right'
          },
          buttons: [
            {
              action() { return this.back() },
              text: 'Back',
            },
            {
              action() { return this.next() },
              text: 'Next'
            }
          ]
        },
        {
          title: "Support",
          text: "If you have any questions please reach out to our support team via email. ",
          attachTo: {
            element: "#app_help",
            on: 'top'
          },
          buttons: [
            {
              action() { return this.back() },
              text: 'Back',
            },
            {
              action() { return this.next() },
              text: 'Next'
            }
          ]
        },
        {
          title: "Further resources",
          text: "You can also watch this <a href='https://mortarstone.com/product_launch/'>in-depth video</a> reviewing the new features if you're interested in learning more. <br><br> Thanks for using MortarStone!",
          buttons: [
            {
              action() { return this.back() },
              text: 'Back',
            },
            {
              action() { return this.complete() },
              text: 'Finish'
            }
          ]
        }
      ]
    })
  }

  createDismissal(url, body, token) {
    fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': token
        },
      }
    )
  }

  get body() {
    return { dismissal: { kind: 'redesign-tour' } }
  }

  get url() {
    return "/dismissals"
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
