import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="screening-lists"
export default class extends Controller {
  static targets = ['actual', 'form']

  connect() {}

  submit(event) {
    event.preventDefault()

    this.actualTarget.value = event.currentTarget.dataset.value
    this.formTarget.submit()
  }
}
