import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="docs"
export default class extends Controller {
  static values = {
    path: String
  }

  connect() {
    this.summaryElem = document.querySelector('#doc_summary')
    this.loadDoc()
  }

  loadDoc() {
    if (this.hasPathValue) {
      // set the src of the doc_summary in the footer
      // this.summaryElem.src = this.pathValue
      get(this.pathValue, { responseKind: "turbo-stream" })
    }
  }
}
