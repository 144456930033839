import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'active', 'inactive' ]

  static values = {
    topic: String,
    active: Boolean
  }

  connect() {}

  disconnect() {}

  toggle({ detail: { topics } }) {
    this.reactTo(topics)
  }

  reactTo(topics) {
    if (topics.includes(this.topicValue)) {
      // this.element.classList.add(...this.activeClasses)
      this.activate()
    } else {
      // this.element.classList.remove(...this.activeClasses)
      this.deactivate()
    }
  }

  activate() {
    if (this.hasActiveClass) {
      this.element.classList.add(...this.activeClasses)
    }
    if (this.hasInactiveClass) {
      this.element.classList.remove(...this.inactiveClasses)
    }
  }

  deactivate() {
    if (this.hasActiveClass) {
      this.element.classList.remove(...this.activeClasses)
    }
    if (this.hasInactiveClass) {
      this.element.classList.add(...this.inactiveClasses)
    }
  }
}
