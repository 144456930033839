import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use";

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ['form']

  static values = {
    delay: { type: Number, default: 200 },
  }

  static debounces = ['save']

  connect() {
    useDebounce(this, { wait: this.delayValue })
    this.lastValues = this.formString
  }

  save() {
    if (this.valuesChanged) {
      this.formTarget.requestSubmit()
    }
  }

  get valuesChanged() {
    const currentValues = this.formString

    return this.lastValues != currentValues
  }

  get formValues() {
    return new FormData(this.formTarget)
  }

  get formString() {
    return new URLSearchParams(this.formValues).toString()
  }
}
