import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "nav" ]

  connect() {
    // close for tap outside nav
  }

  open(event) {
    this.nav.addClass('translate-x-0')
    event.preventDefault()
  }

  close(event) {
    this.nav.removeClass('translate-x-0')
    event.preventDefault()
  }

  get nav() {
    return $(this.navTarget)
  }
}
