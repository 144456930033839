import { Controller } from "@hotwired/stimulus"
import { useTargetMutation } from "stimulus-use"

export default class extends Controller {
  static targets = ['potential']

  connect () { useTargetMutation(this) }

  potentialTargetAdded(element) {
    // Existing values include the element added
    if (this.existingValues(element.dataset.value).length > 1) {
      element.remove()
    }
  }

  existingValues(testValue) {
    return this.potentialTargets.filter((target) => {
      return target.dataset.value === testValue
    })
  }
}
