import { StreamActions } from "@hotwired/turbo"

Turbo.StreamActions.add_classes = function() {
  const classes = this.getAttribute("classes")

  this.targetElements.forEach((target) => {
    target.classList.add(classes.split(" ").join(", "))
  });
}

Turbo.StreamActions.remove_classes = function() {
  const classes = this.getAttribute("classes")

  this.targetElements.forEach((target) => {
    target.classList.remove(classes.split(" ").join(", "))
  });
}

Turbo.StreamActions.open_modal = function() {
  const modalName = this.getAttribute("modal_name")

  const event = new CustomEvent('ui#openModal', { detail: { name: modalName } })
  document.dispatchEvent(event)
}

Turbo.StreamActions.close_modal = function() {
  const modalName = this.getAttribute("modal_name")

  const event = new CustomEvent('ui#closeModal', { detail: { name: modalName } })
  document.dispatchEvent(event)
}

Turbo.StreamActions.scroll_into_view = function() {
  const alignToTop = this.getAttribute("align-to-top")
  const behavior   = this.getAttribute("behavior")
  const block      = this.getAttribute("block")
  const inline     = this.getAttribute("inline")
  const delay      = this.getAttribute("delay")

  if (alignToTop) {
    this.targetElements.forEach((target) => setTimeout(() => target.scrollIntoView(alignToTop === "true"), delay))
  } else if (block || inline || behavior) {
    let options = {}

    if (block) options.block = block
    if (inline) options.inline = inline
    if (behavior) options.behavior = behavior

    this.targetElements.forEach((target) => setTimeout(() => target.scrollIntoView(options), delay))
  } else {
    this.targetElements.forEach((target) => setTimeout(() => target.scrollIntoView(), delay))
  }
}
