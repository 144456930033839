import merge from 'deepmerge'
import { useFormatting } from "./chart_formatting"

export default class DecorationConfigurator {
  constructor(source) {
    useFormatting(this)
    this.source = source
  }

  get config() {
    let cfg = {}

    if (this.shouldColorNegatives) {
      cfg.data ??= {}
      cfg.data.color = this.colorizeNegatives
    }

    return cfg
  }

  get msConfig() {
    return this.source.ms
  }

  get shouldColorNegatives() {
    return Boolean(this.msConfig?.color_negatives) &&
      this.msConfig.color_negatives === Object(this.msConfig.color_negatives)
  }

  get negConfig() {
    return this.msConfig.color_negatives
  }

  get negColorables() {
    return Object.keys(this.negConfig)
  }

  colorizeNegatives = (color, d) => {
    // bail if we can't get the data category or value
    if (!Boolean(d.id) || !Boolean(d.value)) return color

    if (this.negColorables.includes(d.id)) {
      return (d.value < 0) ? this.negConfig[d.id] : color
    } else {
      return color
    }
  }
}


export { DecorationConfigurator }
