import MapPopup from "../popup"
import mapboxgl, { Popup } from 'mapbox-gl'
import Formatter from '../../utils/formatter'

export default class extends MapPopup {
  addTo (layerId) {
    this.map.on('click', layerId, (event) => {
      this.parsed = this.parseValues(event.features[0].properties)

      let lngLat
      if (this.lng && this.lat) {
        lngLat = [this.lng, this.lat]
      } else {
        lngLat = event.lngLat
      }

      new mapboxgl.Popup({
          maxWidth: '31rem',
          className: this.classList.join(' ')
        })
        .setLngLat(lngLat)
        .setHTML(this.propertiesHTML())
        .addTo(this.map)
    })
  }

  propertiesHTML () {
    return `
      <div>
        <h2 class="t-size-2xl p-4">${this.props.name}</h2>
        <div></div>
        <hr />

        <div>
          ${Object.entries(this.props.data).map(data => { return this.dataHTML(data[0], data[1]) }).join(' ')}
        </div>
      </div>
    `
  }

  dataHTML(k, v) {
    let data = this.whiteListKeys(v)

    if (Object.keys(data).length === 0) {
      return ''
    } else {
      return `
        <div class="mb-4 p-2 bg-${this.props.filter_colors[k]}
        rounded-md font-medium text-white text-center text-xs">
          <span class="drop-shadow">${this.props.filter_names[k]}</span>
        </div>

        <table class="table-auto mb-2 w-full">
          ${this.dataTable(data)}
        </table>
      `
    }
  }

  whiteListKeys(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        if (this.whiteList.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

  dataTable(data) {
    return Object.entries(data).map(([prop, value]) => {
      return `<tr class="border-b border-slate-200">
        <td class="px-4 py-2">${Formatter.humanize(prop)}</td>
        <td class="px-4 py-2">${this.formatVal(prop, value)}</td>
      </tr>`
    }).join(' ')
  }

  formatVal(key, value) {
    if (this.countCols.includes(key)) {
      return Formatter.withDelimiters(value)
    } else if (this.moneyCols.includes(key)) {
      return Formatter.money(Number(value))
    } else if (this.percentCols.includes(key)) {
      return Formatter.percent(value)
    } else if (this.dateCols.includes(key)) {
      return this.formatDate(value)
    } else if (this.listCols.includes(key)) {
      return value.join(",")
    } else {
      return value
    }
  }

  formatDate(value) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const date = new Date(Date.parse(value))

    return date.toLocaleDateString('utc', options)
  }

  get whiteList() {
    return [
      ...this.moneyCols, ...this.countCols, ...this.percentCols,
      ...this.dateCols, ...this.listCols, ...this.plainCols
    ]
  }

  get plainCols() {
    return [
      'age', 'prior_band', 'recent_band', 'band', 'instrument_type', 'tfm_score',
      'tenure_score', 'frequency_score', 'monetary_rank', 'engagement_score',
      'location_name', 'prior_score', 'recent_score'
    ]
  }

  get listCols() {
    return ['email_addresses', 'phone_numbers']
  }

  get dateCols() {
    return ['birthdate', 'hoh_birthdate', 'date', 'completion_date']
  }

  get moneyCols() {
    return [
      'giving', 'income', 'given', 'amount_sum', 'amount', 'amount_avg',
      'balance', 'lia', 'retirement_assets', 'net_worth', 'home_value',
      'smallest_gift', 'largest_gift', 'recent_period_giving',
      'prior_period_giving', 'recent_giving', 'prior_giving', 'giving_change',
      'median_income', 'pledged_amount', 'pledged_giving', 'balance',
      'total_given'
    ]
  }

  get countCols() {
    return ['gift_count', 'misses', 'giving_days', 'distance', 'gifts_given']
  }

  get percentCols() {
    return ['percent_change', 'percent_complete']
  }

  get id () {
    return `${this.name}-${this.lng}-${this.lat}`
  }

  parseValues (props) {
    this.props = Object.fromEntries(Object.entries(props).map(([k, v]) => [ k, this.tryJson(v)]))
    return this.props
  }

  tryJson(string) {
    try {
      return JSON.parse(string)
    } catch (e) {
      return string
    }
  }
}
