// Stimulus controller for date ranges
// This uses Flatpickr directly, as opposed to flatpickr_controller which
// wraps the stimulus-flatpickr library

// import * as strftime from 'strftime';
import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'start', 'end' ]

  connect() {
    this.startPicker = flatpickr(this.startTarget, {
      ...this.startConfig
    });
    this.endPicker = flatpickr(this.endTarget, {
      ...this.endConfig
    });
  }

  disconnect() {
    this.startPicker.destroy()
    this.endPicker.destroy()
  }

  clearStart() {
    this.startPicker.clear()
  }

  clearEnd() {
    this.endPicker.clear()
  }

  clearAll() {
    this.clearStart()
    this.clearEnd()
  }

  get baseConfig() {
    return {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      inline: this.data.get('inline') == 'true'
    }
  }

  get startConfig() {
    return Object.assign(this.baseConfig, {
      onChange: (selectedDates, dateStr, instance) => {
        this.data.set('dateStart', dateStr)

        if (Boolean(selectedDates[0])) {
          this.adjustEndDate(selectedDates[0])
        }
      }
    })
  }

  get endConfig() {
    return Object.assign(this.baseConfig, {
      onChange: (selectedDates, dateStr, instance) => {
        this.data.set('dateEnd', dateStr)

        if (Boolean(selectedDates[0])) {
          this.adjustStartDate(selectedDates[0])
        }
      }
    })
  }

  adjustEndDate(minDate) {
    const current = this.endPicker.selectedDates[0]
    const isDefined = Boolean(current)

    if (!isDefined || (current < minDate)) {
      this.endPicker.setDate(minDate, true)
    }
  }

  adjustStartDate(maxDate) {
    const current = this.startPicker.selectedDates[0]
    const isDefined = Boolean(current)

    if (!isDefined || (current > maxDate)) {
      this.startPicker.setDate(maxDate, true)
    }
  }

  // // Ranges
  // // ------------------------------------------------------------------------ //
  //
  // get ranges() {
  //   if (this.data.has('single')) {
  //     return {}
  //   } else {
  //     return {
  //       'Last 30 Days': this.last30,
  //       'Last 90 Days': this.last90,
  //       'Last Year': this.lastYear
  //     }
  //   }
  // }
  //
  // get last30() {
  //   let d1 = new Date(this.maxDate)
  //   let d2 = new Date(this.maxDate)
  //
  //   d2.setDate(d1.getDate() - 30)
  //
  //   return this.formatRange(d2, d1)
  // }
  //
  // get last90() {
  //   let d1 = new Date(this.maxDate)
  //   let d2 = new Date(this.maxDate)
  //
  //   d2.setDate(d1.getDate() - 90)
  //
  //   return this.formatRange(d2, d1)
  // }
  //
  // get lastYear() {
  //   let d1 = new Date(this.maxDate)
  //   let d2 = new Date(this.maxDate)
  //
  //   d2.setYear(d1.getFullYear() - 1)
  //
  //   return this.formatRange(d2, d1)
  // }
}
