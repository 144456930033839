import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.observer = new ResizeObserver(this.updateSize)
    this.observer.observe(this.element, { box: 'border-box' })

    this.originalClasses = []

    this.element.classList.forEach((v) => { this.originalClasses.push(v) })
  }

  disconnect() {
    this.observer.disconnect()
  }

  updateSize = (entries) => {
    let w
    let entry = entries[0]

    if (entry.borderBoxSize) {
      w = entry.borderBoxSize[0]?.inlineSize || this.width
    } else {
      w = entry.contentRect.width || this.width
    }

    if (w > 0) {
      let size = Math.floor(w / 100)
      let classes = [...this.originalClasses] // duplicate original array
      classes.push(`polymorph-${size}`)

      this.element.className = classes.join(' ')
    }
  }

  get width() {
    return this.element.offsetWidth
  }
}
