import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--resetable"
export default class extends Controller {
  static values = {
    immediate: Boolean,
    selector: String
  }

  connect() {
    app.resetables[this.element.id] = this

    if (this.immediateValue) { this.reset() }
  }

  reset() {
    this.element.innerHTML = this.tplContent
  }

  get template() {
    return document.querySelector(this.selectorValue)
  }

  get tplContent() {
    return this.template.innerHTML
  }
}
