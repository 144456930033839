import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['offset']

  connect() {
    this.attachOffset()
  }

  attachOffset() {
    let dt = new Date()
    let d1 = new Date(dt.getFullYear(), 0, 1) // Jan 1st of this year
    let offset = d1.getTimezoneOffset()

    this.offset = -offset
  }

  set offset(val) {
    return this.offsetTarget.value = val
  }
}
