import { Controller } from "@hotwired/stimulus"
import Combobox from '@github/combobox-nav'
import microWait from "../micro_wait"

export default class extends Controller {
  static targets = ['input', 'list']

  connect () { }

  disconnect () {
    this.combobox?.destroy()
  }

  inputTargetConnected () {
    this.focusWhenFilled()
  }

  listTargetConnected () {
    this.start()
  }

  start () {
    if (this.hasListTarget) {
      this.combobox?.destroy()

      this.combobox = new Combobox(this.inputTarget, this.listTarget)
      this.combobox.start()
    }
  }

  stop () {
    this.combobox?.stop()
  }

  // When the search field and the list are reloaded (via Turbo)
  // then the search field will lose focus. This re-focuses and sets the
  // cursor back where it was.
  //
  focusWhenFilled () {
    if (Boolean(this.inputTarget?.value)) {
      this.inputTarget.focus()
      this.inputTarget.setSelectionRange(-1, -1)
    }
  }
}
