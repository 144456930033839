import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get } from '@rails/request.js'

// Connects to data-controller="ui--presence"
export default class extends Controller {
  static values = { path: String }
  static classes = ['present', 'missing']

  connect() {
    this.checkPresence()
  }

  async checkPresence() {
    if (this.hasPathValue) {
      const req = new FetchRequest('head', this.pathValue)
      const res = await req.perform()

      if (res.ok) {
        this.element.classList.remove(...this.missingClasses)
        this.element.classList.add(...this.presentClasses)
      } else {
        this.element.classList.remove(...this.presentClasses)
        this.element.classList.add(...this.missingClasses)
      }
    }
  }
}
