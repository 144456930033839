import { Controller } from "@hotwired/stimulus"
import { Formatter } from "../../utils/formatter"

export default class extends Controller {
  static targets = ['quantity', 'unitCost', 'total']

  connect () {
    this.update()
  }

  update () {
    let totalAmount = (this.quantity * this.unitCost) || 0

    this.totalTarget.innerHTML = Formatter.currency(totalAmount)
    this.totalTarget.dataset.total = totalAmount
  }

  get quantity () {
    return this.quantityTarget.value
  }

  get unitCost () {
    return this.unitCostTarget.value
  }
}
