import { Controller } from "@hotwired/stimulus"
import microWait from "../../micro_wait"

export default class extends Controller {
  connect() {
    this.register()
  }

  // update() defined in leader, comparison controllers

  get position() {
    return Number(this.data.get('position'))
  }

  get flatpickr() {
    return window.app.scenarioForm.namedFps[this.name]
  }

  get dates() {
    return this.flatpickr.dates
  }

  get fromDate() {
    return this.group.fromDate.toJSDate()
  }

  get group() {
    return app.scenarioForm.group
  }

  get form() {
    return app.scenarioForm.main
  }

  get period() {
    return this.periodTarget.value
  }

  get periodStrategy() {
    let strategy = app.periodData.strategyFor(this.period)
    strategy.period = this.period
    strategy.fromDate = this.fromDate

    if (this.period == 'custom') {
      strategy.dateStart = this.flatpickr.startDate
      strategy.dateEnd = this.flatpickr.endDate
    }

    return strategy
  }

  // Private
  // ------------------------------------------------------------------------ //

  register() {
    app.scenarioForm.ranges = app.scenarioForm.ranges || []
    app.scenarioForm.namedRanges = app.scenarioForm.namedRanges || {}

    app.scenarioForm.ranges[this.position] = this
    app.scenarioForm.namedRanges[this.name] = this
  }

  announceRegistered() {
    const event = new CustomEvent('scenario-range-connected', {
      bubbles: true,
      detail: this.name
    })

    this.element.dispatchEvent(event)
  }

  get name() {
    return this.data.get('name')
  }
}
