import mapboxgl from 'mapbox-gl'

export default class Isochrone {
  constructor (id, lng, lat, rel, mode = 'driving', duration = 10) {
    this.lat = lat
    this.lng = lng
    this.mode = mode
    this.duration = duration
    this.id = id || Date.now().toString()
    this.relativeNum = rel
    this.lineColor = this.possibleColors[this.colorKey]
  }

  updateData (evnt) {
    const details = evnt.detail
    this.mode = details.mode
    this.duration = details.duration
  }

  update (lng, lat, duration, mode) {
    this.lng = lng
    this.lat = lat
    this.duration = duration
    this.mode = mode
  }

  async getSource () {
    const json = await this.getData()

    return {
      type: 'geojson',
      data: json
    }
  }

  layer () {
    return this.layerDefaults
  }

  async getData (url) {
    const query = await fetch(this.url, { method: 'GET' })
    const json = await query.json()
    return json
  }

  get url () {
    return `${this.urlBase}${this.mode}/${this.lng},${this.lat}?contours_minutes=${this.duration}&polygons=true&access_token=${mapboxgl.accessToken}`
  }

  get urlBase () {
    return 'https://api.mapbox.com/isochrone/v1/mapbox/'
  }

  get layerDefaults () {
    return {
      'id': this.id,
      'type': 'line',
      'source': this.id,
      'layout': { 'line-join': 'round' },
      'paint': {
        'line-color': this.lineColor,
        'line-opacity': 0.75,
        'line-width': 3
      }
    }
  }

  get innerHTML () {
    return `
      <label name="mode">
        Travel mode
      </label>
      <div data-controller="faux-radios" data-faux-radios-index="${this.modeIndex}" class="faux_radios">
        <input data-maps--isochrone-target="mode" data-faux-radios-target="actual" autocomplete="off" type="hidden" name="mode[mode]" id="mode_mode" value="${this.mode}">

        <div class="faux_radios-option" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="walking">
          <i class="fa_icon fal fa-walking"></i>
          <span> Walking</span>
        </div>

        <div class="faux_radios-option" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="driving">
          <i class="fa_icon fal fa-car-side"></i>
          <span> Driving</span>
        </div>

        <div class="faux_radios-option" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="cycling">
          <i class="fa fa-bicycle fa_icon"></i>
          <span> Cycling</span>
        </div>
      </div>

      <label name="duration">Travel time</label>
      <div class="l-flex-between l-pb-4">
        <div data-controller="faux-radios" data-faux-radios-index="${this.durationIndex}" class="faux_radios">
          <input data-maps--isochrone-target="duration" data-faux-radios-target="actual" autocomplete="off" type="hidden" name="duration[duration]" id="duration_duration" value="${this.duration}">
          <div class="faux_radios-option selected" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="10">10 minutes</div>
          <div class="faux_radios-option" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="20">20 minutes</div>
          <div class="faux_radios-option" data-faux-radios-target="option" data-action="click->faux-radios#update click->maps--isochrone#update" data-value="30">30 minutes</div>
        </div>
      </div>

      <a class="btn2" data-action="click->maps--isochrone#remove">
        <i class="fa fa-eye-slash fa_icon pr-2"></i> Hide
      </a>
    `
  }

  get colorKey () {
    return this.relativeNum % this.possibleColors.length
  }

  get possibleColors () {
    return [
      "#ef4444", // red 500
      "#f97316", // orange 500
      "#fbbf24", // amber 400
      "#d946ef", // fuchsia 500
      "#ec4899", // pink 500
      "#f43f5e", // rose 500
      "#b91c1c", // red 700
      "#c2410c", // orange 700
      "#b45309", // amber 700
      "#a21caf", // fuchsia 700
      "#be185d", // pink 700
    ]
  }

  get modeIndex () {
    return {
      'walking': 0,
      'driving': 1,
      'cycling': 2
    }[this.mode]
  }

  get durationIndex () {
    return {
      10: 0,
      20: 1,
      30: 2
    }[this.duration]
  }
}
