import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import merge from 'deepmerge'

// The flatpickr-stimulus package used for the other flatpickr controllers
// doesn't reload the config after the input is cleared. By using flatpickr
// directly, we can avoid the problem.

export default class extends Controller {
  static targets = [ 'input' ]

  connect() {
    this.fp = flatpickr(
      this.inputTarget,
      merge(this.defaultConfig, this.config)
    )
  }

  clear() {
    this.fp.clear()
  }

  disconnect() {
    this.fp.destroy()
  }

  get defaultConfig() {
    return {
      enableTime: true,
      allowInput: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'M d, Y h:i K'
    }
  }

  get config() {
    if(this.enableTime == "false") {
      return this.dateOnlyConfig
    } else {
      return {}
    }
  }

  get enableTime() {
    return this.element.dataset.enableTime
  }

  get dateOnlyConfig() {
    return {
      enableTime: false,
      altFormat: 'M d, Y'
    }
  }
}
