import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="label-mask"
export default class extends Controller {
  static targets = ['item', 'checkbox']

  static values = {
    checked: { type: Array, default: [] }
  }

  static classes = ['visible', 'hidden']

  connect() {
    // this.itemTargets.forEach((i) => console.log(i.dataset.labelIds))
    app.mask = this
  }

  // when checkboxes are checked, hide the items without selected ids

  update(event) {
    this.updateChecked()

    if (this.checkedValue.length) {
      this.maskItems()
    } else {
      this.showAll()
    }
  }

  updateChecked() {
    this.checkedValue = this.checkboxTargets.map((i) => {
      if (i.checked) { return i.value }
    }).filter(Boolean)
  }

  maskItems() {
    this.itemTargets.forEach((item) => {
      const ids = item.dataset.labelIds.split(',')

      if (this.isSelected(ids)) {
        this.showItem(item)
      } else {
        this.hideItem(item)
      }
    })
  }

  showAll() {
    this.checkedValue = []
    this.itemTargets.forEach(i => this.showItem(i))
  }

  clearAll() {
    this.checkboxTargets.forEach(i => i.checked = false)
    this.showAll()
  }

  isSelected(itemIds) {
    return this.checkedValue.some(v => itemIds.includes(v))
  }

  showItem(item) {
    item.classList.remove(...this.hiddenClasses)
    item.classList.add(...this.visibleClasses)
  }

  hideItem(item) {
    item.classList.add(...this.hiddenClasses)
    item.classList.remove(...this.visibleClasses)
  }
}
