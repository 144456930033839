import { Controller } from "@hotwired/stimulus"
import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js"

// Connects to data-controller="doc-blocks"
export default class extends Controller {
  static targets = ['block']
  static values = {
    updateUrl: String,
    handleClass: String,
    sortAttr: { type: String, default: 'data-id' },
    ghostClass: { type: String, default: 'opacity-60' },
    draggable: { type: String, default: '.sortable-item' },
    groupName: String,
    receive: Array
  }

  initialize() {
    if (app.autoScrollMounted === undefined) {
      Sortable.mount(new AutoScroll());
      app.autoScrollMounted = true;
    }
  }

  connect() {
    this.sortable = Sortable.create(this.element, this.options)
  }

  blockTargetConnected(elem) {
    elem.draggable = true
    this.updatePositions()
  }

  get options() {
    let opts = {
      group: this.groupOpts,
      animation: 150,
      ghostClass: this.ghostClassValue,
      draggable: this.draggableValue,
      dataIdAttr: this.sortAttrValue,
      setData: this.setData,
      onUpdate: this.updatePositions.bind(this),
      scroll: true,
      scrollSensitivity: 50,
      scrollSpeed: 20,
      forceAutoScrollFallback: true
    }

    if (this.hasHandleClassValue) {
      opts['handle'] = this.handleClassValue
    }

    return opts
  }

  updatePositions() {
    const blocks = this.blockTargets

    for(let i = 0; i < blocks.length; i++) {
      blocks[i].querySelector('input.block-position').value = i
    }
  }
}
