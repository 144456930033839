import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['actual', 'option']

  connect() {
    this.selectOption()
    this.register()
  }

  update(event) {
    this.index = this.optionTargets.indexOf(event.currentTarget)
  }

  setByValue(value) {
    this.index = this.values.indexOf(value)
  }

  selectOption() {
    this.optionTargets.forEach((option, index) => {
      option.classList.toggle('selected', index == this.index)
    })

    this.actual = this.values[this.index]
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', value)
    this.selectOption()
  }

  get values() {
    if (this.data.has('values')) {
      return this.data.get('values').split(',')
    } else {
      return this.options.map(o => o.dataset['value'])
    }
  }

  get actual() {
    return this.actualTarget
  }

  set actual(value) {
    this.actual.value = value
    this.fireChange()
  }

  get options() {
    return this.optionTargets
  }

  fireChange() {
    let event = new Event('change')
    this.actual.dispatchEvent(event)
  }

  register() {
    if (this.data.has('name')) {
      app.radios = app.radios || {}
      app.radios[this.data.get('name')] = this
    }
  }
}
