import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['permissionCheckbox', 'locationCheckbox']

  connect(){
    const controller = this

    this.permissionCheckboxTargets.forEach( function(target) {
        var dependentElements = target.dataset.enables

        if(dependentElements && !target.checked) {
          var elements = dependentElements.split(",")

          for (var id of elements) {
            controller.disable(id, target)
          }
        }
      }
    )
  }

  toggleDisable(event){
    var dependentElements = event.currentTarget.dataset.enables

    if(dependentElements){
      var elements = dependentElements.split(",")

      for (var id of elements) {
        if(event.currentTarget.checked) {
          this.enable(id)
        } else {
          this.disable(id, event.currentTarget)
        }
      }
    }
  }

  toggleAll(event){
    var btn = $(event.currentTarget)
    var elements = this.targets.findAll(btn.data('targets'))
    var state = btn.data('state')

    if(state == 'selected'){
      this.unselectAll(elements)
      btn.data('state', 'unselected')
    } else {
      this.selectAll(elements)
      btn.data('state', 'selected')
    }
  }

  selectAll(elements) {
    elements.forEach( function(target){
      target.checked ? null : target.click()
    })
  }

  unselectAll(elements) {
    elements.forEach( function(target){
      target.checked ? target.click() : null
    })
  }

  enable(id){
    this.parentElem(id)._tippy.destroy()
    $("#" + id).prop('disabled', false)
  }

  disable(id, target){
    $("#" + id).prop('checked', false)
    $("#" + id).prop('disabled', true)

    const controller = this
    var parent = this.parentElem(id)
    var labelText = $('label[for='+  $(target).attr('id')  +']').text();

    tippy(parent, {
      content: `Requires <span class="highlight">${labelText}</span> permission`,
      followCursor: true,
      onShow(instance){
        var parentElem = controller.parentElem(target.getAttribute("id"))
        controller.toggleHighlight(parentElem)
      }
    })
  }

  toggleHighlight(element) {
    $(element).addClass('highlight')

    setTimeout(() => {
      $(element).removeClass('highlight')
    }, 1500);
  }

  parentElem(id){
    return $("#" + id).parent()[0]
  }
}
