import { Controller } from "@hotwired/stimulus"
import microWait from "../micro_wait"

export default class extends Controller {
  static targets = ['unit']

  connect() {
    window.app.scenarioForm.main = this
  }

  setUnit(unit) {
    if (this.unitRadio) {
      this.unitRadio.setByValue(unit)
    }
  }

  get unitRadio() {
    return app.radios?.scenario_unit
  }
}
