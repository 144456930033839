import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  connect() {
    this.config = {
      enableTime: true,
      allowInput: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'M d, Y h:i K'
    }

    super.connect()
  }
}
