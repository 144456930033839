import { DayStrategy } from './day_strategy'

export default class YtdStrategy extends DayStrategy {

  // private

  get offsetForPeriod() {
    let boy = this.fromDate.startOf('year')
    let int = this.diff(boy, this.fromDate)

    return int.length('days')
  }

  get priorPeriodDateEnd() {
    return this.dateEnd.minus({ years: 1 })
  }

  get priorYearDateEnd() {
    return this.dateEnd.minus({ years: 1 })
  }
}

export { YtdStrategy }
