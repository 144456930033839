import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="caffeine"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('turbo-frame').forEach(frame => {
      frame.loading = 'eager'
    })
  }
}
