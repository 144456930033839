import { DateTime } from 'luxon'

export default class DateNormalizer {
  constructor(date) {
    this.date = this.constructor.normalize(date)
  }

  static normalize(date) {
    switch (true) {
      case (typeof date === 'string'):
        return DateTime.fromISO(date)
        break;
      case (date instanceof Date):
        return DateTime.fromJSDate(date)
        break;
      case (date instanceof DateTime):
        return date
        break;
      default:
        return date
    }
  }

  toDateTime() {
    return this.date
  }

  toJSDate() {
    return this.date.toJSDate()
  }

  toISODate() {
    return this.date.toISODate()
  }
}

export { DateNormalizer }
