import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { DateTime } from 'luxon'
import RangeSummarizer from "../date_utils/range_summarizer"

// Connects to data-controller="fancy-picker"
export default class extends Controller {
  static targets = [
    'summary',
    'dateMinField',
    'dateMinYear',
    'dateMinMonth',
    'dateMinDay',
    'dateMaxField',
    'dateMaxYear',
    'dateMaxMonth',
    'dateMaxDay',
    'dateRangeField',
    'pickerArea'
  ]

  static values = {
    initial: Array,
    showComparison: { type: Boolean, default: false }
  }

  connect() {
    this.init()
  }

  init() {
    if (this.hasInitialValue && this.initialValue.length) {
      this.dates = this.initialValue.map(this.parseDate)
    }

    this.initRangePicker()
  }

  initRangePicker() {
    this.picker = flatpickr(this.dateRangeFieldTarget, this.pickerConfig)
    this.updateSummary()
  }



  /**
   * Handle when the flatpickr has been changed. Passed as a config option to
   * the flatpickr instance.
   * @param {array} selectedDates
   * @param {string} dateStr
   * @param {object} instance
   */
  pickerChanged(selectedDates, dateStr, instance) {
    this.setInputsFromRange()
    this.dates = selectedDates
    this.setActualsFromRange()
    this.updateSummary()
  }

  /**
   * Handle when a text field input has changed. If there is input but the date
   * is invalid, raise an error. Otherwise assume the field has a good date or
   * has been cleared. Empty fields should be handled in other methods.
   * @param {event} event
   * @todo Decorate the field when there's an error
   */
  inputChanged(event) {
    const date = this.parseDate(event.target.value)

    if (Boolean(event.target.value) && isNaN(date)) {
      console.error(`Bad date in ${event.target.name}`)
    } else {
      this.setRangeFromInputs()
      this.dates = this.parsedInputDates
      this.setActualsFromRange()
      this.updateSummary()
    }
  }

  /**
   * Respond when the user clicks in the input field by changing the date shown
   * in the flatpickr
   * @param {event} event
   */
  inputFocused(event) {
    this.jumpToInput(event.target)
  }

  /**
   * Handle when the user clicks a preset
   * @param {event} event
   */
  setPreset(event) {
    event.preventDefault()

    this.setRange(...this.presets[event.params.preset])
    this.setInputsFromRange()
    this.setActualsFromRange()
    this.updateSummary()
  }





  /**
   * Change the text in the inputs to match what is currently selected in the flatpickr
   */
  setInputsFromRange() {
    const dates = this.picker.selectedDates

    if (dates.length == 1) {
      this.dateMinFieldTarget.value = this.formatDate(dates[0])
    }

    if (dates.length == 2) {
      this.dateMinFieldTarget.value = this.formatDate(dates[0])
      this.dateMaxFieldTarget.value = this.formatDate(dates[1])
    }
  }

  /**
   * Read what is currently in the input fields and update flatpickr accordingly
   */
  setRangeFromInputs() {
    this.setRange(...this.validParsedInputDates)
  }

  /**
   * Tell flatpickr to jump to the date currently in the input (or do nothing)
   * @param {object} input - the input element that contains the jump-to date
   */
  jumpToInput(input) {
    const date = this.parseDate(input.value)

    if (!isNaN(date)) {
      this.picker.jumpToDate(date)
    }
  }


  /**
   * Set the date range in the picker, false means don't fire onChange event
   * @param {Date} startDate
   * @param {Date} endDate
   */
  setRange(startDate, endDate) {
    this.dates = [startDate, endDate]
    this.picker.setDate(this.dates, false)
  }

  /**
   * Set the hidden fields that will actually carry the date values to the backend
   * @param {Date} startDate
   * @param {Date} endDate
   */
  setActuals(startDate, endDate) {
    if (startDate instanceof Date) {
      this.dateMinYearTarget.value  = startDate.getFullYear()
      this.dateMinMonthTarget.value = startDate.getMonth() + 1
      this.dateMinDayTarget.value   = startDate.getDate()
    }

    if (endDate instanceof Date) {
      this.dateMaxYearTarget.value  = endDate.getFullYear()
      this.dateMaxMonthTarget.value = endDate.getMonth() + 1
      this.dateMaxDayTarget.value   = endDate.getDate()
    }
  }

  setActualsFromRange() {
    this.setActuals(...this.dates)
  }





  // Utility functions

  clearMinField() {
    this.dateMinFieldTarget.value  = ""

    this.dateMinYearTarget.value   = ""
    this.dateMinMonthTarget.value  = ""
    this.dateMinDayTarget.value    = ""

    this.setRangeFromInputs()
    this.dates = this.parsedInputDates
  }

  clearMaxField() {
    this.dateMaxFieldTarget.value  = ""

    this.dateMaxYearTarget.value   = ""
    this.dateMaxMonthTarget.value  = ""
    this.dateMaxDayTarget.value    = ""

    this.setRangeFromInputs()
    this.dates = this.parsedInputDates
  }

  /**
   * Update the text summary
   */
  updateSummary() {
    if (this.datesArePresentAndValid) {
      this.summaryTarget.innerHTML = this.summarizer.summary()
    } else {
      this.summaryTarget.textContent = 'Not filtered'
    }
  }

  /**
   * Format a date to the configured flatpickr alt date
   * @param {date} date - a JS Date object
   * @returns {string}
   */
  formatDate(date) {
    if (!Boolean(date)) { return '' }

    return this.picker.formatDate(date, this.pickerConfig.altFormat)
  }

  /**
   * Parse a US date string. We need this before flatpickr is initialized, so
   * it cannot use the built in parser.
   * @param {string} date - a US date string: mm/dd/YYYY
   * @returns {date} a JS Date object
   */
  parseDate(date) {
    if (date === "") { return null }

    return DateTime.fromFormat(date, 'D').toJSDate()
  }

  /**
   * The flatpickr config
   * - inline makes the picker always visible
   * - appendTo allows us to choose where the picker is placed in the DOM
   * - onChange allows us to do something when a range is chosen in the picker
   * @returns {object}
   */
  get pickerConfig() {
    return {
      altInput: true,
      inline: true,
      altFormat: "m/d/Y",
      dateFormat: "Y-m-d",
      mode: 'range',
      defaultDate: this.validParsedInputDates,
      appendTo: this.pickerAreaTarget,
      onChange: this.pickerChanged.bind(this)
    }
  }

  /**
   * @returns {string[]} the values from each date field
   */
  get inputDates() {
    return [
      this.dateMinFieldTarget.value,
      this.dateMaxFieldTarget.value
    ]
  }

  /**
   * The date field values converted to JS date objects
   * @returns {date[]}
   */
  get parsedInputDates() {
    return this.inputDates.map((date) => this.parseDate(date))
  }

  /**
   * Returns the parsed input dates with invalid dates removed
   * @returns {date[]}
   */
  get validParsedInputDates() {
    return this.parsedInputDates.filter((d) => !isNaN(d))
  }

  /**
   * Checks if at least one of the stored dates is present and valid
   * @returns {boolean}
   */
  get datesArePresentAndValid() {
    return (this.dates?.length) && this.dates.some((d) => (d !== null) && !isNaN(d))
  }

  /**
   * Checks if the date-min field is empty (the range has no start date)
   * @returns {boolean}
   */
  get inputsAreBeginless() {
    return (this.parseDate(this.dateMinFieldTarget.value) === null)
  }

  /**
   * Checks if the date-max field is empty (the range has no end date)
   * @returns {boolean}
   */
  get inputsAreEndless() {
    return (this.parseDate(this.dateMaxFieldTarget.value) === null)
  }

  /**
   * Checks that both input fields have valid dates
   * @returns {boolean}
   */
  get inputsAreFullRange() {
    return this.parsedInputDates.every((d) => d !== null)
  }

  get summarizer() {
    return new RangeSummarizer(
      this.dates[0],
      this.dates[1],
      this.showComparisonValue
    )
  }

  // presets

  get presets() {
    return {
      't30d': this.last30days,
      't90d': this.last90days,
      't6m': this.last6months,
      't365d': this.last365days,
      't12m': this.trailing12Months,
    }
  }

  get last30days() {
    return [
      DateTime.local().minus({ days: 30 }).toJSDate(),
      DateTime.local().toJSDate()
    ]
  }

  get last90days() {
    return [
      DateTime.local().minus({ days: 90 }).toJSDate(),
      DateTime.local().toJSDate()
    ]
  }

  get last6months() {
    return [
      DateTime.local().minus({ months: 6 }).toJSDate(),
      DateTime.local().toJSDate()
    ]
  }

  get last365days() {
    return [
      DateTime.local().minus({ days: 365 }).toJSDate(),
      DateTime.local().toJSDate()
    ]
  }

  get lastYear() {
    return [
      DateTime.local().startOf('year').minus({ year: 1 }).toJSDate(),
      DateTime.local().endOf('year').minus({ year: 1 }).toJSDate()
    ]
  }

  get trailing12Months() {
    const strategy = app.periodData.build('t12m')
    return strategy.dateRange
  }
}
