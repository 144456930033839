import merge from 'deepmerge'
import { useFormatting } from "./chart_formatting"

export default class AxisConfigurator {
  constructor(source) {
    useFormatting(this)
    this.source = source
  }

  get msConfig() {
    return this.source.ms
  }

  get yConfig() {
    const cfg = { axis: {} }

    if (this.axisFormats) {
      for (const [axis, fmt] of Object.entries(this.axisFormats)) {
        cfg.axis[axis] = cfg[axis] || { tick: {} }
        cfg.axis[axis].tick.format = this.formatters[fmt]
      }
    } else {
      cfg.axis.y ??= { show: true }
      cfg.axis.y.tick = { format: this.fallbackFormatter }
    }

    return cfg
  }

  get xConfig() {
    const cfg = {}

    if (this.shouldFormatX) {
      cfg.axis = { x: { tick: { format: this.xFormats[this.xFormat] } } }
    }

    return cfg
  }

  get config() {
    return merge(this.yConfig, this.xConfig)
  }

  get fallbackFormat() {
    return this.msConfig?.type || 'number'
  }

  get fallbackFormatter() {
    return this.formatters[this.fallbackFormat]
  }

  get axisFormats() {
    return this.msConfig?.axis_formats
  }

  get xFormat() {
    return this.axisFormats?.x
  }

  get shouldFormatX() {
    return Boolean(this.xFormat)
  }

  get xFormats() {
    return {
      month: '%b %Y',
      day: '%b %d',
      week: '%b %d'
    }
  }

  get shouldFormatY2() {
    return Boolean(this.axisFormats?.y2)
  }
}


export { AxisConfigurator }
