import * as d3 from "d3";
import * as d3sk from "d3-sankey";

export default class Sankey {
  constructor(selector, data) {
    this.selector = selector
    this.element  = document.querySelector(selector)
    this.width    = this.element.parentElement.offsetWidth
    this.height   = Math.max(700, this.width / 4)
    this.data     = data
    this.svg      = null
  }

  format(value) {
    let formatNumber = d3.format(",.0f")
    return `${formatNumber(value)} households`
  }

  color(str) {
    let colors = {
      'band 1': '#3b4257',
      'band 2': '#2c6d79',
      'band 3': '#349880',
      'band 4': '#7cbf6f',
      'band 5': '#dfdb5f',
      'lapsed': '#e14721'
    }

    let key = str.toLowerCase().substring(0,6)

    return colors[key]
  }

  extractKey(str) {
    return str.toLowerCase().substring(0,6)
  }

  render() {
    const color = this.color
    const elem = d3.select(this.selector)
      .append('svg')
      .style('width', '100%')
      .style('height', 'auto')
      .style('min-height', '700px')

    this.svg = elem

    const sankey = d3sk.sankey()
      .nodeWidth(32)
      .nodePadding(8)
      .nodeSort(null) // sorts nodes according to data
      .extent([[1, 1], [this.width - 1, this.height - 6]])

    let jsonData = this.data
    sankey(jsonData)

    const node = elem.append("g")
      .selectAll("rect")
      .data(jsonData.nodes)
      .enter().append("rect")
      .attr('id', d => d.id < 5 ? `b${d.id + 1}l` : `b${d.id - 5}r`)
      .attr("x", d => d.x0)
      .attr("y", d => d.y0)
      .attr("height", d => d.y1 - d.y0)
      .attr("width", d => d.x1 - d.x0)
      .attr("fill", d => color(d.name))
      .on('mouseover', (event, d) => {
        d3.selectAll('.link').style('opacity', 0.1)

        if(d.id < 5) {
          [5,6,7,8,9,10].forEach((i) => d3.select(`#link_${d.id}-${i}`).style('opacity', 1))
        } else {
          [0,1,2,3,4,5].forEach((i) => d3.select(`#link_${i}-${d.id}`).style('opacity', 1))
        }
      })
      .append("title")
      .text(d => `${d.name}\n${this.format(d.value)}`)

    const link = elem.append("g")
      .attr("fill", "none")
      .attr("stroke-opacity", 0.5)
      .selectAll("g")
      .data(jsonData.links)
      .enter().append("g")
      .attr('class', 'link')
      .attr('id', d => `link_${d.source.id}-${d.target.id}`)
      .style("mix-blend-mode", "multiply");

    const gradient = link.append("linearGradient")
        .attr("id", d => `grad_${d.source.id}-${d.target.id}`)
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", d => d.source.x1)
        .attr("x2", d => d.target.x0)

    gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", d => color(d.source.name));

    gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", d => color(d.target.name));

    link.append('path')
      .attr('d', d3sk.sankeyLinkHorizontal())
      .attr('stroke', d => `url('#grad_${d.source.id}-${d.target.id}')`)
      .attr('stroke-width', function(d) { return Math.max(1, d.width) })

    link.append('title')
      .text((d) => `${d.source.name} → ${d.target.name}\n${this.format(d.value)}`)

    elem.append("g")
      .style("font", "10px sans-serif")
      .selectAll("text")
      .data(jsonData.nodes)
      .enter().append("text")
      .attr("x", d => d.x0 < this.width / 2 ? d.x1 + 6 : d.x0 - 6)
      .attr("y", d => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", d => d.x0 < this.width / 2 ? "start" : "end")
      .text(d => d.name)

    elem.on('mouseout', () => d3.selectAll('.link').style('opacity', 1))
  }
}
