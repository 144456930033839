import { Controller } from "@hotwired/stimulus"
// import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static get targets() {
    let arr = ['scrollable'];

    ['top', 'right', 'bottom', 'left'].forEach(side => {
      arr.push(`${side}Anchor`);
      arr.push(`${side}Hint`);
    })

    return arr
  }

  static values = { mode: { type: String, default: 'side' } }

  static classes = [ 'top', 'right', 'bottom', 'left' ]

  initialize() {
    this.sides = ['Top', 'Right', 'Bottom', 'Left']

    this.sides.forEach(side => {
      // Define should-show getters, eg: shouldShowTopHint
      // these depend on decorateTarget to set data-is-visible on the anchors
      Object.defineProperty(this, `shouldShow${side}Hint`, {
        get: () => { return this[`${side.toLowerCase()}AnchorTarget`]?.dataset?.isVisible === 'false' }
      })


      // Define Stimulus target callbacks to observe only the anchors that connect
      // https://stimulus.hotwired.dev/reference/targets#connected-and-disconnected-callbacks
      this[`${side.toLowerCase()}AnchorTargetConnected`] = () => {
        this.observer.observe(this[`${side.toLowerCase()}AnchorTarget`])
      }


      // Define toggle functions, eg: toggleTopHint
      // these add or remove the classValue based on should-show getter
      this[`toggle${side}Hint`] = () => {
        if (this[`has${side}HintTarget`]) {
          this[`${side.toLowerCase()}HintTarget`].classList.toggle(this[`${side.toLowerCase()}Class`], this[`shouldShow${side}Hint`])
        }
      }
    })
  }

  connect() {
    app.controllers = app.controllers || []
    app.controllers.push(this)
  }

  // Define the observer when the thing that is to be observed is connected

  scrollableTargetConnected() {
    this.observer = new IntersectionObserver(this.update, {
      root: this.scrollableTarget,
      threshold: 0.9
    })
  }

  // Core functions

  update = (entries) => {
    entries.forEach(entry => {
      this.decorateTarget(entry)
    })

    if (this.modeValue == 'side') {
      this.sides.forEach(side => {
        if (this[`has${side}AnchorTarget`]) {
          this[`toggle${side}Hint`]()
        }
      })
    } else {
      this.toggleBoxHint()
    }
  }

  decorateTarget(entry) {
    entry.target.dataset.isVisible = entry.isIntersecting
  }

  toggleBoxHint() {
    this.sides.forEach(side => {
      if (this[`has${side}Class`]) {
        this.scrollableTarget.classList.toggle(
          this[`${side.toLowerCase()}Class`],
          this[`shouldShow${side}Hint`]
        )
      }
    })
  }
}
