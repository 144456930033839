// Mixin for a controller that will define drag/drop behavior
// Handles classes and drag set up
// Using controller should define payloadValue and dragStart() function at least.
// Define drag[Start|End]Classes for drag styles.
export const useDraggable = (controller) => {
  Object.assign(controller, {
    onDragStart(evt) {
      if (controller.hasDragEndClass) {
        controller.element.classList.remove(...controller.dragEndClasses)
      }

      if (controller.hasDragStartClass) {
        controller.element.classList.add(...controller.dragStartClasses)
      }

      evt.dataTransfer.effectAllowed = 'move'

      if (controller.hasPayloadValue) {
        evt.dataTransfer.setData('text/plain', JSON.stringify(controller.payloadValue))
      }

      // controller.call(controller, 'dragStart', evt)
      controller.dragStart && controller.dragStart(evt)
    },

    onDragEnd(evt) {
      if (controller.hasDragEndClass) {
        controller.element.classList.add(...controller.dragEndClasses)
      }

      if (controller.hasDragStartClass) {
        controller.element.classList.remove(...controller.dragStartClasses)
      }
    }
  })

  controller.element.addEventListener('dragstart', controller.onDragStart)
  controller.element.addEventListener('dragend', controller.onDragEnd)
}


// Mixin for the drop target
export const useDropBox = (controller) => {
  Object.assign(controller, {
    onDragEnter(evt) {
      if (controller.hasDragEnterClass) {
        controller.element.classList.add(...controller.dragEnterClasses)
      }

      controller.dragEnter && controller.dragEnter(evt)
    },

    onDragLeave(evt) {
      controller.removeEnterClasses()

      if (controller.hasDragLeaveClass) {
        controller.element.classList.add(...controller.dragLeaveClasses)
      }

      controller.dragLeave && controller.dragLeave(evt)
    },

    // Apparently doing this is what enables something to receive drop events
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dragover_event
    onDragOver(evt) {
      evt.preventDefault()
      return false
    },

    onDrop(evt) {
      evt.stopPropagation()

      controller.removeEnterClasses()
      controller.drop && controller.drop(evt)
    },

    removeEnterClasses() {
      if (controller.hasDragEnterClass) {
        controller.element.classList.remove(...controller.dragEnterClasses)
      }
    },

    parsePayload(evt) {
      const payloadStr = evt.dataTransfer.getData('text/plain')
      // const payload = JSON.parse(payloadStr)

      // return payload

      let payload

      if (Boolean(payloadStr)) {
        try {
          payload = JSON.parse(payloadStr)
        } catch (error) {}
      }

      return payload
    },

    addListeners() {
      controller.element.addEventListener('dragenter', controller.onDragEnter)
      controller.element.addEventListener('dragleave', controller.onDragLeave)
      controller.element.addEventListener('dragover', controller.onDragOver)
      controller.element.addEventListener('drop', controller.onDrop)
    },

    removeListeners() {
      controller.element.removeEventListener('dragenter', controller.onDragEnter)
      controller.element.removeEventListener('dragleave', controller.onDragLeave)
      controller.element.removeEventListener('dragover', controller.onDragOver)
      controller.element.removeEventListener('drop', controller.onDrop)
    }
  })

  controller.addListeners()
}
