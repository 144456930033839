import { DayStrategy } from './day_strategy'

export default class WeekStrategy extends DayStrategy {
  get unit() {
    return this._unit || 'week'
  }

  // private

  get autoDateStart() {
    return this.autoDateEnd
      .minus({ weeks: this.offsetForPeriod })
      .startOf('week')
  }

  get autoDateEnd() {
    return this.fromDate.endOf('week')
  }

  get priorPeriodDateStart() {
    return this.dateStart
      .minus({ days: 1, weeks: this.offsetForPeriod })
      .startOf('week')
  }

  get priorPeriodDateEnd() {
    return this.dateStart.minus({ days: 1 })
  }

  get priorYearDateStart() {
    return this.dateEnd
      .minus({ years: 1, weeks: this.offsetForPeriod })
      .startOf('week')
  }

  get priorYearDateEnd() {
    return this.dateEnd.minus({ years: 1 }).endOf('week')
  }
}

export { WeekStrategy }
