import { DayStrategy } from './day_strategy'

export default class QtdStrategy extends DayStrategy {

  // private

  get offsetForPeriod() {
    let boq = this.fromDate.startOf('quarter')
    let int = this.diff(boq, this.fromDate)

    return int.length('days')
  }

  get priorPeriodDateEnd() {
    let boq = this.dateStart.minus({ days: 1 }).startOf('quarter')
    return boq.plus({ days: this.dayCount })
  }

  get priorYearDateEnd() {
    return this.fromDate.minus({ years: 1 })
  }
}

export { QtdStrategy }
