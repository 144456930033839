import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="stream"
export default class extends Controller {
  connect() {
    // this.element.setAttribute("data-action", "click->stream#click")
  }

  click(evt) {
    if (evt.metaKey || evt.ctrlKey) {
      // do nothing if the user is trying to open a new tab
      return true
    } else {
      evt.preventDefault()
      this.loadAndRender(this.url)
    }
  }

  loadAndRender(url) {
    // todo: announce loading start, react where appropriate
    get(url, { responseKind: "turbo-stream" })
  }

  get url() {
    return this.element.getAttribute("href")
  }
}
