import ValueParser from "./utils/value_parser"

export default class Model {
  constructor(attrs) {
    this.attrs = attrs || {}
  }

  get(attr) {
    return this.attrs[attr]
  }

  set(attr, value) {
    this.setAttr(attr, value)
    return this.get(attr)
  }

  has(attr) {
    return (attr in this.attrs)
  }

  setAttr(attr, value) {
    this.attrs[attr] = value
  }

  isPresent(attr) {
    let value = this.get(attr)
    return Boolean(Boolean(value) && String(value).length)
  }

  allPresent(attrs) {
    return attrs.every((attr) => this.isPresent(attr))
  }

  anyPresent(attrs) {
    return attrs.some((attr) => this.isPresent(attr))
  }

  rangePresent(name) {
    return this.allPresent(['Start', 'End'].map((sfx) => `${name}${sfx}`))
  }

  rangeExact(name) {
    let start = this.get(`${name}Start`)
    let end = this.get(`${name}End`)

    if(start == "" || end == "") {
      return false
    } else {
      return this.get(`${name}Start`) == this.get(`${name}End`)
    }
  }

  presentRangeValue(name) {
    return this.get(`${name}Start`) || this.get(`${name}End`)
  }

  getNum(attr) {
    return this.castNumber(this.get(attr))
  }

  setNum(attr, value) {
    this.setAttr(attr, this.castNumber(value))
  }

  getBoolean(attr) {
    return this.castBoolean(this.get(attr))
  }

  setBoolean(attr, value) {
    return this.setAttr(attr, this.castBoolean(value))
  }

  getTitled(attr) {
    let str = this.get(attr)
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  // private

  castNumber(value) {
    // let parsed = Number(value)

    // if (isNaN(parsed)) {
    //   return parseInt(value) || value
    // } else {
    //   return parsed
    // }
    return ValueParser.castNumber(value)
  }

  castBoolean(value) {
    // if (!value) { return false }
    // if (['false', '0'].includes(value)) { return false }

    // return Boolean(value)
    return ValueParser.castBoolean(value)
  }

  arraysEqual(a, b) {
    return a.every(item => b.includes(item)) && b.every(item => a.includes(item))
  }
}
