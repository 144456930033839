import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['quantity', 'unitAmount', 'total']

  connect(){
    this.updateTotal()
  }

  updateTotal(){
    let totalAmount = (this.quantity * this.unit_amount).toFixed(2)

    this.totalTarget.innerHTML = `$${totalAmount}`
  }

  get quantity() {
    return this.quantityTarget.value
  }

  get unit_amount() {
    return this.unitAmountTarget.value
  }
}
