import { Controller } from "@hotwired/stimulus"
import { useDropBox } from "../../ui/drag_mixins"
import { post } from '@rails/request.js'

export default class extends Controller {
  static classes = ['dragStart', 'dragEnter']

  static values = {
    transferUrl: String,
    listId: Number,
    position: { type: Number, default: 0 },
    payload: { type: Object, default: {} }
  }

  connect() {
    useDropBox(this)
  }

  disconnect() {
    this.removeListeners()
  }

  reconnect() {
    this.removeListeners()
    this.addListeners()
  }

  drop = (evt) => {
    const payload = this.parsePayload(evt)

    if (payload.current_list_id && payload.listing_id && this.listIdValue) {
      this.transferListing(
        payload.current_list_id,
        payload.listing_id,
        this.listIdValue,
        payload.everyone
      )
    }

    if (this.hasDragEnterClasses) {
      this.element.classList.remove(...this.dragEnterClasses)
    }
  }

  async transferListing(currentListId, listingId, ListId, everyone) {
    const response = await post(
      this.transferUrlValue,
      {
        body: JSON.stringify({ current_list_id: currentListId, listing_id: listingId, list_id: ListId, everyone: everyone }),
        responseKind: "turbo-stream"
      }
    )

    if (response.ok) {
      // reload turbo frame
      this.dispatch('transfer', { detail: { listingId: listingId }, bubbles: true })
    }
  }
}
