import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="searches--location-summary"
export default class extends Controller {
  static values = {
    total: Number
  }

  static targets = ['checkbox', 'summary']

  connect() {
    this.update()
  }

  update() {
    this.summaryTarget.textContent = this.summary
  }

  get summary() {
    if (this.checkedCount == this.totalValue) {
      return 'All Locations'
    } else {
      return this.pluralizedSummary
    }
  }

  get pluralizedSummary() {
    if (this.checkedCount == 1) {
      return '1 Location'
    } else {
      return `${this.checkedCount} Locations`
    }
  }

  get checkedCount() {
    return this.checkboxTargets.filter((c) => c.checked).length
  }
}
