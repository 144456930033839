import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'icon' ]
  static values = {
    text: String
  }

  copy(event) {
    navigator.clipboard.writeText(this.textValue)

    if (this.hasIconTarget) {
      this.iconTarget.setAttribute('data-icon', 'check')
      this.iconTarget.classList.add('fa-beat')
      setTimeout(() => {  
        this.iconTarget.setAttribute('data-icon', 'copy'); 
        this.iconTarget.classList.remove('fa-beat'); 
      }, 1000);
    }
  }
}