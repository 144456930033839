import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  // 'input' collides with something else in Flatpickr or flatpickr-stimulus
  static targets = ['field']

  // This totally overrides the super class's method so that we can change
  // which element FP is initialized with. `super.connect()` is purposely not used.
  connect() {
    this.register()

    this.config = {
      altInput: true,
      altFormat: 'M d, Y',
      dateFormat: "Y-m-d",
      mode: 'range',
      onClose: this.setCustom
    }

    this._initializeEvents();
    this._initializeOptions();
    this._initializeDateFormats();

    this.fp = flatpickr(this.fieldTarget, {
      ...this.config
    });

    this._initializeElements();
  }

  setRange(range) {
    this.fp.setDate(range)
  }

  get range() {
    return app.scenarioForm.namedRanges[this.name]
  }

  setCustom = () => {
    this.range.setCustom()
  }

  get dates() {
    return this.fp.selectedDates
  }

  get startDate() {
    return this.dates[0]
  }

  get endDate() {
    return this.dates[1]
  }

  register() {
    app.scenarioForm.fps = app.scenarioForm.fps || []
    app.scenarioForm.fps.push(this)

    window.app.scenarioForm.namedFps = window.app.scenarioForm.namedFps || {}

    if (this.data.has('name')) {
      window.app.scenarioForm.namedFps[this.name] = this
      this.announceRegistered()
    }
  }

  announceRegistered() {
    const event = new CustomEvent('scenario-flatpickr-connected', {
      bubbles: true,
      detail: this.name
    })

    this.element.dispatchEvent(event)
  }

  get name() {
    return this.data.get('name')
  }
}
