export default class LegendSkeleton {
  html () {
    return `
      <div class="legend-items bdr-b l-pb-4">
        ${this.emtpyItems.join('')}
      </div>
    `
  }

  get emtpyItems () {
    return [...Array(5)].map(() => {
      return `
        <div class="l-flex-center l-py-1">
          <div class="legend-color bg-gray-300"></div>
          <span class="legend-label-skeleton loadbar">
            <div class="mover loadbar-mover">
            </div>
          </span>
        </div>
      `
    })
  }
}
