import { Controller } from "@hotwired/stimulus"
import { Formatter } from "../../utils/formatter"

export default class extends Controller {
  static targets = ['title', 'label', 'value', 'change', 'percent']

  connect() {
    app.chartSummaries = app.chartSummaries || {}
    app.chartSummaries[this.element.id] = this
  }

  update(data, idx) {
    let row = data.data?.json[idx]
    let periods = Object.keys(data.data?.names || {})
    let money = data.ms?.type == 'money'

    periods.reverse().forEach((p, i) => {
      this.updateLabel(i, row[`${p}_date`])
      this.updateValue(i, row[p], money)
      this.updatePercent(i, row[`${p}_prct`])
    })
  }

  reset(data) {
    let summary = data.ms?.summary
    let periods = Object.keys(data.data?.names || {})
    let money = data.ms?.type == 'money'

    periods.reverse().forEach((p, i) => {
      this.updateLabel(i, summary[p].label)
      this.updateValue(i, summary[p].value, money)
      this.updatePercent(i, summary[p].percent)
    })
  }

  updateLabel(index, val) {
    this.labelTargets[index].textContent = val
  }

  updateValue(index, val, money = false) {
    if (money) {
      this.valueTargets[index].textContent = Formatter.currency(val)
    } else {
      this.valueTargets[index].textContent = val
    }
  }

  updatePercent(index, val) {
    if (!Boolean(val)) { return '' }

    this.percentTargets[index].textContent = Formatter.percent(val)
  }
}
