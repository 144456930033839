import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'id', 'companyId', 'planSelect' ]

  changePlanId() {
    if (this.custom_mapId.length) {
      jQuery.ajax(
        `/hq/companies/${this.companyId}/uploading/custom_maps/plans/${this.custom_mapId}`,
        {
          method: 'PATCH',
          data: { plan_id: this.planId }
        }
      )
    } else {
      jQuery.post(
        `/hq/companies/${this.companyId}/uploading/custom_maps/plans`,
        { plan_id: this.planId }
      )
    }
  }

  get companyId() {
    return this.companyIdTarget.value
  }

  get custom_mapId() {
    return this.idTarget.value
  }

  get planId() {
    return this.planSelectTarget.value
  }
}
