import { Controller } from "@hotwired/stimulus"
import { DateTime } from 'luxon'
import RelativeRangeSummarizer from "../date_utils/relative_range_summarizer"

// Connects to data-controller="relative-picker"
export default class extends Controller {
  static targets = [
    'summary',
    'daysMinField',
    'daysMaxField',
    'preview'
  ]

  static values = {
    initial: Array,
    showComparison: { type: Boolean, default: false }
  }

  connect() {
    app.picker = this
    this.init()
  }

  /**
   * When there is a non-empty initial value, set things up and update the
   * summary. If there is no initial value, try what is in the input fields.
   * Otherwise set things to zero.
   */
  init() {
    if (this.hasInitialValue && this.initialValue.length) {
      this.dayRange = this.initialValue
      this.updateDatesFromDays()
      this.updateSummary()
    } else if (this.inputsArePresent) {
      this.dayRange = this.inputValues
      this.updateDatesFromDays()
    } else {
      this.hidePreview()
      this.dayRange = [0, 0]
      this.updateDatesFromDays()
    }
  }

  inputChanged(event) {
    this.setRangeFromInputs()
    this.updateSummary()
  }

  setPreset(event) {
    event.preventDefault()

    this.setRange(...this.presets[event.params.preset])
    this.setInputsFromRange()
    this.updateSummary()
  }

  setRange(max, min) {
    this.dayRange = [max, min]
    this.updateDatesFromDays()
  }

  setRangeFromInputs() {
    this.dayRange = [
      parseInt(this.daysMaxFieldTarget.value) || 0,
      parseInt(this.daysMinFieldTarget.value) || 0,
    ]

    this.updateDatesFromDays()
  }

  setInputsFromRange() {
    this.daysMaxFieldTarget.value = this.dayRange[0]
    this.daysMinFieldTarget.value = this.dayRange[1]
  }

  updateDatesFromDays() {
    this.dateRange = this.dayRange.map(this.offsetToDate)
  }

  updateSummary() {
    if (this.validDays) { this.showPreview() } else { this.hidePreview() }

    this.summaryTarget.innerHTML = this.summarizer.summary()
    this.previewTarget.textContent = this.previewString
  }

  showPreview() {
    const parent = this.previewTarget.parentNode
    parent.classList.remove('hidden')
  }

  hidePreview() {
    const parent = this.previewTarget.parentNode
    parent.classList.add('hidden')
  }

  get summarizer() {
    return new RelativeRangeSummarizer({
      min: this.dayRange[0],
      max: this.dayRange[1],
      showComp: this.showComparisonValue
    })
  }

  get previewString() {
    if (!this.inputsPresent) { return '' }
    if (this.backwardsDays)  { return "Days should be largest first" }

    if (this.inputsAreBeginless) {
      return `${this.dateRange[1].toLocaleDateString()} or before`
    } else if (this.inputsAreEndless) {
      return `${this.dateRange[0].toLocaleDateString()} or after`
    } else {
      return this.dateRange.map((d) => d.toLocaleDateString()).join(' - ')
    }
  }

  get inputValues() {
    return [
      this.daysMaxFieldTarget.value,
      this.daysMinFieldTarget.value
    ]
  }

  get inputsPresent() {
    return this.inputValues.some((v) => v.length)
  }

  get validDays() {
    return this.dayRange.some((d) => d > 0)
  }

  get backwardsDays() {
    return this.inputsAreFullRange && (this.dayRange[1] > this.dayRange[0])
  }

  /**
   * Checks if the days-max input is empty but the days-min field has a value
   * @returns {boolean}
   */
  get inputsAreBeginless() {
    return !Boolean(this.daysMaxFieldTarget.value) && Boolean(this.daysMinFieldTarget.value)
  }

  /**
   * Checks if the date-max field is empty (the range has no end date)
   * @returns {boolean}
   */
  get inputsAreEndless() {
    return !Boolean(this.daysMinFieldTarget.value) && Boolean(this.daysMaxFieldTarget.value)
  }

  /**
   * Checks that both input fields have valid dates
   * @returns {boolean}
   */
  get inputsAreFullRange() {
    return this.inputValues.every((d) => Boolean(parseInt(d)))
  }

  // Utils

  offsetToDate(value) {
    const days = parseInt(value)

    if (isNaN(days)) {
      return null
    }

    return DateTime.local().minus({ days: days }).toJSDate()
  }

  dateToOffset(date) {
    const diff = DateTime.fromJSDate(date).diffNow('days').toObject()
    return diff.days
  }


  // Presets

  get presets() {
    return {
      't30d': [30, 0],
      't90d': [90, 0],
      't6m': [180, 0],
      't365d': [365, 0],
      't730d': [730, 366],
    }
  }
}
