import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // connect() {}

  dismiss(e) {
    fetch(
      this.url, {
        method: 'POST',
        body: JSON.stringify(this.body),
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-TOKEN': this.csrfToken
        },
      }
    ).then( () => this.element.remove() )
  }

  get id() {
    return this.data.get('id')
  }

  get body() {
    return { user: { announcement_id: this.id } }
  }

  get url() {
    return this.data.get('url')
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
