export default class CampusForm {
  constructor (element) {
    this.select = element
  }

  get valid () {
    if (this.lat && this.lng) {
      return true
    } else {
      return false
    }
  }

  get lngLat () {
    return [this.lng, this.lat]
  }

  get lat () {
    return Number(this.selection.dataset.lat)
  }

  get lng () {
    return Number(this.selection.dataset.lng)
  }

  get selection () {
    return this.select.options[this.select.selectedIndex]
  }
}
