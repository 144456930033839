import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="panel"
export default class extends Controller {
  static targets = ['header', 'title', 'body', 'footer']

  static values = {
    visible: { type: Boolean, default: false },
    pinned: { type: Boolean, default: false }
  }

  connect() {
    if (Boolean(this.element.id)) {
      window.app.panels[this.element.id] = this
    }
  }

  disconnect() {
    this.hide()

    if (Boolean(this.element.id) && app.panels[this.element.id]) {
      delete app.panels[this.element.id]
    }
  }

  /*
    There are many modes a panel can be in.

    Show (hide):  Visible or hidden
    Pin (unpin):  Visible and part of the layout (the app is multi-column)
    Focus (blur): Visible and an overlay is displayed behind the panel

    These distinctions allow for the sidebar to be part of the layout on large
    screens, to be tucked away on large/medium screens, and to be a slide-out
    for small screens, AND to not be above the overlay when the left slide-out
    is active.

    See also ui_controller
  */

  // Display the panel with an overlay
  show(event) {
    event?.preventDefault()

    this.visibleValue = true

    this.showAndFocus()
    this.addClickListener()
    this.dispatch(`${this.element.id}:show`, window)
  }

  hide(event) {
    event?.preventDefault()

    this.visibleValue = false
    this.hideAndBlur()
    this.removeClickListener()
  }

  // Used by UI to add the sidebar to the layout
  pin(event) {
    event?.preventDefault()

    this.element.classList.add('panel--visible')
    this.visibleValue = true
    this.pinnedValue = true
  }

  unpin(event) {
    event?.preventDefault()

    this.visibleValue = false
    this.pinnedValue = false
    this.element.classList.remove('panel--visible')
  }

  // Private

  // Listen for click outside panel
  // capture prevents cross-talk with other panels
  addClickListener() {
    document.addEventListener('click', this.outsideClickHandler, true)
  }

  // Remove args must match add args
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal
  removeClickListener() {
    document.removeEventListener('click', this.outsideClickHandler, true)
  }

  outsideClickHandler = (event) => {
    if (this.shouldHide(event)) {
      this.hide(event)
    }
  }

  get isOpen() {
    return this.element.classList.contains('panel--visible')
  }

  get isPinned() {
    return this.pinnedValue
  }

  get rect() {
    return this.element.getBoundingClientRect()
  }

  shouldHide(event) {
    return this.isOpen &&
      Boolean(event) &&
      !this.isInBounds(event.clientX, event.clientY)
  }

  isInBounds(x, y) {
    // Firefox seems to return zeros when clicking on select elements
    if (x == 0 && y == 0) { return true }

    return x > this.rect.left &&
      x < this.rect.right &&
      y > this.rect.top &&
      y < this.rect.bottom
  }

  get showClasses() {
    return ['panel--visible', 'panel--active']
  }

  showAndFocus() {
    this.showClasses.forEach((item) => {
      this.element.classList.add(item)
    })
  }

  hideAndBlur() {
    this.showClasses.forEach((item) => {
      this.element.classList.remove(item)
    })

    window.ui?.hideOverlay()
  }
}
