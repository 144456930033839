import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ui--dragbox"
export default class extends Controller {
  static targets = ['list']
  static classes = ['dragEnter']

  connect() {
    this.element.addEventListener('dragenter', this.onDragEnter)
    this.element.addEventListener('dragleave', this.onDragLeave)
    this.element.addEventListener('dragover', this.onDragOver)
    this.element.addEventListener('drop', this.onDrop)
  }

  onDragEnter = (evt) => {
    this.element.classList.add(...this.dragEnterClasses)
  }

  onDragLeave = (evt) => {
    this.element.classList.remove(...this.dragEnterClasses)
  }

  onDragOver = (evt) => {
    evt.preventDefault()
    return false
  }

  onDrop = (evt) => {
    evt.stopPropagation()

    this.element.classList.remove(...this.dragEnterClasses)

    const payloadStr = evt.dataTransfer.getData('text/plain')
    const payload = JSON.parse(payloadStr)

    console.log(payload);
  }
}
