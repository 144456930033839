import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'table',
    'tbody',
    'control'
  ]

  static classes = [ 'focus' ]

  connect() {
    // Duplicate, don't reference
    this.baseClasses = [...this.element.classList]
    this.focusOn('amount')
  }

  // Set a focus class on <tbody>, this way we can replace all
  // classes rather than keeping some classes (like .cohort_tbl on <table>)
  focus(event) {
    event.preventDefault()
    this.controlTargets.forEach((control) => this.deactivateControl(control))
    this.activateControl(event.currentTarget)

    // const name = event.currentTarget.dataset['cohortTblFocusClass']
    // this.tbodyTarget.classList = [`cohort_tbl--focus_${name}`]
    const name = event.params['focus']
    this.focusOn(name)
  }

  focusOn(name) {
    this.element.className = [...this.baseClasses, `cohort_tbl--focus_${name}`].join(' ')
  }

  activateControl(ctrl) {
    ctrl.classList.remove('text-slate-400')
    ctrl.classList.add('text-slate-800')
  }

  deactivateControl(ctrl) {
    ctrl.classList.remove('text-slate-800')
    ctrl.classList.add('text-slate-400')
  }

  // TODO: Hide cells out of range
  // toggleOutOfBounds(event) {
  //   event.preventDefault()
  //   event.currentTarget.classList.toggle('active')
  //
  //   this.tbodyTarget.classList.toggle('cohort_tbl--hide_out_of_range')
  // }
}
