import Formatter from '../utils/formatter'

export default class Legend {
  constructor(legend, options = {}) {
    this.title = legend.title
    this.cutoffs = legend.cutoffs
    this.type = legend.type || 'discrete'
    this.format = legend.format || 'none'
    this.showTitle = options.showTitle || false
  }

  html () {
    let html = ''

    if (this.showTitle) { html += this.titleHTML() }

    html += `<div class="legend-items py-4">${this.legendItemsHTML()}</div>`

    return html
  }

  titleHTML () {
    return `<div class="text-md font-medium pt-4">${this.title}</div>`
  }

  legendItemsHTML () {
    return this.cutoffs.map((pair) => this.legendItemHTML(pair)).join(' ')
  }

  legendItemHTML (pair) {
    if (this.cutoffs.indexOf(pair) === 0 && this.format !== 'none') {
      return ''
    } else {
      return `<div>
        <div class="legend-color" style="background-color: ${pair[1]}">
        </div>
        <span class="legend-label">${this.formattedLabel(pair)}
        </span>
      </div>`
    }
  }

  formattedLabel (pair) {
    if (this.format === 'none') {
      return pair[0]
    } else if (this.cutoffs.indexOf(pair) !== 0) {
      return this.formatAsRange(pair)
    }
  }

  formatAsRange (pair) {
    let prev = this.cutoffs[this.cutoffs.indexOf(pair) - 1]
    let val = this.adjustVal(pair)
    return `${this.formatVal(prev[0])} - ${this.formatVal(val)}`
  }

  adjustVal (pair) {
    let val = pair[0]
    if (this.cutoffs.indexOf(pair) < this.cutoffs.length - 1) {
      switch (this.format) {
        case 'integer':
          val = pair[0] - 1
          break;
        default:
          val = pair[0] - 0.01
          break;
      }
    }

    return val
  }

  formatVal (value) {
    switch (this.format) {
      case 'currency':
        return Formatter.currency(value)
        break;
      case 'percent':
        return Formatter.percent(value)
        break;
      case 'integer':
        return Formatter.withDelimiters(value)
        break;
      default:
        return value
    }
  }
}
