import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="households--name-box"
export default class extends Controller {
  static values = {
    currentId: Number
  }

  static targets = ['item']

  connect() {
    this.activate(this.currentIdValue)
  }

  highlight(evt) {
    let id = evt.params?.id
    if (id) { this.activate(id) }
  }

  activate(id) {
    this.itemTargets.forEach(item => {
      if (id == item.dataset['itemId']) {
        item.classList.add('bg-slate-100')
      } else {
        item.classList.remove('bg-slate-100')
      }
    });
  }
}
