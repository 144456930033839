import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']

  toggleAll(event) {
    event.preventDefault()

    if (this.allSelected()) {
      this.unselectAll()
    } else {
      this.selectAll()
    }

    this.dispatch('toggleAll', { bubbles: true })
  }

  unselectAll() {
    this.checkboxTargets.forEach((t) => {
      t.checked = false
    })
  }

  selectAll() {
    this.checkboxTargets.forEach((t) => {
      t.checked = true
    })
  }

  allSelected() {
    let selected = 0

    this.checkboxTargets.forEach((t) => {
      if (t.checked) {
        selected++
      }
    })

    return (selected == this.checkboxTargets.length)
  }
}
