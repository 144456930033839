import { Controller } from "@hotwired/stimulus"
import RangeController from './range_controller'

export default class extends RangeController {
  static targets = ['comparison', 'period']

  update() {
    this.compareToLeader()
  }

  setCustom() {
    this.setComparisonToCustom()
  }

  // Private
  // ------------------------------------------------------------------------ //

  compareToLeader() {
    // Don't overwrite custom date range
    if(this.comparison === 'custom') { return }

    let compStrategy = this.leader.periodStrategy.compare(this.comparison)

    this.flatpickr.setRange(compStrategy.range)
    this.data.set('period', this.leader.period)
    this.periodTarget.value = this.leader.period
  }

  get isLeader() { return false }
  get isComparison() { return true }
  get usingPeriodRange() { return false }

  get leader() {
    return app.scenarioForm.ranges[this.position - 1]
  }

  get comparison() {
    if (this.hasComparisonTarget) {
      return this.comparisonTarget.value
    }
  }

  setComparisonToCustom() {
    let opts = Array.from(this.comparisonTarget.children)

    for (let option of opts) {
      if (option.value == 'custom') {
        option.selected = true
        option.setAttribute('selected', 'selected')
      }
    }
  }
}
