import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="workflows--copies"
export default class extends Controller {
  static values = { url: String, initial: String }

  connect() {
    this.updatePreview(this.initialValue)
  }

  preview(event) {
    const selected = event.target.options[event.target.selectedIndex]
    const id = selected.value

    this.updatePreview(id)
  }

  updatePreview(id) {
    post(this.urlValue, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ copy: { "list_id": id } })
    })
  }
}
