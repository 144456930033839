import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page"
export default class extends Controller {
  static values = {
    topics: { type: Array, default: [] },
    record: { type: Boolean, default: false }
  }

  connect() {
    if (window.ui) {
      ui.page = {
        controller: this,
        topics: this.topicsValue
      }

      ui.navUpdate()
    }

    if (app?.phg && this.recordValue) {
      app.phg.startSessionRecording()
    }
  }

  disconnect() {
    if (window.ui) { ui.page = {} }

    if (app?.phg && this.recordValue) {
      app.phg.stopSessionRecording()
    }
  }

  announcePageChange() {
    this.dispatch('change', { bubbles: true, detail: { topics: this.topicsValue } })
  }
}
