import { Controller } from "@hotwired/stimulus"
import RangeController from './range_controller'

export default class extends RangeController {
  static targets = ['period']

  update() {
    if (this.usingPeriodRange) {
      this.data.set('period', this.period)
      this.flatpickr.setRange(this.rangeForPeriod)
    } else {
      this.setPeriodToCustom()
    }

    if (this.group.initialLoad) {
      this.group.initialLoad = false
    } else {
      this.form.setUnit(this.unitForPeriod)
    }
  }

  setCustom() {
    this.setPeriodToCustom()
    this.group.updateRanges()
  }

  get rangeForPeriod() {
    return this.periodStrategy.range
  }

  get unitForPeriod() {
    return app.periodData.unitFor(this.period)
  }

  // Private
  // ------------------------------------------------------------------------ //

  get isLeader() {
    return true
  }

  get isComparison() {
    return false
  }

  get leader() {
    return null
  }

  get usingPeriodRange() {
    return this.period != 'custom'
  }

  // Set select to custom, cover all the bases
  setPeriodToCustom() {
    this.periodTarget.value = 'custom'

    let option = this.periodTarget.querySelector('[value="custom"]')
    option.selected = true
    option.setAttribute('selected', 'selected')

    this.data.set('period', 'custom')
  }

  register() {
    super.register()
    app.scenarioForm.rangeLeader = this
  }
}
