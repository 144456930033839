import GraphController from "../graph_controller"
import Sankey from "../../charts/sankey"
import * as d3 from 'd3'

export default class extends GraphController {
  static values = {
    bindId: String,
    loadEvent: String,
    announce: String,
    config: Object,
    data: Object
  }

  destroy() {
    this.chart.svg.remove()
    delete this.chart
  }

  appear(entry) {}

  generate () {
    const sankey = new Sankey(`#${this.bindIdValue}`, this.dataValue.data)
    this.chart = sankey
    sankey.render()
    this.appendNoCache()
  }

  // Turbo cache load removes event listener for mouseover
  appendNoCache () {
    document.head.appendChild(this.noCache)
  }

  get noCache () {
    let node = document.createElement("meta")
    node.setAttribute("name", "turbo-cache-control")
    node.setAttribute("content", "no-cache")
    return node
  }

  resize() {
    if (this.chart) {
      this.rebuild()
    }
  }
}
