import Flatpickr from "stimulus-flatpickr"
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

export default class extends Flatpickr {
  static values = {
    monthOnly:  { type: Boolean, default: false },
    allowInput: { type: Boolean, default: false },
    altFormat:  { type: String, default: 'M d, Y' },
    inline:     { type: Boolean, default: false }
  }

  connect() {
    this.config = {
      altInput: true,
      altFormat: this.altFormatValue,
      dateFormat: "Y-m-d",
      inline: this.inlineValue
    }

    if(this.monthOnlyValue) {
      this.config['plugins'] = [this.monthPlugin]
    }

    if (this.hasAllowInputValue) {
      this.config['allowInput'] = this.allowInputValue
    }

    super.connect()
  }

  clear(evt) {
    evt.preventDefault()
    this.fp.clear()
  }

  get monthPlugin() {
    return new monthSelectPlugin({ theme: "dark", dateFormat: "Y-m-d" })
  }
}
