import MapPopup from "../popup"
import Formatter from '../../utils/formatter'

export default class extends MapPopup {
  propertiesHTML () {
    return `
      <div>
        <h2 class="t-size-2xl l-pr-4">${this.name}</h2>
        <div>${Formatter.withDelimiters(this.filtered.household_count.value)} Households</div>
        <hr />

        <div
          data-controller="maps--isochrone"
          data-maps--isochrone-lat-value="${this.lat}"
          data-maps--isochrone-lng-value="${this.lng}"
          data-maps--isochrone-id-value="${this.id}"
          >
        </div>
      </div>
    `
  }

  get id () {
    return `${this.name}-${this.lng}-${this.lat}`
  }
}
