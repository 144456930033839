// Parse values like Stimulus
// https://github.com/hotwired/stimulus/blob/main/src/core/value_properties.ts

export default class ValueParser {
  static castArray(value) {
    const array = JSON.parse(value)

    if (!Array.isArray(array)) {
      console.error(`Expected array but was ${array}`)
    }

    return array
  }

  // If it isn't '0' or 'false', then it's true
  static castBoolean(value) {
    return !(value == "0" || String(value).toLowerCase() == "false")
  }

  static castNumber(value) {
    const num = Number(value)

    if (isNaN(num)) {
      console.error(`${value} is not a number`)
    }

    return num
  }

  static castObject(value) {
    const object = JSON.parse(value)

    if (object === null || typeof object != "object" || Array.isArray(object)) {
      console.error('Expected object');
    }

    return object
  }

  static castString(value) {
    return String(value)
  }
}

export { ValueParser }
